import {
  Avatar,
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import {
  CameraAltOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import avatar from "../../assets/images/svg/avatar.svg";
import pinIcon from "../../assets/images/svg/pinIcon.svg";
import joinIcon from "../../assets/images/joinIcon.svg";
import { useNavigate } from "react-router-dom";
import { $crud } from "../../utils/CrudFactory";

export function AccountSettings() {
  const [mfaScreen, setMfaScreen] = useState(false);
  const theme = useTheme();
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const handleChangePassword = async () => {
    $crud
      .post("admin/password-change-send-code", {
        id: user._id,
      })
      .then((response) => {
        const { message, type, data } = response;
        if (type == "success") {
          localStorage.clear();
          navigate("/forgot-password", {
            state: {
              changeAccPassword: true,
              adminId: user._id,
              adminEmail: user.email,
            },
          });
        }
      })
      .catch((error) => {
        console.error("Error sending code:", error);
      });
  };

  const handleMFAChange = async () => {
    $crud
      .post("admin/mfa-change-verify", {
        id: user._id,
        password,
      })
      .then((response) => {
        const { message, type, data } = response;
        if (type == "success") {
          localStorage.clear();
          navigate("/authenticator", {
            state: {
              qrCodeDataURL: data.qrCodeDataURL,
              otpSecret: data.otpSecret,
              userId: data.loginUser._id,
              token: `Bearer ${data.token}`,
              user: JSON.stringify(data?.loginUser),
              changeMFA: true,
              isQRScanned: data?.isQRScanned,
            },
          });
        }
      })
      .catch((error) => {
        console.error("Error sending code:", error);
      });
  };

  return (
    <Grid container sx={{ padding: "0 18px" }}>
      {mfaScreen ? (
        <>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: "30px",
                color: "#673C8E",
                textAlign: "center",
                marginTop: "86px",
              }}
            >
              Edit your account information:
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "40px" }}>
            <Card
              sx={{
                padding: "40px",
                margin: "0 auto",
                width: "650px",
                background: theme.palette.primary.main,
                boxShadow: theme.palette.boxShadow.primary,
                borderRadius: 0,
                border: theme.palette.border.primary,
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.text.primary,
                  fontSize: "30px",
                  fontWeight: 700,
                  textAlign: "center",
                }}
              >
                Re-register 2FA
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.text.primary,
                  fontSize: "20px",
                  fontWeight: 600,
                  marginTop: "30px",
                  mb: 1,
                }}
              >
                Verify Password
              </Typography>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <OutlinedInput
                  placeholder="Enter Password"
                  sx={{
                    color: "#1C1B1F",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: (theme) => theme.palette.text.secondary, // Change outlined border color
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: (theme) => theme.palette.text.secondary, // Change outlined border color on hover
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#1C1B1F", // Change outlined border color when focused
                    },
                    "& .MuiOutlinedInput-notchedOutline legend": {
                      display: "none", // Remove the notch
                    },
                    // Hide the arrows (spinners)
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                      "&::-webkit-outer-spin-button": {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                      "&::-webkit-inner-spin-button": {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    },
                  }}
                  size="small"
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  // onBlur={handleBlur}
                  type="text"
                  className={!showPassword ? "hidden-otp" : ""}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                  marginTop: "20px",
                }}
              >
                <Button
                  onClick={handleMFAChange}
                  variant="contained"
                  size="small"
                  type="submit"
                  sx={{
                    borderColor: "#673C8E",
                    background: "#673C8E",
                    color: "#fff",
                    padding: "10px 30px",
                    fontSize: "14px",
                    borderRadius: "10px",
                    textTransform: "capitalize",
                    "&:hover": {
                      background: "#673C8E",
                      borderColor: "#673C8E",
                      color: "#fff",
                    },
                  }}
                >
                  Continue
                </Button>
              </Box>
            </Card>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: "30px",
                color: "#673C8E", // Return the value directly
              }}
            >
              Account Settings
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ marginRight: "24px" }}>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                height: "237px",
                // background: theme.palette.text.primary,
                position: "relative",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "122px",
                  background: theme.palette.text.primary,
                  position: "absolute",
                  top: 0,
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    position: "absolute",
                    bottom: "17px",
                    cursor: "auto",
                    right: "30px",
                    borderRadius: "10px",
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.text.primary,
                    borderColor: theme.palette.primary.main,
                    textTransform: "capitalize",
                    fontWeight: 500,
                    "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.text.primary,
                      borderColor: theme.palette.primary.main,
                    },
                  }}
                  startIcon={<CameraAltOutlined />}
                >
                  Update Profile
                </Button>
              </Box>

              <Box
                sx={{
                  position: "absolute",
                  paddingBottom: "20px",
                  width: "100%",
                  bottom: "0",
                  left: "50%",
                  transform: "translateX(-50%)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  borderBottom: theme.palette.border.primary,
                }}
              >
                <Avatar
                  sx={{
                    width: "100px",
                    height: "100px",
                  }}
                  src={avatar}
                />
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "18px",
                    color: theme.palette.text.black, // Return the value directly
                  }}
                >
                  {user.name}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 4,
                    mt: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <img width="20" src={pinIcon} />
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "12px",
                        color: theme.palette.text.primary, // Return the value directly
                      }}
                    >
                      Canada
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <img width="20" src={joinIcon} />

                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "12px",
                        color: theme.palette.text.primary, // Return the value directly
                      }}
                    >
                      Joined May 27
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              marginTop: "20px",
              borderBottom: theme.palette.border.primary,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
                fontSize: "20px",
                color: theme.palette.text.primary, // Return the value directly
              }}
            >
              Account information:
            </Typography>
            <Grid container mt={2}>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 600,
                    fontSize: "20px",
                    color: theme.palette.text.primary,
                    mb: 1,
                  }}
                >
                  Name
                </Typography>
                <TextField
                  sx={{
                    color: "#1C1B1F",
                    width: "60%",
                    mb: 2,
                    "& .MuiInputLabel-root": {
                      color: "#1C1B1F !important", // Adding !important to ensure the color overrides
                    },
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: (theme) => theme.palette.text.secondary, // Change outlined border color on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1C1B1F", // Change outlined border color when focused
                      },
                    },
                  }}
                  size="small"
                  fullWidth
                  value={user.name}
                  variant="outlined"
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 600,
                    fontSize: "20px",
                    color: theme.palette.text.primary,
                    mb: 1,
                  }}
                >
                  Address
                </Typography>
                <TextField
                  multiline
                  sx={{
                    color: "#1C1B1F",
                    width: "60%",
                    mb: 2,
                    "& .MuiInputLabel-root": {
                      color: "#1C1B1F !important", // Adding !important to ensure the color overrides
                    },
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: (theme) => theme.palette.text.secondary, // Change outlined border color on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1C1B1F", // Change outlined border color when focused
                      },
                    },
                  }}
                  size="small"
                  fullWidth
                  value={
                    user.address ||
                    "45 New Avenue Toronto,ON - L5N 1B2, Canada."
                  }
                  variant="outlined"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <Box
                    sx={{
                      width: "80%",
                      // display: "flex",
                      paddingRight: "24px",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 600,
                        fontSize: "20px",
                        mb: 1,
                        color: theme.palette.text.primary,
                      }}
                    >
                      Phone Number
                    </Typography>
                    <TextField
                      sx={{
                        color: "#1C1B1F",
                        mb: 2,
                        ml: "auto",
                        "& .MuiInputLabel-root": {
                          color: "#1C1B1F !important",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            borderColor: (theme) =>
                              theme.palette.text.secondary,
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#1C1B1F",
                          },
                        },
                      }}
                      size="small"
                      fullWidth
                      value={user.phone || " +1 123456789"}
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "80%",
                      // display: "flex",
                      flexDirection: "column",
                      paddingRight: "24px",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 600,
                        fontSize: "20px",
                        mb: 1,
                        color: theme.palette.text.primary,
                      }}
                    >
                      Member ID | Joined Since
                    </Typography>
                    <TextField
                      sx={{
                        color: "#1C1B1F",
                        mb: 2,
                        ml: "auto",
                        "& .MuiInputLabel-root": {
                          color: "#1C1B1F !important",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            borderColor: (theme) =>
                              theme.palette.text.secondary,
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#1C1B1F",
                          },
                        },
                      }}
                      size="small"
                      fullWidth
                      value={user.memberId || "EID123456 | 24/05/24"}
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ marginTop: "20px", mb: 3 }}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
                fontSize: "20px",
                color: theme.palette.text.primary, // Return the value directly
              }}
            >
              Edit Your Account information:
            </Typography>
            <Grid container mt={2}>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 600,
                    fontSize: "20px",
                    color: theme.palette.text.primary,
                    mb: 1,
                  }}
                >
                  2FA
                </Typography>
                <Button
                  onClick={() => setMfaScreen(true)}
                  variant="contained"
                  size="small"
                  sx={{
                    borderRadius: "4px",
                    backgroundColor: theme.palette.text.primary,
                    color: theme.palette.primary.main,
                    textTransform: "capitalize",
                    fontWeight: 500,
                    "&:hover": {
                      backgroundColor: theme.palette.text.primary,
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  Re-Register 2FA
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <Box
                    sx={{
                      width: "27%",
                      // display: "flex",
                      paddingRight: "24px",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 600,
                        fontSize: "20px",
                        mb: 1,
                        color: theme.palette.text.primary,
                      }}
                    >
                      Password
                    </Typography>
                    <Button
                      onClick={handleChangePassword}
                      variant="contained"
                      size="small"
                      sx={{
                        borderRadius: "4px",
                        backgroundColor: theme.palette.text.primary,
                        color: theme.palette.primary.main,
                        textTransform: "capitalize",
                        fontWeight: 500,
                        "&:hover": {
                          backgroundColor: theme.palette.text.primary,
                          color: theme.palette.primary.main,
                        },
                      }}
                    >
                      Change Password
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}
