import { Typography } from "@mui/material";
import React from "react";
import ReactApexChart from "react-apexcharts";

const PieChart = ({ percentage, title }) => {
  const remaining = 100 - percentage;

  const chartState = {
    series: [percentage, remaining],
    options: {
      chart: {
        type: "donut",
      },
      plotOptions: {
        pie: {
          donut: {
            size: "60%",
            labels: {
              show: true,
              name: {
                show: false,
              },
              value: {
                show: true,
                formatter: () => `${percentage}%`,
                fontSize: "20px",
                fontWeight: 700,
                color: "rgba(104, 60, 140, 1)",
                offsetY: 10,
              },
              total: {
                show: true,
                showAlways: true,
                label: "",
                formatter: () => `${percentage}%`,
                style: {
                  fontSize: "20px",
                  fontWeight: 700,
                  color: "rgba(104, 60, 140, 1)",
                },
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      colors: ["rgba(104, 60, 140, 1)", "rgba(104, 60, 140, 0.35)"],
      legend: {
        show: false,
      },
    },
  };

  return (
    <div style={{ width: "180px", height: "180px", position: "relative" }}>
      <ReactApexChart
        options={chartState.options}
        series={chartState.series}
        type="donut"
        width="100%"
        height="100%"
      />
      <Typography
        sx={{
          textAlign: "center",
          fontSize: "12px",
          color: "#683C8C",
          fontWeight: 600,
          marginTop: "-20px",
        }}
      >
        {title}
      </Typography>
    </div>
  );
};

export default PieChart;
