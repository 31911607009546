import {
  Box,
  Grid,
  Select,
  MenuItem,
  TextField,
  Typography,
  Button,
  IconButton,
  Checkbox,
  Avatar,
  Tooltip,
  useTheme,
} from "@mui/material";
import {
  StarOutlined,
  Visibility,
  StarBorder,
  ArrowDropDown,
  East,
} from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import OrderPageCard from "../../components/cards/OrderPageCard";
import DynamicTable from "../../components/DynamicTable";
import { usePagination } from "../../hooks";
import { $crud } from "../../utils/CrudFactory";
import { useNavigate } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Filter from "../../components/Filter";
import { chatsFilter, orderFilters } from "../../utils/filterConfig";
import ChatPopUp from "../../components/modals/ChatPopUp";

export function Chats() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);
  const [chatTitle, setChatTitle] = useState("");
  const [chatRow, setChatRow] = useState(null);
  const [openChatPopup, setOpenChatPopup] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const handleOpenChatPopup = (e, row) => {
    setOpenChatPopup(true);
    setChatRow(row);
    if (e.target.innerText == "Go To Chat") {
      setChatTitle("Are you sure, you want to go to chat?");
    } else {
      setChatTitle("Are you sure, you want to Accept or start the chat?");
    }
  };
  const handleCloseChatPopup = () => {
    setOpenChatPopup(false);
    setChatRow(null);
  };
  // STATE TO MANAGE FILTERS --------------------------------------
  const [filtrationData, setFiltrationData] = useState({
    search: "",
    from: "",
    to: "",
    selectAll: "",
    liveChat: "",
    supportEngaged: "",
    supportRelated: "",
  });
  // STATE TO MANAGE FILTERS --------------------------------------

  const [rows, setRows] = useState([]); //STATE TO GET THE ORDER LIST
  const { page, limit, setLimit, setPage, total, setTotal } = usePagination(); // PAGINATION PROPS

  // console.log(filtrationData, "filter data ");
  // ========================= FUNCTION TO GET THE ORDER DATA FROM DATABASE(calling orders api) =============================
  async function retrieveData() {
    const { data } = await $crud.retrieve("admin/chats", {
      limit,
      page,
      search: filtrationData.search,
      from: filtrationData.from,
      to: filtrationData.to,
      selectAll: filtrationData.selectAll,
      liveChat: filtrationData.liveChat,
      supportEngaged: filtrationData.supportEngaged,
      supportRelated: filtrationData.supportRelated,
    });
    return data;
  }
  // ========================= FUNCTION TO GET THE ORDER DATA FROM DATABASE(calling orders api) =============================

  useEffect(() => {
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
    retrieveData().then((res) => {
      setRows(res.result);
      setTotal(res.total);
    });
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
  }, [limit, page, filtrationData]);

  const statusOptions = ["Blocked", "Active"];
  const customCheckboxStyles = {
    "&.Mui-checked": {
      color: "#fff",
      // borderColor: "#fff",
    },

    color: "#fff",
  };
  // COLUMNS IN THE ORDER TABLE-----------------------------
  const chatColumns = [
    // {
    //   id: "checkbox",
    //   label: (
    //     <Checkbox
    //       indeterminate={
    //         selectedRows.length > 0 && selectedRows.length < rows.length
    //       }
    //       sx={customCheckboxStyles}
    //       checked={rows.length > 0 && selectedRows.length === rows.length}
    //       onChange={(event) => {
    //         if (event.target.checked) {
    //           const newSelecteds = rows.map((row) => row.id);
    //           setSelectedRows(newSelecteds);
    //         } else {
    //           setSelectedRows([]);
    //         }
    //       }}
    //       inputProps={{ "aria-label": "select all chats" }}
    //     />
    //   ),
    //   align: "center",
    //   renderCell: (row) => {
    //     return (
    //       <Checkbox
    //         sx={{
    //           color: "rgba(103, 60, 142, 1)",
    //           borderRadius: "0",
    //           "&.Mui-checked": {
    //             color: "rgba(103, 60, 142, 1)",
    //           },
    //         }}
    //         checked={selectedRows.indexOf(row.id) !== -1}
    //         onChange={(event) => {
    //           const newSelected = [...selectedRows];
    //           console.log(newSelected);
    //           if (event.target.checked) {
    //             // Add row ID if not present
    //             if (!newSelected.includes(row.id)) {
    //               newSelected.push(row.id);
    //             }
    //           } else {
    //             // Remove row ID if present
    //             const index = newSelected.indexOf(row.id);
    //             if (index > -1) {
    //               newSelected.splice(index, 1);
    //             }
    //           }
    //           setSelectedRows(newSelected);
    //         }}
    //         inputProps={{ "aria-label": `select chat ${row.id}` }}
    //       />
    //     );
    //   },
    // },
    {
      id: "sessionID",
      label: "Session ID",
      align: "center",
      renderCell: (row) => {
        return (
          <Typography sx={{ fontSize: "12px", fontWeight: 400, color: "#000" }}>
            {row.sessionID}
          </Typography>
        );
      },
    },
    {
      id: "chatParty1",
      label: "Chat Party 1",
      align: "center",
      renderCell: (row) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Avatar
              sx={{ width: "30px", height: "30px" }}
              alt={row.chatParty1.name}
            />
            <Box sx={{ textAlign: "left" }}>
              <Typography
                sx={{ fontSize: "14px", fontWeight: 500, color: "#000" }}
              >
                {row.chatParty1.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: 500,
                  color: "rgba(104, 60, 140, 1)",
                }}
              >
                {row.chatParty1?.anotherPerson}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      id: "action",
      label: "Action",
      align: "center",
      renderCell: (row) => {
        const getStatusStyles = (status) => {
          switch (status) {
            case "Accept Invite":
              return {
                background: "rgba(248, 48, 48, 1)",
              };
            case "Start Chat":
              return {
                background: "rgba(103, 60, 142, 1)",
              };
            case "Go to Chat":
              return {
                background: "rgba(8, 175, 75, 1)",
              };
            default:
              return {
                background: "#FFF0ED",
              };
          }
        };
        const styles = getStatusStyles(row.action);
        return (
          <Button
            onClick={(e) => handleOpenChatPopup(e, row)}
            variant="contained"
            size="small"
            sx={{
              padding: "7px 10px",
              textTransform: "capitalize",
              backgroundColor: styles.background,
              color: "#fff",
              fontSize: "13px",
              fontWeight: 400,
              width: "110px",
              "&:hover": {
                backgroundColor: styles.background,
                color: "#fff",
              },
            }}
          >
            {row.action}
          </Button>
        );
      },
    },
    {
      id: "dateTime",
      label: "Date & Time",
      align: "center",
    },
    {
      id: "orderID",
      label: "Order ID",
      align: "center",
      renderCell: (row) => {
        return (
          <Typography sx={{ fontSize: "14px", fontWeight: 500, color: "#000" }}>
            {row.orderID}
          </Typography>
        );
      },
    },
    {
      id: "between",
      label: "Between",
      align: "center",
      renderCell: (row) => {
        return (
          <Button
            variant="outlined"
            size="small"
            sx={{
              padding: "7px 10px",
              textTransform: "capitalize",
              backgroundColor: "transparent",
              cursor: "auto",
              color: "#683C8C",
              borderColor: "#683C8C",
              fontSize: "13px",
              width: "120px",
              "&:hover": {
                backgroundColor: "transparent",
                color: "#683C8C",
                borderColor: "#683C8C",
              },
            }}
          >
            {row.between.name1}{" "}
            <East sx={{ fontSize: "15px", margin: "0 4px" }} />{" "}
            {row.between.name2}
          </Button>
        );
      },
    },
    {
      id: "chatParty2",
      label: "Chat Party 2",
      align: "center",
      renderCell: (row) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              justifyContent: "center",
            }}
          >
            <Avatar
              sx={{ width: "30px", height: "30px" }}
              alt={row.chatParty2.name}
            />
            <Box sx={{ textAlign: "left" }}>
              <Typography
                sx={{ fontSize: "14px", fontWeight: 500, color: "#000" }}
              >
                {row.chatParty2.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: 500,
                  color: "rgba(104, 60, 140, 1)",
                }}
              >
                {row.chatParty2?.anotherPerson}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      id: "status",
      label: "Status",
      align: "center",
      renderCell: (row) => {
        const getStatusStyles = (status) => {
          switch (status) {
            case "Live Chat":
              return {
                background: "rgba(8, 175, 75, 1)",
                color: "#fff",
                fontSize: "15px",
              };
            case "Queued":
              return {
                background: "rgba(232, 170, 48, 1)",
                color: "#fff",
                fontSize: "15px",
              };
            case "Chat Ended":
              return {
                background: "rgba(57, 92, 218, 1)",
                color: "#fff",
                fontSize: "15px",
              };
            default:
              return {
                background: "#FFF0ED",
                color: "rgba(254, 133, 106, 1)",
              };
          }
        };
        const styles = getStatusStyles(row.status);
        return (
          <Button
            variant="contained"
            size="small"
            sx={{
              padding: "7px 10px",
              textTransform: "capitalize",
              backgroundColor: styles.background,
              cursor: "auto",
              color: "#fff",
              fontSize: "13px",
              fontWeight: 400,
              width: "110px",
              "&:hover": {
                backgroundColor: styles.background,
                color: "#fff",
              },
            }}
          >
            {row.status}
          </Button>
        );
      },
    },
    {
      id: "action",
      label: "View",
      renderCell: (row) => {
        return (
          <IconButton
            sx={{ fontSize: "8px", color: "rgba(104, 60, 140, 1)" }}
            onClick={() =>
              navigate(`/chats/chat-detail/${row.sessionID}`, {
                state: { chat: row },
              })
            }
          >
            <Visibility />
          </IconButton>
        );
      },
    },
  ];

  // COLUMNS IN THE ORDER TABLE-----------------------------

  // FUNCTION TO SET THE FILTERS IN filtrationData STATE -----------------------------
  const getFilteredData = (data) => {
    setFiltrationData({
      ...filtrationData,
      ...data,
    });
  };
  // FUNCTION TO SET THE FILTERS IN filtrationData STATE -----------------------------

  const handleSearchInputChange = (value) => {
    setSearchInput(value);
    setFiltrationData((prevData) => ({
      ...prevData,
      search: value,
    }));
  };
  return (
    <>
      <Grid container sx={{ padding: "0 18px" }}>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              fontSize: "30px",
              // color: "#673C8E",
              color: theme.palette.text.primary,
            }}
          >
            Chats
          </Typography>
        </Grid>
      </Grid>

      {/*========================= CALLING DYNAMIC TABLE COMPONENT TO SHOW THE CLIENTS LIST IN TABLE ===========================*/}
      <DynamicTable
        rows={rows}
        type="chats"
        url="chats"
        searchPlaceholderData={searchInput}
        onSearchData={handleSearchInputChange}
        setRows={setRows}
        filterConfig={chatsFilter} // Pass the filter checkbox options
        noRecordTitle="No Chats Yet !" // no data title
        onFilterData={getFilteredData} // filteration data
        onResetFilters={() => {
          setFiltrationData((prev) => ({
            search: prev.search,
            supportRelated: "",
            supportEngaged: "",
            liveChat: "",
            from: "",
            to: "",
            selectAll: "",
          }));
        }}
        paginationProps={{ limit, page, setLimit, setPage, total }}
        columns={chatColumns} // Pass the columns prop
      />
      <ChatPopUp
        title={chatTitle}
        open={openChatPopup}
        onClose={handleCloseChatPopup}
        row={chatRow}
      />
      {/*========================= CALLING DYNAMIC TABLE COMPONENT TO SHOW THE ORDER LIST IN TABLE ===========================*/}
    </>
  );
}
