import React from "react";
import {
  Box,
  IconButton,
  Typography,
  Pagination,
  PaginationItem,
  Select,
  MenuItem,
  useTheme,
} from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

const CustomPagination = ({
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
  total,
  type,
}) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{ padding: "16px" }}
    >
      <Typography
        variant="body2"
        sx={{
          marginRight: "16px",
          display: type == "driverDetails" ? "none" : "block",
        }}
      >
        Showing {page * rowsPerPage + 1} to{" "}
        {Math.min((page + 1) * rowsPerPage, total)} from {total}
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          background: theme.palette.primary.main,
          padding: "4px 2px",
          borderRadius: "4px",
        }}
      >
        <Pagination
          count={Math.ceil(total / rowsPerPage)}
          page={page + 1}
          onChange={(event, value) => onPageChange(value - 1)}
          renderItem={(item) => (
            <PaginationItem
              components={{
                previous: KeyboardArrowLeft,
                next: KeyboardArrowRight,
              }}
              {...item}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#673C8E",
                  color: "#FFFFFF",
                },
                borderColor: "#673C8E",
                borderWidth: "1px",
                borderStyle: "solid",
                borderRadius: "4px",
                color: "#673C8E",
              }}
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default CustomPagination;
