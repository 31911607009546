import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogContent,
  Backdrop,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { enqueueSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backdropFilter: "blur(2px)", // Adjust blur strength as needed
  },
  dialogPaper: {
    borderRadius: "0", // Set border radius to 0
    border: "1px solid #000",
  },
  textFieldRoot: {
    "& .MuiInputLabel-root": {
      color: "#8D8D8E !important", // Adding !important to ensure the color overrides
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(103, 60, 142, 1)", // Initial outlined border color
      },
      "&:hover fieldset": {
        borderColor: "rgba(103, 60, 142, 1)", // Change outlined border color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(103, 60, 142, 1)", // Change outlined border color when focused
      },
    },
  },
}));
const DriverVipModal = ({ onClose, open, row, onSubmit }) => {
  const classes = useStyles();
  const [text, setText] = useState("");
  const handleSubmit = () => {
    if (text === "VIP") {
      onSubmit(row._id, row.vipDriver);
      onClose();
    } else {
      enqueueSnackbar("Type 'VIP' to confirm!", {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={open} />
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="xs"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogContent>
          <Typography
            id="modal-title"
            sx={{ fontSize: "18px", mb: 2, fontWeight: 600 }}
            gutterBottom
          >
            Vip Driver
          </Typography>
          <TextField
            id="reason"
            fullWidth
            variant="outlined"
            placeholder="Type 'VIP' to confirm"
            sx={{
              color: "#1C1B1F",
              "& .MuiInputLabel-root": {
                color: "#8D8D8E !important", // Adding !important to ensure the color overrides
              },
              "& fieldset": {
                borderColor: "rgba(103, 60, 142, 1)", // Initial outlined border color
                borderRadius: "8px",
              },
              "& .MuiOutlinedInput-root": {
                "&:hover fieldset": {
                  borderColor: "rgba(103, 60, 142, 1)", // Change outlined border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "rgba(103, 60, 142, 1)", // Change outlined border color when focused
                },
              },
            }}
            onChange={(e) => setText(e.target.value)}
          />
          <Box sx={{ mt: 2, textAlign: "right" }}>
            <Button
              sx={{
                borderColor: "#683C8C",
                color: "#683C8C",
                padding: "6px 20px",
                fontSize: "15px",
                fontWeight: 400,
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#683C8C",
                  color: "#FFFFFF",
                },
                mr: 1,
              }}
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: "#683C8C",
                color: "#FFFFFF",
                padding: "6px 20px",
                fontSize: "15px",
                fontWeight: 400,
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#683C8C",
                  color: "#FFFFFF",
                },
              }}
              variant="contained"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DriverVipModal;
