import {
  Avatar,
  Badge,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import {
  NotificationsNoneOutlined,
  SmsOutlined,
  SettingsOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import notf from "../assets/images/svg/notf.svg";
import chatHeader from "../assets/images/svg/chatHeader.svg";
import setting from "../assets/images/svg/setting.svg";
import avatarIcon from "../assets/images/svg/avatar.svg";

export function Header() {
  const user = JSON.parse(localStorage.getItem("user"));
  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  return (
    <Grid container sx={{ padding: "30px 18px", paddingBottom: "20px" }}>
      <Grid
        item
        xs={12}
        md={7}
        lg={9}
        sx={{ borderRight: "1px solid #D0D6DE" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
            cursor: "pointer",
          }}
        >
          <Box sx={{ width: { xs: "inherit", md: "70%" } }}>
            <TextField
              name="search"
              placeholder="Search here..."
              sx={{
                width: "90%",
                color: "#1C1B1F",
                "& .MuiInputLabel-root": {
                  color: "#1C1B1F !important", // Adding !important to ensure the color overrides
                },
                "& .MuiOutlinedInput-root": {
                  paddingTop: "5px", // Increase padding
                  paddingBottom: "5px", // Increase padding
                  "& fieldset": {
                    borderColor: "#673C8E", // Initial border color
                    borderWidth: "1px", // Increase border width
                    borderRadius: "8px",
                  },
                  "&:hover fieldset": {
                    borderColor: "#673C8E", // Change outlined border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#673C8E", // Change outlined border color when focused
                  },
                },
              }}
              size="small"
              id="email"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                    <SearchOutlined />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box>
            <IconButton
              sx={{
                background: "#673C8E59",
                // color: "#673C8E",
                mr: "30px",
                borderRadius: "15px",
              }}
            >
              <img style={{ width: "26px" }} src={notf} alt="Logo" />
            </IconButton>
            <IconButton
              sx={{
                background: "#673C8E59",
                // color: "#673C8E",
                mr: "30px",
                borderRadius: "15px",
              }}
            >
              <img style={{ width: "26px" }} src={chatHeader} alt="Logo" />
            </IconButton>
            <IconButton
              sx={{
                background: "#074F2459",
                // color: "#074F24",
                mr: "18px",
                borderRadius: "15px",
              }}
            >
              <img style={{ width: "26px" }} src={setting} alt="Logo" />
            </IconButton>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={5} lg={3} sx={{ textAlign: "right", pr: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            gap: 2,
            cursor: "pointer",
          }}
        >
          <Typography sx={{ textTransform: "capitalize" }}>
            Hello, <b>{user.name}</b>
          </Typography>
          <Avatar
            sx={{ background: "#673C8E" }}
            alt={capitalizeFirstLetter(user.name)}
            src={avatarIcon}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
