import React, { useState, useEffect, useRef } from "react";
import { IconButton, Box } from "@mui/material";
import Mic from "@mui/icons-material/Mic";
import Send from "@mui/icons-material/Send";
import Delete from "@mui/icons-material/Delete";
import PlayArrow from "@mui/icons-material/PlayArrow";
import Pause from "@mui/icons-material/Pause";

const ChatAudioRecorder = ({
  isRecording,
  handleMicClick,
  onSend,
  setAudioFileName,
  audioFileName,
}) => {
  const [audioFile, setAudioFile] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [stream, setStream] = useState(null);
  const [recordingTime, setRecordingTime] = useState(0); // Timer state
  const [finalRecordingTime, setFinalRecordingTime] = useState(0); // State to save the duration after recording stops
  const [isPlaying, setIsPlaying] = useState(false); // State to manage audio playback
  const audioRef = useRef(null);
  const recordedChunksRef = useRef([]); // Ref for recorded chunks
  const [isRecordingInProgress, setIsRecordingInProgress] = useState(false);

  useEffect(() => {
    if (isRecording && !isRecordingInProgress) {
      requestMicrophoneAccess()
        .then((stream) => {
          setStream(stream);
          const recorder = new MediaRecorder(stream);

          recorder.ondataavailable = (event) => {
            recordedChunksRef.current = [
              ...recordedChunksRef.current,
              event.data,
            ];
          };

          recorder.onstop = () => {
            if (recordedChunksRef.current.length > 0) {
              const blob = new Blob(recordedChunksRef.current, {
                type: "audio/webm;codecs=opus",
              });
              const audioUrl = URL.createObjectURL(blob);
              setAudioFile(blob);
              setAudioFileName(`recorded_${new Date().getTime()}.webm`);
              recordedChunksRef.current = []; // Clear chunks after creating blob
              audioRef.current.src = audioUrl;
            }
          };

          recorder.start();
          setMediaRecorder(recorder);
          setIsRecordingInProgress(true);
        })
        .catch((err) => {
          console.error("Error accessing microphone:", err);
        });
    } else if (!isRecording && isRecordingInProgress) {
      if (mediaRecorder) {
        mediaRecorder.stop();
      }
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
      setMediaRecorder(null);
      setStream(null);
      setIsRecordingInProgress(false);
      setFinalRecordingTime(recordingTime); // Set final recording time when recording stops
    }
  }, [isRecording, isRecordingInProgress]);

  // Timer logic
  useEffect(() => {
    let timer;
    if (isRecording) {
      timer = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    } else if (!isRecording && audioFile) {
      clearInterval(timer);
    } else {
      clearInterval(timer);
      setRecordingTime(0); // Reset timer when recording stops
    }
    return () => clearInterval(timer);
  }, [isRecording, audioFile]);

  const requestMicrophoneAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      return stream;
    } catch (err) {
      console.error("Error accessing microphone:", err);
      throw err;
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };

  const handleDeleteAudio = () => {
    setAudioFile(null);
    setAudioFileName("");
    setRecordingTime(0); // Reset timer
    setFinalRecordingTime(0); // Reset final recording time
  };

  // Play/pause audio functionality
  const handlePlayPauseClick = () => {
    if (!isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const audioElement = audioRef.current;
    audioElement.addEventListener("ended", () => setIsPlaying(false));
    return () => {
      audioElement.removeEventListener("ended", () => setIsPlaying(false));
    };
  }, []);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {/* Mic Button */}
      {!audioFile && (
        <IconButton
          onClick={handleMicClick}
          sx={{
            backgroundColor: isRecording
              ? "rgba(103, 60, 142, 1)"
              : "transparent",
            color: isRecording ? "#fff" : "#673C8E",
            borderRadius: "50%",
            padding: isRecording ? "8px" : "0",
            transition: "background-color 0.3s ease, color 0.3s ease",
            "&:hover": {
              backgroundColor: isRecording
                ? "rgba(103, 60, 142, 0.75)"
                : "rgba(103, 60, 142, 0.15)",
              color: isRecording ? "#fff" : "#673C8E",
            },
          }}
        >
          <Mic />
        </IconButton>
      )}

      {/* Timer display during recording */}
      {isRecording && (
        <Box sx={{ display: "flex", alignItems: "center", marginLeft: "8px" }}>
          <span style={{ color: "#673C8E", fontWeight: "bold" }}>
            {formatTime(recordingTime)}
          </span>
        </Box>
      )}

      {/* Show Send, Delete, Play/Pause buttons along with recorded time after recording */}
      {audioFile && (
        <>
          {/* Delete Button */}
          <IconButton
            onClick={handleDeleteAudio}
            sx={{
              backgroundColor: "rgba(255, 0, 0, 0.8)",
              color: "#fff",
              borderRadius: "50%",
              padding: "8px",
              mr: 1,
              transition: "background-color 0.3s ease",
              "&:hover": {
                backgroundColor: "rgba(255, 0, 0, 0.6)",
              },
            }}
          >
            <Delete sx={{ fontSize: "16px" }} />
          </IconButton>
          {/* Play/Pause Button */}
          <IconButton
            onClick={handlePlayPauseClick}
            sx={{
              backgroundColor: "rgba(103, 60, 142, 1)",
              color: "#fff",
              borderRadius: "50%",
              padding: "8px",
              mr: 1,
              transition: "background-color 0.3s ease",
              "&:hover": {
                backgroundColor: "rgba(103, 60, 142, 0.75)",
              },
            }}
          >
            {isPlaying ? (
              <Pause sx={{ fontSize: "16px" }} />
            ) : (
              <PlayArrow sx={{ fontSize: "16px" }} />
            )}
          </IconButton>
          {/* Recorded Duration */}
          <Box sx={{ display: "flex", alignItems: "center", mr: 1 }}>
            <span style={{ color: "#673C8E", fontWeight: "bold" }}>
              {formatTime(finalRecordingTime)}
            </span>
          </Box>

          {/* Send Button */}
          <IconButton
            onClick={() => {
              onSend(audioFile, audioFileName);
              handleDeleteAudio(); // Reset after sending
            }}
            sx={{
              backgroundColor: "rgba(103, 60, 142, 1)",
              color: "#fff",
              borderRadius: "50%",
              padding: "8px",
              transition: "background-color 0.3s ease",
              "&:hover": {
                backgroundColor: "rgba(103, 60, 142, 0.75)",
              },
            }}
          >
            <Send sx={{ fontSize: "16px" }} />
          </IconButton>
        </>
      )}

      <audio ref={audioRef} controls style={{ display: "none" }} />
    </Box>
  );
};

export default ChatAudioRecorder;
