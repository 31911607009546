import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  LabelList,
  ResponsiveContainer,
  Legend,
  YAxis,
  Cell,
} from "recharts";

// Sample data
const driverData = [
  { name: "Sun", value: 47, fill: "rgba(104, 60, 140, 0.35)" },
  { name: "Sun", value: 97, fill: "rgba(104, 60, 140, 1)" },
  { name: "Sun", value: 72, fill: "rgba(104, 60, 140, 0.35)" },
  { name: "Sun", value: 40, fill: "rgba(104, 60, 140, 1)" },
  { name: "Sun", value: 32, fill: "rgba(104, 60, 140, 0.35)" },
  { name: "Sun", value: 76, fill: "rgba(104, 60, 140, 1)" },
  { name: "Sun", value: 87, fill: "rgba(104, 60, 140, 0.35)" },
];

const CustomerBarChart = () => {
  return (
    <ResponsiveContainer
      style={{ marginLeft: "-50px" }}
      width={400}
      height={175}
    >
      <BarChart
        width={500}
        height={300}
        data={driverData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis
          dataKey="name"
          axisLine={false}
          tickLine={false}
          tick={{
            fontSize: "11px",
            fontWeight: 400,
            fill: "rgba(103, 60, 142, 1)",
          }}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tick={{
            fontSize: "11px",
            fontWeight: 400,
            fill: "rgba(103, 60, 142, 1)",
          }}
          ticks={[0, 20, 40, 60, 80]}
        />
        <Bar dataKey="value" barSize={10}>
          {driverData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.fill} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default CustomerBarChart;
