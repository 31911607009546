import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogContent,
  Backdrop,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backdropFilter: "blur(2px)", // Adjust blur strength as needed
  },
  dialogPaper: {
    borderRadius: "0", // Set border radius to 0
    border: "1px solid #000",
  },
  textFieldRoot: {
    "& .MuiInputLabel-root": {
      color: "#8D8D8E !important", // Adding !important to ensure the color overrides
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(103, 60, 142, 1)", // Initial outlined border color
      },
      "&:hover fieldset": {
        borderColor: "rgba(103, 60, 142, 1)", // Change outlined border color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(103, 60, 142, 1)", // Change outlined border color when focused
      },
    },
  },
}));
const ChatUploader = ({ open, onClose, onSend, file, fileType }) => {
  const classes = useStyles();

  return (
    <>
      <Backdrop className={classes.backdrop} open={open} />
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="xs"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogContent sx={{ padding: "20px 50px" }}>
          <Box sx={{}}>
            <Typography variant="h6" sx={{ textAlign: "center", mb: 2 }}>
              {fileType === "image" ? "Upload Image" : "Upload Document"}
            </Typography>
            <Box sx={{ border: "1px solid black", padding: "2px" }}>
              {/* Display the selected image or document preview here */}
              {file && fileType === "image" && (
                <img
                  src={URL.createObjectURL(file)}
                  alt="Selected file"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "300px",
                  }}
                />
              )}
              {file && (fileType === "document" || fileType === "video") && (
                <Typography>{file.name}</Typography>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: "28px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              //   padding: "0 56px",
            }}
          >
            <Button
              sx={{
                borderColor: "#683C8C",
                color: "#683C8C",
                padding: "6px 40px",
                fontSize: "15px",
                fontWeight: 400,
                textTransform: "capitalize",
                "&:hover": {
                  borderColor: "#683C8C",
                  backgroundColor: "transparent",
                  color: "#683C8C",
                },
                mr: 1,
              }}
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: "#683C8C",
                color: "#FFFFFF",
                padding: "6px 40px",
                fontSize: "15px",
                fontWeight: 400,
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#683C8C",
                  color: "#FFFFFF",
                },
              }}
              variant="contained"
              onClick={() => onSend(file)}
            >
              Send
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ChatUploader;
