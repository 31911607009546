import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import { SearchOutlined } from "@mui/icons-material";

const Search = ({
  padding,
  placeholder,
  borderRadius,
  onSearchData,
  type,
  value,
}) => {
  const onUserInput = (e) => {
    const { name, value } = e.target;
    onSearchData(value); // Call the parent function with the updated data
  };
  return (
    <TextField
      value={value || ""}
      name="search"
      onChange={onUserInput}
      placeholder={placeholder}
      sx={{
        width: "100%",
        color: "#1C1B1F",
        display:
          type == "clients" ||
          type == "drivers" ||
          type == "regularOffers" ||
          type == "helpOffers" ||
          type == "clientStatement" ||
          type == "driverStatement" ||
          type == "executiveStatement"
            ? "none"
            : "flex",
        "& .MuiInputLabel-root": {
          color: "#1C1B1F !important", // Adding !important to ensure the color overrides
        },
        "& .MuiOutlinedInput-root": {
          padding: padding, // Increase padding
          //   paddingBottom: "5px", // Increase padding
          "& fieldset": {
            borderColor: "#673C8E", // Initial border color
            borderWidth: "1px", // Increase border width
            borderRadius: borderRadius,
          },
          "&:hover fieldset": {
            borderColor: "#673C8E", // Change outlined border color on hover
          },
          "&.Mui-focused fieldset": {
            borderColor: "#673C8E", // Change outlined border color when focused
          },
        },
      }}
      size="small"
      // label="Search"
      id="email"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{ cursor: "pointer" }}>
            <SearchOutlined sx={{ fontSize: "30px" }} />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default Search;
