import React, { useRef, useImperativeHandle } from "react";
import { TextField } from "@mui/material";

const CustomTextField = React.forwardRef(
  ({ value, onChange, onLastKey, onKeyDown, onMoveNext, onMovePrev }, ref) => {
    const inputRef = useRef(null);

    useImperativeHandle(ref, () => inputRef.current);

    const handleKeyDown = (e) => {
      if (e.key === "Backspace") {
        if (!value && onMovePrev) {
          e.preventDefault();
          onMovePrev();
        }
      } else if (onLastKey && value !== "") {
        onLastKey();
      }

      onKeyDown && onKeyDown(e);
    };

    const handleChange = (e) => {
      onChange(e);
      if (e.target.value && onMoveNext) {
        onMoveNext();
      }
    };

    return (
      <TextField
        inputRef={inputRef}
        value={value}
        onChange={handleChange}
        inputProps={{ maxLength: 1 }}
        variant="outlined"
        onKeyDown={handleKeyDown}
        sx={{
          "& .MuiInputBase-root": {
            borderRadius: "8px",
          },
          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#C4C7C7 !important",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#C4C7C7 !important",
            },
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "8px",
            height: "50px",
            width: "44px",
            border: "1px solid #C4C7C7",
            top: 2,
            "&:hover": {
              border: "1px solid #C4C7C7",
            },
            "&:focus": {
              border: "1px solid #C4C7C7",
            },
          },
        }}
      />
    );
  }
);

export default CustomTextField;
