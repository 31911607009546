import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  Grid,
  Box,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  FormHelperText,
  Divider,
} from "@mui/material";
import { API_URL } from "../../constants";
import { enqueueSnackbar } from "notistack";
import loginMain from "../../assets/images/loginMain.png";
import login2 from "../../assets/images/login2.svg";
import errorImg from "../../assets/images/error.svg";
import logo from "../../assets/images/logo.svg";
import QR from "../../assets/images/svg/QR.svg";
import arrowIcon from "../../assets/images/svg/arrowIcon.svg";
import authImg1 from "../../assets/images/svg/authImg1.svg";
import authImg2 from "../../assets/images/svg/authImg2.svg";
import CustomTextField from "../../components/CustomTextField";

export function AuthenticatorScreen() {
  const [otpDigits, setOtpDigits] = useState(Array(6).fill(""));
  const [showContinue, setShowContinue] = useState(false);
  const refs = useRef({});

  useEffect(() => {
    if (showContinue && refs.current[5]) {
      refs.current[5].focus();
    }
  }, [showContinue]);

  const location = useLocation();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [otpCode, setOtpCode] = useState("");
  const [error, setError] = useState(null);
  const qrCodeDataURL = location.state?.qrCodeDataURL;
  const otpSecret = location.state?.otpSecret; // Assuming otpSecret is also passed
  const adminId = location.state?.userId;
  const token = location.state?.token;
  const user = location.state?.user;
  const changeMFA = location.state?.changeMFA;
  const isQRScanned = location.state?.isQRScanned;

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setStep(1);
  };

  const handleOtpChange = (e) => {
    setOtpCode(e.target.value);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(otpSecret)
      .then(() => {
        console.log("OTP secret copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleContinue = async () => {
    try {
      const otpCode = otpDigits.join("");
      const response = await axios.post(
        `${API_URL}create/admin/verifySecretKey`,
        {
          otpCode,
          adminId,
          isQRScanned,
        }
      );
      const { data, type, message } = response.data;
      enqueueSnackbar(message, { variant: type });
      if (type == "success") {
        let userData = JSON.parse(user);
        localStorage.setItem("token", token);
        localStorage.setItem("user", user);
        if (userData.deleteAccount) {
          navigate("/delete-account");
        } else if (changeMFA) {
          navigate("/account-settings");
        } else {
          navigate("/dashboard");
        }
      }
    } catch (error) {
      // Handle error
      console.error("Error verifying secret key:", error);
    }
  };

  const handleLastKey = () => {
    setShowContinue(true);
  };

  const handleChange = (index, value) => {
    // Update the entire otpDigits array
    const updatedOtpDigits = [...otpDigits];
    updatedOtpDigits[index] = value;
    setOtpDigits(updatedOtpDigits);

    // Move focus to the next input field if available
    if (value !== "" && index < otpDigits.length - 1) {
      refs.current[index + 1].focus();
    }

    // Check if this is the last digit
    if (index === 5) {
      setShowContinue(true); // Show the continue button
    }
  };

  const handleMoveNext = (index) => {
    if (index < otpDigits.length - 1 && refs.current[index + 1]) {
      refs.current[index + 1].focus();
    }
  };

  const handleMovePrev = (index) => {
    if (index > 0 && refs.current[index - 1]) {
      refs.current[index - 1].focus();
    }
  };
  return (
    <>
      <form>
        <Box
          sx={{
            height: "100vh",
            padding: "0",
            width: "100vw",
            display: "flex",
            boxSizing: "border-box",
          }}
        >
          <Box sx={{ position: "absolute", right: "20px", top: "10px" }}>
            <img style={{ width: "120px" }} src={logo} alt="Logo" />
          </Box>
          <Grid container>
            <Grid
              item
              xs={12}
              md={7.5}
              sx={{
                background: (theme) => theme.palette.background.paper,
                boxShadow: "0px 4px 10px 0px #673C8E",
                borderRight: "1px solid #673C8E",
                display: "grid",
                placeItems: "center",
              }}
            >
              {/* ================================= AuthenticatorScreen BOX ============================== */}
              <Box
                sx={{
                  maxWidth: {
                    xs: "270px",
                    md: "300px",
                    lg: "512px",
                  },
                  marginTop: {
                    xs: "40px",
                    md: "0",
                  },
                }}
              >
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <Typography
                      variant="h4"
                      sx={{
                        color: (theme) => theme.palette.text.secondary,
                        fontWeight: 700,
                      }}
                    >
                      {isQRScanned ? "2FA" : "2FA Registration"}
                    </Typography>
                    {!isQRScanned && (
                      <Typography
                        variant="subtitle1"
                        sx={{
                          color: "rgba(25, 28, 29, 1)",
                          marginTop: "10px",
                          fontWeight: 700,
                        }}
                      >
                        Step 1: Scan this QR code
                      </Typography>
                    )}

                    {isQRScanned ? (
                      <>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color: "rgba(25, 28, 29, 1)",
                            marginTop: "60px",
                            fontWeight: 700,
                          }}
                        >
                          Enter the one-time code
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color: "#747778",
                            opacity: "0.75",
                            // marginTop: "10px",
                          }}
                        >
                          Enter the 6-digit verification code generated by the
                          authenticator app.
                        </Typography>
                      </>
                    ) : (
                      <Typography
                        variant="subtitle1"
                        sx={{
                          color: "#313131",
                          marginTop: "10px",
                        }}
                      >
                        Scan the following QR code with your authenticator app,
                        such as Google Authenticator, Duo Mobile, Authy, etc.
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: "center" }}>
                    {!isQRScanned && (
                      <>
                        {step === 1 && (
                          <>
                            {qrCodeDataURL ? (
                              <img
                                width="200px"
                                src={qrCodeDataURL}
                                alt="QR Code"
                              />
                            ) : (
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  color: "rgba(25, 28, 29, 1)",
                                  marginTop: "10px",
                                  fontWeight: 700,
                                }}
                              >
                                QR Code not available
                              </Typography>
                            )}
                            <Box
                              onClick={handleNextStep}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "-10px",
                                marginBottom: "10px",
                                cursor: "pointer",
                              }}
                            >
                              <img src={arrowIcon} />

                              <Typography
                                variant="subtitle1"
                                sx={{
                                  color: "rgba(104, 60, 140, 1)",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                }}
                              >
                                Can’t scan the QR code?
                              </Typography>
                            </Box>
                          </>
                        )}
                        {step === 2 && (
                          <>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                color: "#191C1D",
                                fontSize: "14px",
                                fontWeight: 500,
                                background: "rgba(217, 217, 217, 0.22)",
                                padding: "18px",
                                marginBottom: "30px",
                              }}
                            >
                              {otpSecret}
                            </Typography>
                            <Button
                              onClick={handleCopyToClipboard}
                              variant="outlined"
                              fullWidth
                              size="small"
                              sx={{
                                backgroundColor: "transparent",
                                color: "#683C8C",
                                borderColor: "#683C8C", // for outlined variant
                                padding: "10px 8px",
                                "&:hover": {
                                  backgroundColor: "#683C8C",
                                  borderColor: "#683C8C", // for outlined variant hover
                                  color: "#FFFFFF",
                                },
                              }}
                            >
                              Copy
                            </Button>
                            <Box
                              onClick={handlePreviousStep}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "20px",
                                marginBottom: "10px",
                                cursor: "pointer",
                              }}
                            >
                              <img src={arrowIcon} />

                              <Typography
                                variant="subtitle1"
                                sx={{
                                  color: "rgba(104, 60, 140, 1)",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                }}
                              >
                                Can’t copy? Scan the QR code
                              </Typography>
                            </Box>
                          </>
                        )}
                      </>
                    )}
                  </Grid>
                  {!isQRScanned && (
                    <Grid item xs={12}>
                      <Divider sx={{ background: "rgba(120, 120, 120, 1)" }} />
                    </Grid>
                  )}
                  {!isQRScanned && (
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          color: "rgba(25, 28, 29, 1)",
                          marginTop: "10px",
                          fontWeight: 700,
                        }}
                      >
                        Step 2: Enter the one-time code
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          color: "#313131",
                          // marginTop: "10px",
                        }}
                      >
                        Enter the 6-digit verification code generated by the
                        authenticator app.
                      </Typography>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    {otpDigits.map((digit, index) => (
                      <CustomTextField
                        key={index}
                        value={digit}
                        onChange={(e) => handleChange(index, e.target.value)}
                        onLastKey={index === 5 ? () => {} : null} // No-op for now
                        onMoveNext={() => handleMoveNext(index)}
                        onMovePrev={() => handleMovePrev(index)}
                        ref={(el) => (refs.current[index] = el)}
                      />
                    ))}
                  </Grid>
                  {showContinue && (
                    <Grid item xs={12} mt={3}>
                      <Button
                        onClick={handleContinue}
                        variant="contained"
                        fullWidth
                        size="small"
                        sx={{
                          backgroundColor: "#683C8C",
                          color: "#fff",
                          borderColor: "#683C8C", // for outlined variant
                          padding: "10px 8px",
                          "&:hover": {
                            backgroundColor: "#683C8C",
                            borderColor: "#683C8C", // for outlined variant hover
                            color: "#FFFFFF",
                          },
                        }}
                      >
                        continue
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Box>
              {/* ================================= AuthenticatorScreen BOX ============================== */}
            </Grid>
            <Grid
              item
              xs={12}
              md={4.5}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              sx={{ position: "relative" }}
            >
              {/* =========================== AuthenticatorScreen IMAGE BOX ============================== */}
              <Box
                sx={{
                  marginTop: { xs: "30px", md: "0" },
                  width: { xs: "150px", md: "250px", lg: "440px" },
                }}
              >
                {step === 1 && (
                  <img
                    style={{ width: "inherit" }}
                    src={authImg1}
                    alt="Login"
                  />
                )}
                {step === 2 && (
                  <img
                    style={{ width: "inherit" }}
                    src={authImg2}
                    alt="Authenticator"
                  />
                )}
                {/* {step === 3 && <img style={{ width: "inherit" }} src={authImg3} alt="Success" />} */}
              </Box>
              {/* =========================== AuthenticatorScreen IMAGE BOX ============================== */}
            </Grid>
          </Grid>
        </Box>
      </form>
    </>
  );
}
