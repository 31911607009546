// import { io } from "socket.io-client";
// import { BASE_URL } from "../constants";



// export let socket;

// export const socketInit = async (userId, course) => {
//     const user = JSON.parse(localStorage.getItem("user"));
//     socket = io(BASE_URL, {
//         auth: { userId: user._id, mode: "web" },
//     });
//     socket.onAny(async (event, ...args) => {
//         console.log('----------Message from socket-------------------', event, args, '----------------------------------');
//     });

//     socket.on('connect', () => {
//         console.log('-------------Connected to socket server------------');
//     });
//     socket.on('disconnect', () => {
//         console.log('------------Disconnected from socket server--------------');
//     });
//     socket.connect();
// }



import { io } from "socket.io-client";
import { BASE_URL } from "../constants";

export let socket;

export const socketInit = async (userId, course) => {
    const user = JSON.parse(localStorage.getItem("user"));
    socket = io(BASE_URL, {
        auth: { userId: user._id, mode: "web" },
    });

    socket.onAny((event, ...args) => {
        console.log('----------Message from socket-------------------', event, args, '----------------------------------');
    });

    socket.on('connect', () => {
        console.log('-------------Connected to socket server------------');
    });

    socket.on('disconnect', () => {
        console.log('------------Disconnected from socket server--------------');
    });
}
