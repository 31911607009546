import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  Backdrop,
  IconButton,
} from "@mui/material";
import { Close, CameraAlt, Delete, Send } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backdropFilter: "blur(2px)", // Adjust blur strength as needed
  },
  dialogPaper: {
    borderRadius: "0", // Set border radius to 0
    border: "1px solid #000",
  },
  textFieldRoot: {
    "& .MuiInputLabel-root": {
      color: "#8D8D8E !important", // Adding !important to ensure the color overrides
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(103, 60, 142, 1)", // Initial outlined border color
      },
      "&:hover fieldset": {
        borderColor: "rgba(103, 60, 142, 1)", // Change outlined border color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(103, 60, 142, 1)", // Change outlined border color when focused
      },
    },
  },
}));

const ChatCameraPopUp = ({ open, onClose, handleSendMessage }) => {
  const classes = useStyles();
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [cameraAllowed, setCameraAllowed] = useState(false);
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);

  useEffect(() => {
    if (open && cameraAllowed) {
      handleStartVideoStream();
    } else if (open && !cameraAllowed) {
      setShowPermissionModal(true);
    }
    // Cleanup the camera stream when the component unmounts or when the modal closes
    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
      }
    };
  }, [open, cameraAllowed]);

  const handleStartVideoStream = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
      videoRef.current.play();
    } catch (err) {
      console.error("Error accessing camera:", err);
      setShowPermissionModal(true);
    }
  };

  const handleCapturePhoto = () => {
    const context = canvasRef.current.getContext("2d");
    context.drawImage(
      videoRef.current,
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );
    const image = canvasRef.current.toDataURL("image/png");
    setCapturedImage(image);
  };

  const handleAllowAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setCameraAllowed(true);
      setShowPermissionModal(false);
      videoRef.current.srcObject = stream;
      videoRef.current.play();
    } catch (err) {
      console.error("Error accessing camera:", err);
      setShowPermissionModal(true); // Show permission modal if access fails
    }
  };

  const handleSendPhoto = () => {
    const base64Image = capturedImage.split(",")[1]; // Get the Base64 part
    handleSendMessage(base64Image); // Call the handleSendMessage function with the captured image
    setCapturedImage(null);
    onClose();
  };

  const handleDeletePhoto = () => {
    setCapturedImage(null);
    handleStartVideoStream(); // Restart the video stream
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={open} />
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogContent sx={{ padding: "20px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{ textAlign: "center", mb: 2, color: "#000" }}
            >
              Take a photo.
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {capturedImage ? (
              <img
                src={capturedImage}
                alt="Captured"
                style={{ width: "100%", height: "auto" }}
              />
            ) : (
              <video ref={videoRef} style={{ width: "100%", height: "auto" }} />
            )}
            <canvas
              ref={canvasRef}
              style={{ display: "none" }}
              width={640}
              height={480}
            />
          </Box>
          <Box sx={{ width: "100%", textAlign: "center", mt: 2 }}>
            {capturedImage ? (
              <>
                <IconButton
                  sx={{
                    background: "#683C8C",
                    color: "#fff",
                    "&:hover": { background: "#683C8C", color: "#fff" },
                    marginRight: "10px",
                  }}
                  onClick={handleSendPhoto}
                >
                  <Send />
                </IconButton>
                <IconButton
                  sx={{
                    background: "#FF4D4D",
                    color: "#fff",
                    "&:hover": { background: "#FF4D4D", color: "#fff" },
                  }}
                  onClick={handleDeletePhoto}
                >
                  <Delete />
                </IconButton>
              </>
            ) : (
              <IconButton
                sx={{
                  background: "#683C8C",
                  color: "#fff",
                  "&:hover": { background: "#683C8C", color: "#fff" },
                }}
                onClick={handleCapturePhoto}
              >
                <CameraAlt />
              </IconButton>
            )}
          </Box>
        </DialogContent>
      </Dialog>

      {/* Camera Permission Modal */}
      <Dialog
        open={showPermissionModal}
        onClose={() => setShowPermissionModal(false)}
        maxWidth="xs"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogContent sx={{ padding: "20px 50px" }}>
          <Typography variant="h6" sx={{ textAlign: "center", mb: 2 }}>
            We need your permission to access the camera.
          </Typography>
          <Box
            sx={{
              marginTop: "28px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              sx={{
                borderColor: "#683C8C",
                color: "#683C8C",
                padding: "6px 40px",
                fontSize: "15px",
                fontWeight: 400,
                textTransform: "capitalize",
                "&:hover": {
                  borderColor: "#683C8C",
                  backgroundColor: "transparent",
                  color: "#683C8C",
                },
                mr: 1,
              }}
              variant="outlined"
              onClick={() => setShowPermissionModal(false)}
            >
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: "#683C8C",
                color: "#FFFFFF",
                padding: "6px 40px",
                fontSize: "15px",
                fontWeight: 400,
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#683C8C",
                  color: "#FFFFFF",
                },
              }}
              variant="contained"
              onClick={handleAllowAccess}
            >
              Allow Access
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ChatCameraPopUp;
