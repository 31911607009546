import {
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { MoreHorizOutlined, NorthOutlined } from "@mui/icons-material";

const OrderPageCard = ({ title, count, rate }) => {
  return (
    <Grid item xs={12} md={4}>
      <Card>
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <Box>
            <Typography
              sx={{ color: "#683C8C", fontSize: "14px", fontWeight: 400 }}
            >
              {title}
            </Typography>
            <Typography sx={{ fontSize: "21px", fontWeight: 700 }}>
              {count}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                marginTop: "20px",
                gap: "5px",
              }}
            >
              <Chip
                size="small"
                sx={{
                  background: rate < 50 ? "#F50B0B" : "#7BD18E94",
                  color: "#fff",
                }}
                label={rate + "%"}
                icon={<NorthOutlined color="#fff" />}
              />
              <Typography
                variant="body2"
                sx={{ fontSize: "10px", color: "#263238" }}
              >
                Since Last Month
              </Typography>
            </Box>
          </Box>
          <Box>
            <IconButton aria-label="settings">
              <MoreHorizOutlined />
            </IconButton>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default OrderPageCard;
