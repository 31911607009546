import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const drawerWidth = 250;

export function Footer() {
  return (
    // <Box
    //   component="footer"
    //   sx={{
    //     padding: 2,
    //     textAlign: "center",
    //     backgroundColor: "#f1f1f1",
    //     marginTop: "auto", // Ensure footer stays at the bottom
    //   }}
    // >
    //   <Typography variant="body1">
    //     &copy; {new Date().getFullYear()} ShikshaTec. All rights reserved.
    //   </Typography>
    // </Box>
    <></>
  );
}
