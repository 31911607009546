import { Box, Button, Card, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { usePagination } from "../../hooks";
import DynamicTable from "../../components/DynamicTable";
import { statementFilters } from "../../utils/filterConfig";
import { $crud } from "../../utils/CrudFactory";
import { BASE_URL } from "../../constants";

const ClientStatements = () => {
  // Calculate default from and to dates
  const currentDate = new Date();
  const toDate = currentDate.toISOString().split("T")[0]; // Today
  const fromDate = new Date(currentDate.setDate(currentDate.getDate() - 30))
    .toISOString()
    .split("T")[0]; // 30 days ago
  // STATE TO MANAGE FILTERS --------------------------------------
  const [filtrationData, setFiltrationData] = useState({
    from: fromDate,
    to: toDate,
  });
  // STATE TO MANAGE FILTERS --------------------------------------

  const [totalAmount, setTotalAmount] = useState(0);
  const [average, setAverage] = useState(0);
  const [rows, setRows] = useState([]); //STATE TO GET THE ORDER LIST
  const { page, limit, setLimit, setPage, total, setTotal } = usePagination(); // PAGINATION PROPS

  // ========================= FUNCTION TO GET THE ORDER DATA FROM DATABASE(calling orders api) =============================
  async function retrieveData() {
    const { data } = await $crud.retrieve("admin/client-statements", {
      limit,
      page,
      from: filtrationData.from,
      to: filtrationData.to,
    });

    return data;
  }
  // ========================= FUNCTION TO GET THE ORDER DATA FROM DATABASE(calling orders api) =============================

  useEffect(() => {
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
    retrieveData().then((res) => {
      setRows(res.result);
      setTotal(res.total);
      const calculatedTotalAmount = res.result
        .map((item) => item.amount || 0) // Extract the amount values
        .reduce((sum, amount) => sum + amount, 0); // S

      setTotalAmount(calculatedTotalAmount);
    });
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
  }, [limit, page, filtrationData]);

  useEffect(() => {
    if (total > 0) {
      // Avoid division by zero
      setAverage(Math.round(totalAmount / total));
    } else {
      setAverage(0); // Handle case where total is 0
    }
  }, [totalAmount, total]); // This effect runs whenever totalAmount or total changes

  // COLUMNS IN THE ORDER TABLE-----------------------------
  const clientColumns = [
    {
      id: "date",
      label: "Date",
      align: "center",
    },
    {
      id: "customerID",
      label: "Customer ID",
      align: "center",
    },
    {
      id: "orderID",
      label: "Order ID",
      align: "center",
    },
    { id: "details", label: "Details", align: "center" },
    {
      id: "amount",
      label: "Amount",
      align: "center",
      renderCell: (row) => {
        return <Typography sx={{ fontSize: "12px" }}>${row.amount}</Typography>;
      },
    },
    {
      id: "invoice",
      label: "Invoice",
      align: "center",
      renderCell: (row) => {
        const invoiceUrl = `${BASE_URL}clientInvoice/${row.invoice}`;

        const handleDownload = async () => {
          try {
            const response = await fetch(invoiceUrl);
            if (!response.ok) throw new Error("Network response was not ok.");

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = row.invoice; // Use the filename for download
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
          } catch (error) {
            console.error("Download failed:", error);
          }
        };

        return (
          <Button
            onClick={handleDownload}
            sx={{
              background: "rgba(103, 60, 142)",
              color: "#fff",
              padding: "4px 14px",
              textTransform: "capitalize",
              border: "none",
              fontSize: "14px",
              width: "90px",
              outline: "none !important",
              borderRadius: "15px",
              "&:hover": {
                color: "#fff",
                background: "rgba(103, 60, 142)",
              },
              "&:focus": {
                color: "#fff",
                background: "rgba(103, 60, 142)",
              },
            }}
          >
            Download
          </Button>
        );
      },
    },
  ];
  // COLUMNS IN THE ORDER TABLE-----------------------------

  // FUNCTION TO SET THE FILTERS IN filtrationData STATE -----------------------------
  const getFilteredData = (data) => {
    setFiltrationData({
      ...filtrationData,
      ...data,
    });
  };
  // FUNCTION TO SET THE FILTERS IN filtrationData STATE -----------------------------

  return (
    <Grid container sx={{ paddingRight: "24px" }}>
      <Grid xs={12}>
        <Card
          sx={{
            padding: "20px 24px",
            background: "#fff",
            boxShadow:
              "0px 0px 2px 0px rgba(23, 26, 31, 0.12), 0px 0px 1px 0px rgba(23, 26, 31, 0.07)",
          }}
        >
          <Typography sx={{ fontSize: "12px", color: "#673C8E", mb: 1 }}>
            Last 30 day earnings calculated
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "start", gap: "24px" }}>
            <Card
              sx={{
                width: "133px",
                background: "#fff",
                boxShadow:
                  "0px 0px 2px 0px #673C8E, 0px 0px 1px 0px rgba(23, 26, 31, 0.07)",
                padding: "33px 44px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ fontSize: "12px", color: "#171A1F" }}>
                ${totalAmount}
              </Typography>
              <Typography sx={{ fontSize: "12px", color: "#9095A1" }}>
                Total
              </Typography>
            </Card>
            <Card
              sx={{
                width: "133px",
                background: "#fff",
                boxShadow:
                  "0px 0px 2px 0px #673C8E, 0px 0px 1px 0px rgba(23, 26, 31, 0.07)",
                padding: "33px 44px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ fontSize: "12px", color: "#171A1F" }}>
                {total}
              </Typography>
              <Typography sx={{ fontSize: "12px", color: "#9095A1" }}>
                Total Orders
              </Typography>
            </Card>
            <Card
              sx={{
                width: "133px",
                background: "#fff",
                boxShadow:
                  "0px 0px 2px 0px #673C8E, 0px 0px 1px 0px rgba(23, 26, 31, 0.07)",
                padding: "33px 44px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ fontSize: "12px", color: "#171A1F" }}>
                ${average}
              </Typography>
              <Typography sx={{ fontSize: "12px", color: "#9095A1" }}>
                Average Pay
              </Typography>
            </Card>
          </Box>
        </Card>
      </Grid>

      {/*========================= CALLING DYNAMIC TABLE COMPONENT TO SHOW THE CLIENTS LIST IN TABLE ===========================*/}
      <DynamicTable
        rows={rows}
        type="clientStatement"
        url="clientStatement"
        setRows={setRows}
        filtrationData={filtrationData}
        filterConfig={statementFilters} // Pass the filter checkbox options
        noRecordTitle="No Client Statements Yet !" // no data title
        onFilterData={getFilteredData} // filteration data
        onResetFilters={() => {
          setFiltrationData((prev) => ({
            from: fromDate,
            to: toDate,
          }));
        }}
        onSearchData={(value) => setFiltrationData({ ...filtrationData })}
        paginationProps={{ limit, page, setLimit, setPage, total }}
        columns={clientColumns} // Pass the columns prop
      />
      {/*========================= CALLING DYNAMIC TABLE COMPONENT TO SHOW THE ORDER LIST IN TABLE ===========================*/}
    </Grid>
  );
};

export default ClientStatements;
