import React, { useRef, useState, useEffect } from "react";
import { Typography, Box, IconButton } from "@mui/material";
import bold from "../assets/images/B.svg";
import italic from "../assets/images/I.svg";
import underline from "../assets/images/U.svg";
import bullets from "../assets/images/bullets.svg";

const TextEditor = ({ value, setFieldValue, type }) => {
  const editorRef = useRef(null);
  const [editorText, setEditorText] = useState(value || "");

  useEffect(() => {
    if (editorRef.current) {
      // Set the content without affecting the cursor position
      editorRef.current.innerHTML = value;
      setEditorText(value);
      moveCursorToEnd(editorRef.current);
    }
  }, [value]);

  const moveCursorToEnd = (element) => {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(element);
    range.collapse(false); // Move the cursor to the end
    selection.removeAllRanges();
    selection.addRange(range);
  };

  const formatText = (command) => {
    document.execCommand(command, false, null);
    // Update editorText after formatting
    setEditorText(editorRef.current.innerHTML);
    moveCursorToEnd(editorRef.current); // Ensure cursor stays at the end
  };

  const handleInput = () => {
    const editorContent = editorRef.current.innerHTML;
    setEditorText(editorContent);
    if (type === "regularOffer") {
      setFieldValue("termCondition", editorContent);
    } else {
      setFieldValue("offerDetails", editorContent);
    }
  };

  return (
    <Box>
      <Typography
        sx={{
          fontSize: "18px",
          marginBottom: "2px",
        }}
      >
        {type === "regularOffer" ? "Terms and Conditions" : "Offer Details"}
      </Typography>
      <Box
        sx={{
          border: "1px solid #ccc",
          padding: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            minHeight: "150px",
            padding: "10px",
            overflowY: "auto",
            textAlign: "left",
            direction: "ltr",
            marginBottom: "10px",
            border: "1px solid #ddd", // Border to visualize the preview area
            background: "#f9f9f9",
          }}
          dangerouslySetInnerHTML={{ __html: editorText }} // Display formatted text in preview
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginTop: "10px",
          }}
        >
          <Box
            contentEditable
            ref={editorRef}
            onInput={handleInput}
            suppressContentEditableWarning
            sx={{
              flexGrow: 1,
              border: "1px solid #ddd",
              borderRadius: "24px",
              padding: "10px",
              paddingRight: "100px", // Reserve space for buttons
              minHeight: "40px",
              overflowY: "auto",
              background: "rgba(104, 60, 140, 0.07)",
              textAlign: "left",
              direction: "ltr",
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <IconButton onClick={() => formatText("bold")}>
              <img src={bold} alt="Bold" />
            </IconButton>
            <IconButton onClick={() => formatText("underline")}>
              <img src={underline} alt="Underline" />
            </IconButton>
            <IconButton onClick={() => formatText("italic")}>
              <img src={italic} alt="Italic" />
            </IconButton>
            <IconButton onClick={() => formatText("insertUnorderedList")}>
              <img src={bullets} alt="Bullets" />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TextEditor;
