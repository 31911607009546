import React, { useState, useRef } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  Grid,
  Box,
  TextField,
  Button,
  Typography,
  FormHelperText,
} from "@mui/material";
import { API_URL } from "../../constants";
import { enqueueSnackbar } from "notistack";
import forgotPass from "../../assets/images/forgotPass.svg";
import verifyCode from "../../assets/images/verifyCode.svg";
import authImg3 from "../../assets/images/svg/authImg3.svg";
import errorImg from "../../assets/images/verifyErr.svg";
import logo from "../../assets/images/logo.svg";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { $crud } from "../../utils/CrudFactory";
import CustomTextField from "../../components/CustomTextField";

export function ForgotPassword() {
  const location = useLocation();
  const { changeAccPassword, adminId, adminEmail } = location.state;
  const [step, setStep] = useState(1);
  const [isMFACodeValid, setMFACodeValid] = useState(false);
  const [otpDigits, setOtpDigits] = useState(Array(6).fill(""));
  const refs = useRef({});
  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setStep(1);
  };
  const handleMFAChange = (index, value) => {
    // Update the entire otpDigits array
    const updatedOtpDigits = [...otpDigits];
    updatedOtpDigits[index] = value;
    setOtpDigits(updatedOtpDigits);

    // Move focus to the next input field if available
    if (value !== "" && index < otpDigits.length - 1) {
      refs.current[index + 1].focus();
    }

    // Check if this is the last digit
    if (index === 5) {
      setMFACodeValid(true); // Show the continue button
    }
  };

  const handleMoveNext = (index) => {
    if (index < otpDigits.length - 1 && refs.current[index + 1]) {
      refs.current[index + 1].focus();
    }
  };

  const handleMovePrev = (index) => {
    if (index > 0 && refs.current[index - 1]) {
      refs.current[index - 1].focus();
    }
    setMFACodeValid(false);
  };
  const [buttonVariant, setButtonVariant] = useState("outlined"); // Default variant is outlined
  const [mfaForm, setMfaForm] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [errorType, setErrType] = useState(null); // State to control image display
  const [userID, setUserID] = useState(null);
  const [verifyCodeForm, setVerifyCodeForm] = useState(changeAccPassword); // State to control form display
  const navigate = useNavigate();

  // INITIAL VALUES ------------------------
  const initialValues = {
    email: "",
    otp: "",
    mfaOTP: "",
  };
  // INITIAL VALUES ------------------------

  // FRONTEND VALIDATIONS -------------------
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Oops! Incorrect Email Format")
      .required("Required!"),
  });
  // FRONTEND VALIDATIONS -------------------

  const { values, handleChange, handleBlur, errors, touched, handleSubmit } =
    useFormik({
      initialValues,
      // onSubmit,
      validationSchema,
    });

  const [showPassword, setShowPassword] = React.useState(false); // State to control password

  // HIDE/SHOW PASSWORD FUNCTION-----------------------
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  // HIDE/SHOW PASSWORD FUNCTION-----------------------

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // VALIDATING EMAIL TO ACTIVATE THE BUTTON----------------
  const handleEmailChange = (e) => {
    const { value } = e.target;
    // Simple email validation check
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    setIsEmailValid(isValid);
  };

  // FUNCTION TO SEND PASSCODE TO USER EMAIL(calling send-code api) ------------------------------------------
  const handleSendCodeClick = async (isResend = false) => {
    $crud
      .post("admin/send-code", {
        email: values.email || adminEmail,
        resend: isResend, // send the resend key
      })
      .then((response) => {
        const { message, type, data } = response;
        setUserID(data.userId);
        if (type === "success") {
          setVerifyCodeForm(true);
        }
        // enqueueSnackbar(message, { variant: type });
      })
      .catch((error) => {
        // The error handling inside $crud.post will handle notification, so no need to add it here again
        console.error("Error sending code:", error);
        // enqueueSnackbar(error.message, { variant: "error" });
      });
  };
  // FUNCTION TO SEND PASSCODE TO USER EMAIL(calling send-code api) ------------------------------------------

  // FUNCTION TO VERIFY MFA-------------------------------------------------
  const handleMFAVerify = async () => {
    const otpCode = otpDigits.join("");
    $crud
      .post("admin/verifySecretKey", {
        otpCode,
        adminId: userID || adminId,
        isQRScanned: true,
      })
      .then((response) => {
        const { message, type, data } = response;
        if (type == "error") {
          setErrType("error");
        }
        if (type === "success") {
          navigate("/reset-password", {
            state: { adminId: userID || adminId },
          });
          // setVerifyCodeForm(true);
        }
        // enqueueSnackbar(message, { variant: type });
      })
      .catch((error) => {
        // The error handling inside $crud.post will handle notification, so no need to add it here again
        console.error("Error sending code:", error);
        setErrType("error");
        // enqueueSnackbar(error.message, { variant: "error" });
      });
  };
  // FUNCTION TO VERIFY MFA-------------------------------------------------

  // FUNCTION TO VERIFY THE PASSCODE(calling verify-email api)---------------------------------
  const handleVerifyEmail = async () => {
    setVerifyCodeForm(true);
    try {
      const response = await axios.post(`${API_URL}create/admin/verify-email`, {
        adminId: userID || adminId,
        otp: values.otp,
      });

      const { message, type, data } = response.data;
      enqueueSnackbar(message, { variant: type });
      if (type == "error") {
        setErrType("error");
      }
      if (type == "success") {
        setMfaForm(true);
        // setVerifyCodeForm(false);
      }
    } catch (error) {
      console.error("Error verifying email:", error);
      enqueueSnackbar("Verification Failed. Please try again.", {
        variant: "error",
      });
    }
  };
  // FUNCTION TO VERIFY THE PASSCODE(calling verify-email api)---------------------------------

  // const isMFACodeValid = values.mfaOTP.length >= 6;
  // const isOTPCodeValid = values.otp.length >= 6;
  const isOTPCodeValid = String(values.otp).length >= 6;
  return (
    <>
      <form>
        <Box
          sx={{
            height: "100vh",
            padding: "0",
            width: "100vw",
            display: "flex",
            boxSizing: "border-box",
          }}
        >
          <Box sx={{ position: "absolute", right: "20px", top: "10px" }}>
            <img style={{ width: "120px" }} src={logo} alt="Logo" />
          </Box>
          <Grid container>
            <Grid
              item
              xs={12}
              md={7.5}
              sx={{
                background: (theme) => theme.palette.background.paper,
                boxShadow: "0px 4px 10px 0px #673C8E",
                borderRight: "1px solid #673C8E",
                display: "grid",
                placeItems: "center",
              }}
            >
              <Box
                sx={{
                  maxWidth: {
                    xs: "270px",
                    md: "300px",
                    lg: "512px",
                  },
                  marginTop: {
                    xs: "40px",
                    md: "0",
                  },
                }}
              >
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/login")}
                    >
                      <ArrowBackIosIcon fontSize="18" />
                      <Typography variant="body2">Back to Login</Typography>
                    </Box>
                  </Grid>
                  {!verifyCodeForm ? (
                    <>
                      {/* ===================================== FORGOT PASSWORD BOX ================================= */}
                      <Grid item container>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              // color: (theme) => theme.palette.text.secondary,
                              color: "#313131",
                              fontWeight: 600,
                              fontSize: "40px",
                            }}
                          >
                            Forgot your password?
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              color: "#313131",
                              marginTop: "16px",
                              marginBottom: "16px",
                            }}
                          >
                            Don’t worry, happens to all of us. Enter your email
                            below to recover your password
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ mb: 4 }}>
                          <TextField
                            name="email"
                            placeholder="Enter Your Email"
                            sx={{
                              color: "#1C1B1F",
                              "& .MuiInputLabel-root": {
                                color: "#1C1B1F !important",
                              },
                              "& .MuiOutlinedInput-root": {
                                "&:hover fieldset": {
                                  borderColor: (theme) =>
                                    theme.palette.text.secondary,
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#1C1B1F",
                                },
                              },
                            }}
                            size="small"
                            label="Email"
                            id="email"
                            fullWidth
                            value={values.email}
                            // onChange={handleChange}
                            onChange={(e) => {
                              handleChange(e);
                              handleEmailChange(e);
                            }}
                            onBlur={handleBlur}
                            variant="outlined"
                            error={touched.email && errors.email}
                            helperText={touched.email && errors.email}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            variant={isEmailValid ? "contained" : "outlined"}
                            fullWidth
                            size="small"
                            onClick={() => handleSendCodeClick(false)}
                            sx={{
                              backgroundColor: isEmailValid
                                ? "#683C8C"
                                : "transparent",
                              color: isEmailValid ? "#FFFFFF" : "#683C8C",
                              borderColor: "#683C8C",
                              padding: "10px 8px",
                              "&:hover": {
                                backgroundColor: isEmailValid
                                  ? "#683C8C"
                                  : "transparent",
                                color: isEmailValid ? "#FFFFFF" : "#683C8C",
                                borderColor: "#683C8C",
                              },
                            }}
                            // disabled={!isEmailValid}
                          >
                            Send Code
                          </Button>
                        </Grid>
                      </Grid>
                      {/* ===================================== FORGOT PASSWORD BOX ================================= */}
                    </>
                  ) : !mfaForm ? (
                    <>
                      {/* ================================== VERIFY CODE BOX ==================================== */}
                      <Grid item xs={12}>
                        <Typography
                          variant="h4"
                          sx={{
                            // color: (theme) => theme.palette.text.secondary,
                            color: "#313131",
                            fontWeight: 600,
                          }}
                        >
                          Verify Code
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color: "#313131",
                            marginTop: "16px",
                            marginBottom: "16px",
                          }}
                        >
                          An authentication code has been sent to your email.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl sx={{ width: "100%" }} variant="outlined">
                          {/* <InputLabel
                            sx={{
                              color: "#1C1B1F !important", // Change label text color
                            }}
                            size="small"
                            htmlFor="outlined-adornment-password"
                          >
                            Enter Code
                          </InputLabel> */}
                          <OutlinedInput
                            placeholder="Enter Code"
                            sx={{
                              color: "#1C1B1F",
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: (theme) =>
                                  theme.palette.text.secondary, // Change outlined border color
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: (theme) =>
                                  theme.palette.text.secondary, // Change outlined border color on hover
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#1C1B1F", // Change outlined border color when focused
                                },
                              "& .MuiOutlinedInput-notchedOutline legend": {
                                display: "none", // Remove the notch
                              },
                              // Hide the arrows (spinners)
                              "& input[type=number]": {
                                MozAppearance: "textfield",
                                "&::-webkit-outer-spin-button": {
                                  WebkitAppearance: "none",
                                  margin: 0,
                                },
                                "&::-webkit-inner-spin-button": {
                                  WebkitAppearance: "none",
                                  margin: 0,
                                },
                              },
                            }}
                            size="small"
                            id="otp"
                            value={values.otp}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="number"
                            className={!showPassword ? "hidden-otp" : ""}
                            // label="Enter code"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            error={touched.otp && !!errors.otp}
                          />
                          {touched.otp && (
                            <FormHelperText error>{errors.otp}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sx={{ display: "flex" }}>
                        <Typography
                          onClick={() => handleSendCodeClick(true)}
                          sx={{ cursor: "pointer", display: "flex", gap: 1 }}
                          variant="body2"
                        >
                          Didn’t receive a code?{" "}
                          <Typography variant="body2" color="error">
                            Resend
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          // variant="contained"
                          variant={isOTPCodeValid ? "contained" : buttonVariant}
                          fullWidth
                          size="small"
                          onClick={handleVerifyEmail}
                          sx={{
                            backgroundColor: isOTPCodeValid
                              ? "#683C8C"
                              : "transparent",
                            color: isOTPCodeValid ? "#FFFFFF" : "#683C8C",
                            padding: "10px 8px",
                            borderColor: "#683C8C",
                            "&:hover": {
                              backgroundColor: isOTPCodeValid
                                ? "#683C8C"
                                : "transparent",
                              color: isOTPCodeValid ? "#FFFFFF" : "#683C8C",
                              borderColor: "#683C8C",
                            },
                          }}
                        >
                          Verify And Continue To MFA
                        </Button>
                      </Grid>
                      {/* ================================== VERIFY CODE BOX ==================================== */}
                    </>
                  ) : (
                    <>
                      {/* ======================================== MFA VERIFY BOX ================================== */}
                      <Grid item xs={12}>
                        <Typography
                          variant="h4"
                          sx={{
                            // color: (theme) => theme.palette.text.secondary,
                            color: "#313131",
                            fontWeight: 600,
                          }}
                        >
                          Verify MFA
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color: "#313131",
                            marginTop: "16px",
                            marginBottom: "16px",
                          }}
                        >
                          Enter the code from your authenticator App.
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        {otpDigits.map((digit, index) => (
                          <CustomTextField
                            key={index}
                            value={digit}
                            onChange={(e) =>
                              handleMFAChange(index, e.target.value)
                            }
                            onLastKey={index === 5 ? () => {} : null} // No-op for now
                            onMoveNext={() => handleMoveNext(index)}
                            onMovePrev={() => handleMovePrev(index)}
                            ref={(el) => (refs.current[index] = el)}
                          />
                        ))}
                      </Grid>
                      {/* <Grid item xs={12}>
                        <FormControl sx={{ width: "100%" }} variant="outlined">
                          <InputLabel
                            sx={{
                              color: "#1C1B1F !important", // Change label text color
                            }}
                            size="small"
                            htmlFor="outlined-adornment-password"
                          >
                            Enter Code
                          </InputLabel>
                          <OutlinedInput
                            placeholder="Enter Code"
                            sx={{
                              color: "#1C1B1F",
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: (theme) =>
                                  theme.palette.text.secondary, // Change outlined border color
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: (theme) =>
                                  theme.palette.text.secondary, // Change outlined border color on hover
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#1C1B1F", // Change outlined border color when focused
                                },
                            }}
                            size="small"
                            id="mfaOTP"
                            value={values.mfaOTP}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type={showPassword ? "integer" : "password"}
                            label="Enter code"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            error={touched.mfaOTP && !!errors.mfaOTP}
                          />
                          {touched.mfaOTP && (
                            <FormHelperText error>
                              {errors.mfaOTP}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid> */}
                      <Grid item xs={12}>
                        <Button
                          // variant="contained"
                          variant={isMFACodeValid ? "contained" : buttonVariant}
                          fullWidth
                          size="small"
                          onClick={handleMFAVerify}
                          sx={{
                            backgroundColor: isMFACodeValid
                              ? "#683C8C"
                              : "transparent",
                            color: isMFACodeValid ? "#FFFFFF" : "#683C8C",
                            padding: "10px 8px",
                            borderColor: "#683C8C",
                            "&:hover": {
                              backgroundColor: isMFACodeValid
                                ? "#683C8C"
                                : "transparent",
                              color: isMFACodeValid ? "#FFFFFF" : "#683C8C",
                              borderColor: "#683C8C",
                            },
                          }}
                        >
                          Verify MFA
                        </Button>
                      </Grid>

                      {/* ======================================== MFA VERIFY BOX ================================== */}
                    </>
                  )}
                </Grid>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4.5}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              sx={{ position: "relative" }}
            >
              {errorType === "error" ? (
                // =========================== DISPLAY ERROR IMAGE ===========================
                <Box
                  sx={{
                    marginTop: { xs: "30px", md: "0" },
                    width: { xs: "150px", md: "250px", lg: "400px" },
                  }}
                >
                  <img
                    style={{ width: "inherit" }}
                    src={errorImg}
                    alt="Login Error"
                  />
                </Box>
              ) : !verifyCodeForm ? (
                // =============================== DISPLAY FORGOT PASSWORD IMAGE ================================
                <Box
                  sx={{
                    marginTop: { xs: "30px", md: "0" },
                    width: { xs: "150px", md: "250px", lg: "400px" },
                  }}
                >
                  <img
                    style={{ width: "inherit" }}
                    src={forgotPass}
                    alt="Forgot Password"
                  />
                </Box>
              ) : mfaForm ? (
                // =============================== DISPLAY MFA IMAGE ===================================
                <Box
                  sx={{
                    marginTop: { xs: "30px", md: "0" },
                    width: { xs: "150px", md: "250px", lg: "500px" },
                  }}
                >
                  <img
                    style={{ width: "inherit" }}
                    src={authImg3}
                    alt="MFA Image"
                  />
                </Box>
              ) : (
                // =============================== DISPLAY VERIFY CODE IMAGE ===================================
                <Box
                  sx={{
                    marginTop: { xs: "30px", md: "0" },
                    width: { xs: "150px", md: "250px", lg: "400px" },
                  }}
                >
                  <img
                    style={{ width: "inherit" }}
                    src={verifyCode}
                    alt="Verify Code"
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </form>
    </>
  );
}
