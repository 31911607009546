import {
  Grid,
  Box,
  Typography,
  IconButton,
  Button,
  Avatar,
  StepConnector,
  Stepper,
  Step,
  StepLabel,
  Card,
  Paper,
} from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import {
  AirportShuttleOutlined,
  PhoneOutlined,
  KeyboardArrowDown,
} from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import back from "../../assets/images/svg/back.svg";
import star from "../../assets/images/svg/star.svg";
import starOutline from "../../assets/images/svg/starOutline.svg";
import truck from "../../assets/images/svg/truck.svg";
import logo from "../../assets/images/logo.png";
import reload from "../../assets/images/svg/reload.svg";
import add from "../../assets/images/svg/add.svg";
import info from "../../assets/images/svg/info.svg";
import driver from "../../assets/images/svg/driver.svg";
import reviewRed from "../../assets/images/svg/reviewRed.svg";
import reject from "../../assets/images/svg/reject.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { ImagePreview, ImageUploader } from "../../components";
import MapComponent from "../../components/maps/MapComponent";
import { $crud } from "../../utils/CrudFactory";

// CUSTOM STEPPER FOR ORDER DETAIL ------------------------------
const CustomStepConnector = () => (
  <StepConnector
    sx={{
      "& .MuiStepConnector-line": {
        display: "block",
        borderColor: "#683C8C",
        borderLeftStyle: "dashed",
        borderLeftWidth: "1px",
        minHeight: "24px",
      },
    }}
  />
);
// CUSTOM STEPPER FOR ORDER DETAIL ------------------------------

// CUSTOM TOOLTIP ====================================
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: 140, // Custom maxWidth for Tooltip
    minHeight: 56, // Custom maxWidth for Tooltip
    background: "#795898", // Additional styles if needed
    color: "#fff", // Additional styles if needed
    fontSize: "8px", // Additional styles if needed
    padding: "8px", // Additional styles if needed
    position: "absolute",
    top: "-38px",
    left: "-90px",
    borderRadius: "0",
  },
});

// STEPS FOR THE STATUS OF ORDER -------------------------------
const steps = [
  {
    label: "Working on Pickup 2",
    description: `On The Way To Pickup 2`,
    icon: <img src={truck} />,
    tooltip: false,
  },
  {
    label: "Pickup 1 complete",
    description: "Sat,23 Jul 2020, 01:24 PM - 1hr 20m",
    icon: <img src={truck} />,
    tooltip: false,
  },
];
// STEPS FOR THE STATUS OF ORDER -------------------------------
export function PickUpDetails() {
  const [mergedSteps, setMergedSteps] = useState([]);
  const [client, setClient] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const [pickup, setPickup] = useState(location.state?.pickup || {});
  const [order, setOrder] = useState(location.state?.selectedOrder || {});
  console.log(pickup, "---------------------------");
  async function retrieveClientDetails() {
    const { data } = await $crud.retrieve("admin/client", {
      customerID: order.customerId,
    });
    return data;
  }

  useEffect(() => {
    retrieveClientDetails().then((res) => {
      setClient(res);
    });
  }, [order]);
  useEffect(() => {
    // Combine order.positionHistory with existing steps
    const merged = [...steps];

    if (order && order.positionHistory) {
      order.positionHistory.forEach((historyItem, index) => {
        let label = "";
        let icon = "";
        let desc = "";
        if (historyItem.status == "No Holds") {
          label = "Order Reinstated";
          desc = "Fri,22 Jul 2020,10:44 AM - 35m";
          icon = <img src={reload} />;
        }
        if (historyItem.status == "Reject") {
          label = "Order Rejected";
          desc = "Fri,22 Jul 2020,10:44 AM - 35m";
          icon = <img src={reject} />;
        }
        if (historyItem.status == "Review") {
          label = "Order kept on Review";
          desc = "Fri,22 Jul 2020,10:44 AM - 35m";
          icon = <img src={reviewRed} />;
        }
        merged.push({
          label,
          description: desc,
          icon,
          reason: historyItem.reason,
          tooltip: true,
        });
      });
    }

    merged.push(
      {
        label: "Driver Matched",
        description: `Fri, 22 Jul 2020, 10:44 AM - 35m`,
        icon: <img src={driver} width="24px" />,
        tooltip: false,
      },
      {
        label: "Order Booked",
        description: `Fri, 22 Jul 2020, 10:44 AM - 35m`,
        icon: <img src={add} />,
        tooltip: false,
      }
    );

    setMergedSteps(merged);
  }, [order, steps]);

  const handleBackClick = () => {
    navigate(-1);
  };

  console.log(pickup);

  const calculateWaitingTime = (dateBooked) => {
    const bookedDate = new Date(dateBooked);
    const now = new Date();
    const diffMs = now - bookedDate; // Difference in milliseconds
    const diffHrs = Math.floor(diffMs / (1000 * 60 * 60)); // Convert milliseconds to hours
    const diffMins = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60)); // Remaining minutes

    return `${diffHrs} Hr ${diffMins} Min`;
  };

  const waitingTime = calculateWaitingTime(order.dateBooked);
  return (
    <Grid container sx={{ padding: "0 24px" }} spacing={1}>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "flex-start",
            gap: 1,
          }}
        >
          <IconButton onClick={handleBackClick} sx={{ marginLeft: "-10px" }}>
            <img src={back} />
          </IconButton>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: 6,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 700,
                  fontSize: "30px",
                  color: "#673C8E", // Return the value directly
                }}
              >
                Pickup ID {order.orderId}-{pickup.puDoId}
              </Typography>
              <img src={order.specialOrder ? star : starOutline} />
            </Box>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
                fontSize: "20px",
                color: "#673C8E", // Return the value directly
              }}
            >
              Order ID {order.orderId}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} sx={{ textAlign: "right" }}>
        {/* <Box > */}
        <Typography
          variant="h5"
          sx={{
            fontSize: "13px",
            color: "#673C8E", // Return the value directly
          }}
        >
          Booked : {order.dateBooked}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontSize: "11px",
            color: "#673C8E", // Return the value directly
            marginTop: "4px",
          }}
        >
          Booked Near : {order.bLocation}
        </Typography>
        {/* </Box> */}
      </Grid>

      <Grid item xs={12} md={3} mt={2}>
        {/* =========================== CLIENT DETAILS BOX ================================ */}
        <Box
          sx={{
            border: "1px solid #683C8C",
            position: "relative",
            marginTop: "14px",
          }}
        >
          <Typography
            sx={{
              fontSize: "10px",
              color: "#A4A4A4",
              position: "absolute",
              top: "4px",
              left: "10px",
            }}
          >
            Client
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "8px 0",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Avatar
              alt={client?.name}
              src="/static/images/avatar/1.jpg"
              sx={{
                width: 58,
                height: 58,
                color: "#fff",
                background: "rgba(104, 60, 140, 0.35)",
              }}
            />
            <Typography sx={{ fontWeight: 600 }}>
              {client?.customerID}
            </Typography>
            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: "#683C8C",
                color: "#FFFFFF",
                borderColor: "#683C8C",
                padding: "2px 60px",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#683C8C",
                  color: "#FFFFFF",
                  borderColor: "#683C8C",
                },
              }}
            >
              {client?.name}
            </Button>
          </Box>
        </Box>
        {/* =========================== CLIENT DETAILS BOX ================================ */}

        {/* =======================================  ORDER HISTORY============================================= */}
        <Box
          sx={{
            marginTop: "15px",
            border: "1px solid #683C8C",
            position: "relative",
            padding: "20px",
            marginBottom: "20px",
            height: "600px",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <Typography
            sx={{
              color: "rgba(104, 60, 140, 1)",
              fontWeight: 600,
              fontSize: "20px",
            }}
          >
            History
          </Typography>
          {/* <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "80px",
              paddingBottom: "80px",
            }}
          >
            <Typography sx={{ fontSize: "20px", color: "#683C8C" }}>
              No Order History !
            </Typography>
          </Box> */}
          {/* =================================== ORDER HISTORY BOX ==================================== */}

          <Box sx={{ maxWidth: 400 }}>
            <Stepper
              activeStep={3}
              orientation="vertical"
              connector={<CustomStepConnector />}
            >
              {mergedSteps.map((step, index) =>
                step.tooltip ? (
                  <CustomTooltip
                    key={index}
                    title={step.reason || "No reason provided"}
                    sx={{
                      "& .MuiTooltip-tooltip": {
                        background: "#795898",
                        color: "#fff",
                        fontSize: "8px",
                        padding: "8px",
                      },
                      "& .MuiStep-root:hover .MuiTooltip-tooltip": {
                        background: "red",
                      },
                    }}
                  >
                    <Step>
                      <StepLabel icon={step.icon}>
                        <Typography
                          sx={{
                            color: "rgba(104, 60, 140, 1)",
                            fontSize: "13px",
                            fontWeight: 400,
                          }}
                        >
                          {step.label}
                        </Typography>
                        <Typography sx={{ color: "black", fontSize: "10px" }}>
                          {step.description}
                        </Typography>
                      </StepLabel>
                    </Step>
                  </CustomTooltip>
                ) : (
                  <Step>
                    <StepLabel icon={step.icon}>
                      <Typography
                        sx={{
                          color: "rgba(104, 60, 140, 1)",
                          fontSize: "13px",
                          fontWeight: 400,
                        }}
                      >
                        {step.label}
                      </Typography>
                      <Typography sx={{ color: "black", fontSize: "10px" }}>
                        {step.description}
                      </Typography>
                    </StepLabel>
                  </Step>
                )
              )}
            </Stepper>
          </Box>
        </Box>
        {/* =======================================  ORDER HISTORY============================================= */}
      </Grid>

      <Grid item xs={12} md={9}>
        <Box
          sx={{
            marginTop: "-8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <Box
            sx={{
              paddingLeft: "14px",
              display: "flex",
              gap: 3,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: "13px",
                color: "#673C8E", // Return the value directly
              }}
            >
              Total Pickups - {order.tpu}
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontSize: "13px",
                color: "#673C8E", // Return the value directly
              }}
            >
              Client Waiting - {waitingTime}
            </Typography>
          </Box>
          <Box sx={{}}>
            {/* <Button
              variant="contained"
              size="small"
              sx={{
                padding: "7px 58px",
                textTransform: "capitalize",
                backgroundColor: "rgba(15, 169, 88, 0.35)",
                color: "rgba(104, 60, 140, 1)",
                borderColor: "#683C8C",
                "&:hover": {
                  backgroundColor: "rgba(15, 169, 88, 0.35)",
                  color: "rgba(104, 60, 140, 1)",
                  borderColor: "#683C8C",
                },
              }}
            >
              Picked
            </Button> */}
            <Button
              variant="contained"
              size="small"
              sx={{
                padding: "7px 38px",
                textTransform: "capitalize",
                backgroundColor: "rgba(235, 8, 0, 0.35)",
                color: "#000",
                borderColor: "#683C8C",
                "&:hover": {
                  backgroundColor: "rgba(235, 8, 0, 0.35)",
                  color: "#000",
                  borderColor: "#683C8C",
                },
              }}
            >
              Not Yet Picked
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "219px",
            paddingLeft: "14px",
            marginTop: "15px",
            gap: "10px",
          }}
        >
          <Card
            sx={{
              width: "55%",
              height: "inherit",
              borderRadius: "0",
              // overflowY: "scroll",
            }}
          >
            <Box
              sx={{ background: "rgba(104, 60, 140, 1)", padding: "10px 24px" }}
            >
              <Typography
                sx={{ color: "#fff", fontSize: "24px", fontWeight: 700 }}
              >
                Pickup & POP
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ padding: "10px 20px", display: "flex", gap: 6 }}>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "rgba(23, 26, 31, 1)",
                      marginBottom: "15px",
                    }}
                  >
                    Pickup Id
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "rgba(23, 26, 31, 1)",
                      marginBottom: "6px",
                    }}
                  >
                    Paid By
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "rgba(23, 26, 31, 1)",
                      marginBottom: "15px",
                    }}
                  >
                    {pickup.puDoId}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "rgba(23, 26, 31, 1)",
                      marginBottom: "6px",
                    }}
                  >
                    {pickup.paidBy}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ padding: "10px 20px" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 700,
                    color: "rgba(23, 26, 31, 1)",
                    mb: 1,
                  }}
                >
                  Attachment
                </Typography>

                <Box sx={{ display: "flex", gap: 2 }}>
                  {pickup.image && (
                    <ImagePreview imgSrc={pickup.image} htmlFor="attachment" />
                  )}
                </Box>
              </Box>
            </Box>
          </Card>
          <Card sx={{ width: "45%", height: "inherit", borderRadius: "0" }}>
            <Box
              sx={{ background: "rgba(104, 60, 140, 1)", padding: "10px 24px" }}
            >
              <Typography
                sx={{ color: "#fff", fontSize: "24px", fontWeight: 700 }}
              >
                Item & $$$
              </Typography>
            </Box>
            <Box sx={{ padding: "10px 16px", display: "flex", gap: 6 }}>
              <Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 700,
                    color: "rgba(23, 26, 31, 1)",
                    marginBottom: "10px",
                  }}
                >
                  Item Name
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 700,
                    color: "rgba(23, 26, 31, 1)",
                    marginBottom: "10px",
                  }}
                >
                  Item Category{" "}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 700,
                    color: "rgba(23, 26, 31, 1)",
                    marginBottom: "10px",
                  }}
                >
                  Entered Value
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 700,
                    color: "rgba(23, 26, 31, 1)",
                  }}
                >
                  Weight
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "rgba(23, 26, 31, 1)",
                    marginBottom: "10px",
                  }}
                >
                  {pickup.itemName}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "rgba(23, 26, 31, 1)",
                    marginBottom: "10px",
                  }}
                >
                  {pickup.category}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "rgba(23, 26, 31, 1)",
                    marginBottom: "10px",
                  }}
                >
                  ${pickup.amount}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "rgba(23, 26, 31, 1)",
                    marginBottom: "10px",
                  }}
                >
                  {pickup.weight}
                </Typography>
              </Box>
            </Box>
          </Card>
        </Box>

        {/* ==================================  ORDER TRACKING BOX======================================== */}
        <Card
          sx={{
            marginLeft: "14px",
            padding: "8px 16px",
            marginBottom: "20px",
            marginTop: "25px",
            height: "465px",
            position: "relative",
            borderRadius: "0",
          }}
        >
          <Typography
            sx={{
              color: "rgba(104, 60, 140, 1)",
              fontSize: "24px",
              fontWeight: 600,
              mb: 1,
            }}
          >
            Pickup Details
          </Typography>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "start",
              alignItems: "flex-start",
              gap: 2,
              height: "180px",
            }}
          >
            <Box
              sx={{
                width: "50%",
                height: "inherit",
              }}
            >
              <MapComponent />
            </Box>
            <Box
              sx={{
                width: "50%",
                height: "inherit",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",

                  alignItems: "center",
                  gap: 1,
                  marginBottom: "29px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "rgba(104, 60, 140, 1)",
                  }}
                >
                  Pickup Address
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "rgba(104, 60, 140, 1)",
                    ml: 2,
                  }}
                >
                  :
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 400,
                    color: "rgba(23, 26, 31, 1)",
                  }}
                >
                  {pickup.address}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  marginBottom: "29px",
                  gap: 1,
                }}
              >
                {/* <Box> */}
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "rgba(104, 60, 140, 1)",
                  }}
                >
                  Landmark / <br />
                  Intersection
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "rgba(104, 60, 140, 1)",
                    ml: 4.5,
                  }}
                >
                  :
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 400,
                    color: "rgba(23, 26, 31, 1)",
                  }}
                >
                  {pickup?.landmark}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  marginBottom: "29px",
                  gap: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "rgba(104, 60, 140, 1)",
                  }}
                >
                  Store Name
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "rgba(104, 60, 140, 1)",
                    ml: 4.6,
                  }}
                >
                  :
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 400,
                    color: "rgba(23, 26, 31, 1)",
                  }}
                >
                  {pickup?.store}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              background: "rgba(104, 60, 140, 0.35)",
              padding: "6px",
              height: "80px",
              marginTop: "12px",
            }}
          >
            <Typography
              sx={{ color: "#673C8E", fontSize: "17px", fontWeight: 600 }}
            >
              Pickup Notes
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "9px",
                fontWeight: 400,
                marginTop: "15px",
              }}
            >
              {pickup.instructions}
            </Typography>
          </Box>

          {/* ----------------- DELIVERY BY ------------------- */}
          <Box
            sx={{
              position: "absolute",
              bottom: "20px",
              width: "96%",
              right: "16px",
              left: "16px",
            }}
          >
            <Typography
              sx={{
                color: "rgba(104, 60, 140, 1)",
                fontSize: "16px",
                fontWeight: 600,
              }}
            >
              Pickup Contact / Department
            </Typography>
            <Box
              sx={{
                marginTop: "20px",
                display: "flex",
                gap: 3,
                justifyContent: "space-between",
              }}
            >
              <Card
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: 2,
                  padding: "13px",
                  width: "205px",
                }}
              >
                <Avatar alt="R" variant="square" />
                <Box>
                  <Typography variant="body2" sx={{ fontWeight: 600 }}>
                    Janet Doe
                  </Typography>
                </Box>
              </Card>
              {/* <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}> */}
              <Card
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  gap: 2,
                  padding: "13px",
                  width: "205px",
                }}
              >
                <IconButton
                  sx={{
                    borderRadius: "4px",
                    border: "1px solid rgba(104, 60, 140, 1)",
                    background: "rgba(104, 60, 140, 0.35)",
                    color: "rgba(104, 60, 140, 1)",
                  }}
                >
                  <PhoneOutlined />
                </IconButton>
                <Box>
                  <Typography sx={{ fontSize: "10px" }}>Telephone</Typography>
                  <Typography sx={{ fontSize: "10px", mt: 1 }}>
                    {pickup.phone}
                  </Typography>
                </Box>
              </Card>
              <Card
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  gap: 2,
                  padding: "13px",
                  width: "205px",
                }}
              >
                <IconButton
                  sx={{
                    borderRadius: "4px",
                    border: "1px solid rgba(104, 60, 140, 1)",
                    background: "rgba(104, 60, 140, 0.35)",
                    color: "rgba(104, 60, 140, 1)",
                  }}
                >
                  <img src={truck} />
                </IconButton>
                <Box>
                  <Typography sx={{ fontSize: "10px" }}>
                    Expected Pickup Time
                  </Typography>
                  <Typography sx={{ fontSize: "10px", mt: 1 }}>
                    23/5/24 - 12:00 AM
                  </Typography>
                </Box>
              </Card>
              {/* </Box> */}
            </Box>
          </Box>
        </Card>
        {/* ==================================  ORDER TRACKING BOX======================================== */}
      </Grid>
    </Grid>
  );
}
