import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  Backdrop,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { enqueueSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backdropFilter: "blur(2px)", // Adjust blur strength as needed
  },
  dialogPaper: {
    borderRadius: "0", // Set border radius to 0
    border: "1px solid #000",
  },
  textFieldRoot: {
    "& .MuiInputLabel-root": {
      color: "#8D8D8E !important", // Adding !important to ensure the color overrides
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(103, 60, 142, 1)", // Initial outlined border color
      },
      "&:hover fieldset": {
        borderColor: "rgba(103, 60, 142, 1)", // Change outlined border color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(103, 60, 142, 1)", // Change outlined border color when focused
      },
    },
  },
}));

const ClientReviewModel = ({ onClose, open, onSubmit }) => {
  const [isCheck, setIsCheck] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsCheck(e.target.checked);
  };

  const classes = useStyles();
  const handleSubmit = () => {
    if (!isCheck) {
      enqueueSnackbar("Please check the box to confirm.", {
        variant: "error",
      });
      return;
    }
    onSubmit();
  };
  return (
    <>
      <Backdrop className={classes.backdrop} open={open} />
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="xs"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogContent sx={{ marginTop: "40px", padding: "0 30px" }}>
          <Box sx={{ textAlign: "center" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isCheck}
                  onChange={handleCheckboxChange}
                  sx={{
                    color: "#683C8C",
                    "&.Mui-checked": {
                      color: "#683C8C",
                    },
                  }}
                />
              }
              sx={{
                "& .MuiFormControlLabel-label": {
                  color: "#683C8C",
                  fontSize: "18px",
                },
              }}
              label="Yes, Set this Review as the best Review."
            />
          </Box>
          <Box
            sx={{
              marginTop: "30px",
              marginBottom: "38px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              sx={{
                borderColor: "#683C8C",
                color: "#683C8C",
                padding: "6px 40px",
                fontSize: "15px",
                fontWeight: 400,
                textTransform: "capitalize",
                "&:hover": {
                  borderColor: "#683C8C",
                  backgroundColor: "transparent",
                  color: "#683C8C",
                },
                mr: 1,
              }}
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              sx={{
                background: "#683C8C",
                color: "#fff",
                padding: "6px 40px",
                fontSize: "15px",
                fontWeight: 400,
                textTransform: "capitalize",
                "&:hover": {
                  background: "#683C8C",
                  color: "#fff",
                },
                mr: 1,
              }}
              variant="contained"
              onClick={handleSubmit}
            >
              Set Review
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ClientReviewModel;
