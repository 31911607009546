import React from "react";
import { FormControlLabel, styled, Switch } from "@mui/material";
import { useThemeContext } from "./ThemeContextProvider";

export const ThemeSwitcher = () => {
  const { mode, toggleColorMode } = useThemeContext();
  return (
    <IOSSwitch
      //   defaultChecked
      checked={mode == "dark"}
      onChange={toggleColorMode}
    />
  );
};

const IOSSwitch = styled((props) => (
  <Switch
    disabled
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  cursor: "auto",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& .MuiSwitch-thumb": {
        backgroundColor: "#fff", // Checked thumb color
      },
      "& + .MuiSwitch-track": {
        backgroundColor: "#673C8E",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
    backgroundColor: "#673C8E", // Unchecked thumb color
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#fff",
    border: "2px solid #673C8E", // Unchecked border color
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"], {
      duration: 500,
    }),
  },
}));

// import React from "react";
// import { styled, Switch } from "@mui/material";
// import { useThemeContext } from "./ThemeContextProvider";

// const IOSSwitch = styled((props) => (
//   <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
// ))(({ theme }) => ({
//   width: 42,
//   height: 26,
//   padding: 0,
//   "& .MuiSwitch-switchBase": {
//     padding: 0,
//     margin: 2,
//     transitionDuration: "300ms",
//     "&.Mui-checked": {
//       transform: "translateX(16px)",
//       color: "#fff",
//       "& .MuiSwitch-thumb": {
//         backgroundColor: "#fff",
//       },
//       "& + .MuiSwitch-track": {
//         backgroundColor: "#673C8E",
//         opacity: 1,
//       },
//     },
//     "&.Mui-focusVisible .MuiSwitch-thumb": {
//       color: "#33cf4d",
//       border: "6px solid #fff",
//     },
//     "&.Mui-disabled .MuiSwitch-thumb": {
//       color:
//         theme.palette.mode === "light"
//           ? theme.palette.grey[100]
//           : theme.palette.grey[600],
//     },
//     "&.Mui-disabled + .MuiSwitch-track": {
//       opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
//     },
//   },
//   "& .MuiSwitch-thumb": {
//     boxSizing: "border-box",
//     width: 22,
//     height: 22,
//     backgroundColor: "#673C8E",
//   },
//   "& .MuiSwitch-track": {
//     borderRadius: 26 / 2,
//     backgroundColor: "#fff",
//     border: "2px solid #673C8E",
//     opacity: 1,
//     transition: theme.transitions.create(["background-color", "border"], {
//       duration: 500,
//     }),
//   },
// }));

// export const ThemeSwitcher = () => {
//   const { mode, toggleColorMode } = useThemeContext();

//   return <IOSSwitch checked={mode === "light"} onChange={toggleColorMode} />;
// };
