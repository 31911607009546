import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { $crud } from "../../utils/CrudFactory";
import RetrieveAccountModal from "../../components/modals/RetrieveAccountModal";

export function DeleteAccount() {
  const theme = useTheme();
  const user = JSON.parse(localStorage.getItem("user"));
  const [isCheck, setIsCheck] = useState(false);
  const [isCheckArchive, setIsCheckArchive] = useState(false);
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [passwordCard, setPasswordCard] = useState(
    user.deleteAccount ? false : true
  );
  const [verifyCodeCard, setVerifyCodeCard] = useState(false);
  const [deleteCard, setDeleteCard] = useState(false);
  const [archiveConfirmCard, setArchiveConfirmCard] = useState(false);
  const [archiveCard, setArchiveCard] = useState(user.deleteAccount);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const [deleteAccountDate, setDeleteAccountDate] = useState("");

  useEffect(() => {
    const storedDeleteAccountDate = user.deleteAccountDate;
    if (storedDeleteAccountDate) {
      const deleteDate = new Date(storedDeleteAccountDate);
      deleteDate.setDate(deleteDate.getDate() + 7); // Add 7 days
      setDeleteAccountDate(
        deleteDate.toLocaleDateString("en-US", {
          day: "numeric",
          month: "long",
          year: "numeric",
        })
      );
    }
  }, []);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowCode = () => setShowCode((show) => !show);

  const handleArchiveAccount = () => {
    setArchiveCard(false);
    setArchiveConfirmCard(true);
  };
  const handleRetrieveAccount = () => {
    if (isCheckArchive) {
      $crud
        .delete("admin/account-retrieve", {
          adminId: user._id,
          deleteAccount: false,
        })
        .then((response) => {
          const { message, type, data } = response;
          if (type == "success") {
            localStorage.clear();
            navigate("/login");
            // enqueueSnackbar("Logged Out Successfully.", { variant: "success" });
          }
        })
        .catch((error) => {
          console.error("Error deleting account:", error);
        });
    } else {
      enqueueSnackbar("Please Check the box to confirm.", { variant: "error" });
    }
  };
  const handleContinue = () => {
    if (password != "") {
      $crud
        .post("admin/account-deletion-send-code", {
          password,
          id: user._id,
        })
        .then((response) => {
          const { message, type, data } = response;
          if (type == "success") {
            setPasswordCard(false);
            setVerifyCodeCard(true);
          }
        })
        .catch((error) => {
          console.error("Error sending code:", error);
        });
    } else {
      enqueueSnackbar("Enter password to continue", { variant: "error" });
    }
  };

  const handleVerifyCode = () => {
    if (code != "") {
      $crud
        .post("admin/account-deletion-verify-code", {
          otp: code,
          adminId: user._id,
        })
        .then((response) => {
          const { message, type, data } = response;
          if (type == "success") {
            setVerifyCodeCard(false);
            setDeleteCard(true);
          }
        })
        .catch((error) => {
          console.error("Error sending code:", error);
        });
    } else {
      enqueueSnackbar("Enter code to continue", { variant: "error" });
    }
  };

  const handleDeleteAccount = () => {
    if (isCheck) {
      $crud
        .delete("admin/account-deletion", {
          adminId: user._id,
        })
        .then((response) => {
          const { message, type, data } = response;
          if (type == "success") {
            localStorage.clear();
            navigate("/login");
            enqueueSnackbar("Logged Out Successfully.", { variant: "success" });

            // setDeleteCard(false);
            // setDeleteSuccessCard(true);
          }
        })
        .catch((error) => {
          console.error("Error deleting account:", error);
        });
    } else {
      enqueueSnackbar("Please Check the box to confirm.", { variant: "error" });
    }
  };

  return (
    <>
      {user.deleteAccount == true ? (
        <RetrieveAccountModal
          archiveCard={archiveCard}
          archiveConfirmCard={archiveConfirmCard}
          isCheckArchive={isCheckArchive}
          setIsCheckArchive={setIsCheckArchive}
          handleRetrieveAccount={handleRetrieveAccount}
          setArchiveCard={setArchiveCard}
          setArchiveConfirmCard={setArchiveConfirmCard}
          theme={theme}
          navigate={navigate}
          deleteAccountDate={deleteAccountDate}
        />
      ) : (
        <Grid container sx={{ padding: "0 16px" }}>
          <Grid item xs={12}>
            <Typography
              sx={{
                color: theme.palette.text.primary,
                fontSize: "30px",
                fontWeight: 700,
                // textAlign: "center",
              }}
            >
              Account deletion
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "80px" }}>
            <Card
              sx={{
                padding: deleteCard ? 0 : "40px",
                margin: "0 auto",
                width: "650px",
                height: deleteCard || user.deleteAccount ? "400px" : "auto",
                background: theme.palette.primary.main,
                boxShadow: theme.palette.boxShadow.primary,
                borderRadius: 0,
                border: theme.palette.border.primary,
              }}
            >
              {/* --------------------- RECOMFIRM PASSWORD ---------------------------------- */}
              {passwordCard && (
                <>
                  <Typography
                    sx={{
                      color: theme.palette.text.primary,
                      fontSize: "32px",
                      fontWeight: 700,
                      textAlign: "center",
                    }}
                  >
                    Re-Confirm Passsword
                  </Typography>
                  <Typography
                    sx={{
                      color: theme.palette.text.secondary,
                      fontSize: "16px",
                      fontWeight: 400,
                      textAlign: "center",
                    }}
                  >
                    Please re-enter your password to continue with this step
                  </Typography>
                  <Typography
                    sx={{
                      color: theme.palette.text.primary,
                      fontSize: "20px",
                      fontWeight: 600,
                      marginTop: "30px",
                      mb: 1,
                    }}
                  >
                    Password
                  </Typography>
                  <FormControl sx={{ width: "100%" }} variant="outlined">
                    <OutlinedInput
                      placeholder="Enter Password"
                      sx={{
                        color: "#1C1B1F",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: (theme) => theme.palette.text.secondary, // Change outlined border color
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: (theme) => theme.palette.text.secondary, // Change outlined border color on hover
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#1C1B1F", // Change outlined border color when focused
                        },
                        "& .MuiOutlinedInput-notchedOutline legend": {
                          display: "none", // Remove the notch
                        },
                      }}
                      size="small"
                      id="password"
                      name="password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      // onBlur={handleBlur}
                      type="text"
                      className={!showPassword ? "hidden-otp" : ""}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 2,
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      onClick={handleContinue}
                      variant="contained"
                      fullWidth
                      size="small"
                      type="submit"
                      sx={{
                        borderColor: "#673C8E",
                        background: "#673C8E",
                        color: "#fff",
                        padding: "10px 30px",
                        fontSize: "14px",
                        borderRadius: "10px",
                        textTransform: "capitalize",
                        "&:hover": {
                          background: "#673C8E",
                          borderColor: "#673C8E",
                          color: "#fff",
                        },
                      }}
                    >
                      Continue
                    </Button>
                  </Box>
                </>
              )}
              {/* --------------------- RECOMFIRM PASSWORD ---------------------------------- */}

              {/* ---------------------- VERIFY CODE CARD --------------------------------- */}
              {verifyCodeCard && (
                <>
                  <Typography
                    sx={{
                      color: theme.palette.text.primary,
                      fontSize: "32px",
                      fontWeight: 700,
                      textAlign: "center",
                    }}
                  >
                    Verify code
                  </Typography>
                  <Typography
                    sx={{
                      color: theme.palette.text.secondary,
                      fontSize: "16px",
                      fontWeight: 400,
                      textAlign: "center",
                    }}
                  >
                    Enter the 6 digit code from email
                  </Typography>
                  <Typography
                    sx={{
                      color: theme.palette.text.primary,
                      fontSize: "20px",
                      fontWeight: 600,
                      marginTop: "30px",
                      mb: 1,
                    }}
                  >
                    Enter Code
                  </Typography>
                  <FormControl sx={{ width: "100%" }} variant="outlined">
                    <OutlinedInput
                      placeholder="Enter Code"
                      sx={{
                        color: "#1C1B1F",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: (theme) => theme.palette.text.secondary, // Change outlined border color
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: (theme) => theme.palette.text.secondary, // Change outlined border color on hover
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#1C1B1F", // Change outlined border color when focused
                        },
                        "& .MuiOutlinedInput-notchedOutline legend": {
                          display: "none", // Remove the notch
                        },
                      }}
                      size="small"
                      id="code"
                      name="code"
                      value={code}
                      onChange={(e) => {
                        setCode(e.target.value);
                      }}
                      // onBlur={handleBlur}
                      type="number"
                      className={!showCode ? "hidden-otp" : ""}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowCode}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showCode ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 2,
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      onClick={handleVerifyCode}
                      variant="contained"
                      fullWidth
                      size="small"
                      type="submit"
                      sx={{
                        borderColor: "#673C8E",
                        background: "#673C8E",
                        color: "#fff",
                        padding: "10px 30px",
                        fontSize: "14px",
                        borderRadius: "10px",
                        textTransform: "capitalize",
                        "&:hover": {
                          background: "#673C8E",
                          borderColor: "#673C8E",
                          color: "#fff",
                        },
                      }}
                    >
                      Verify And Continue
                    </Button>
                  </Box>
                </>
              )}
              {/* ---------------------- VERIFY CODE CARD --------------------------------- */}

              {/* --------------------- DELETE ACCOUNT CARD ---------------------------------- */}
              {deleteCard && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "inherit",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isCheck}
                        onChange={(e) => setIsCheck(e.target.checked)}
                        sx={{
                          color: "#683C8C",
                          "&.Mui-checked": {
                            color: "#683C8C",
                          },
                        }}
                      />
                    }
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        color: "#683C8C",
                        fontSize: "20px",
                      },
                    }}
                    label="Yes, please delete my account."
                  />

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      gap: 2,
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      onClick={handleDeleteAccount}
                      variant="contained"
                      size="small"
                      type="submit"
                      sx={{
                        borderColor: "#F04444",
                        background: "#F04444",
                        color: "#fff",
                        padding: "10px 30px",
                        fontSize: "14px",
                        borderRadius: "10px",
                        textTransform: "capitalize",
                        "&:hover": {
                          background: "#F04444",
                          borderColor: "#F04444",
                          color: "#fff",
                        },
                      }}
                    >
                      Delete
                    </Button>
                    <Button
                      // onClick={handleDeleteAccount}
                      variant="outlined"
                      size="small"
                      type="submit"
                      sx={{
                        borderColor: "#673C8E",
                        // background: "#673C8E",
                        color: "#673C8E",
                        padding: "10px 30px",
                        fontSize: "14px",
                        borderRadius: "10px",
                        textTransform: "capitalize",
                        "&:hover": {
                          // background: "#673C8E",
                          borderColor: "#673C8E",
                          color: "#673C8E",
                        },
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              )}
              {/* --------------------- DELETE ACCOUNT CARD---------------------------------- */}
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
}
