import React from "react";

import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
  const useAuth = () => {
    const user = localStorage.getItem("token");
    if (user) {
      return true;
    } else {
      return false;
    }
  };

  const auth = useAuth();

  if (auth) {
    return <Outlet />;
  } else {
    return <Navigate to="/login" />;
    // return <Navigate to="/login-page" />;
  }
};
export default ProtectedRoute;
