import { FileUploadOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControlLabel,
  Popover,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import jsPDF from "jspdf";
import React, { useState } from "react";

const Export = ({ columns, rows, type }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataFormat, setDataFormat] = useState("pdf");
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // FUNCTION TO CLOSE THE FILTER POPOVER-----------------
  const handleClose = () => {
    setAnchorEl(null);
  };
  // FUNCTION TO CLOSE THE FILTER POPOVER-----------------

  const handleExportToCSV = () => {
    // Initialize CSV content
    let csvContent = "data:text/csv;charset=utf-8,";

    // Get table data
    const tableData = [];
    // Push header row
    const headerRow = columns
      .filter((column) => column.id !== "invoice" && column.id !== "statement")
      .map((column) => column.label);
    tableData.push(headerRow);
    // Push data rows
    rows.forEach((row) => {
      const rowData = [];
      columns.forEach((column) => {
        // Exclude 'invoice' and 'statement' columns
        if (column.id !== "invoice" && column.id !== "statement") {
          rowData.push(row[column.id]);
        }
      });
      tableData.push(rowData);
    });

    // Convert table data to CSV format
    tableData.forEach((rowArray) => {
      const row = rowArray.join(",");
      csvContent += row + "\r\n";
    });

    // Create a download link
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${type}.csv`);
    document.body.appendChild(link); // Required for FF

    // Click the download link
    link.click();
  };

  const handleExportToPDF = () => {
    // Initialize jsPDF
    const doc = new jsPDF();

    // Set document properties
    doc.setProperties({
      title: "Table Data Export",
    });

    // Get table data
    const tableData = [];
    // Push header row
    const headerRow = columns
      .filter((column) => column.id !== "invoice" && column.id !== "statement")
      .map((column) => column.label);
    tableData.push(headerRow);
    // Push data rows
    rows.forEach((row) => {
      const rowData = [];
      columns.forEach((column) => {
        // Check if the column is 'currentstatus' or 'position'
        if (column.id != "invoice" || column.id !== "statement") {
          rowData.push(row[column.id]);
        }
      });
      tableData.push(rowData);
    });

    // Auto table content
    doc.autoTable({
      head: [tableData[0]], // Header row
      body: tableData.slice(1), // Data rows
    });

    // Save the PDF
    doc.save(`${type}.pdf`);
  };
  return (
    <>
      <Button
        onClick={handleClick}
        variant="contained"
        size="small"
        sx={{
          textTransform: "capitalize",
          ml: 2,
          backgroundColor: "#683C8C",
          color: "#FFFFFF",
          borderColor: "#683C8C",

          "&:hover": {
            backgroundColor: "#683C8C",
            color: "#FFFFFF",
            borderColor: "#683C8C",
          },
        }}
        startIcon={<FileUploadOutlined />}
      >
        Export
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ marginTop: "8px" }}
      >
        <div
          style={{
            // marginTop: "15px",
            maxWidth: "168px",
            padding: "10px",
            height: "auto",
          }}
        >
          <Box>
            <RadioGroup
              defaultValue="pdf"
              onChange={(e) => setDataFormat(e.target.value)}
            >
              <FormControlLabel
                value="pdf"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "rgba(104, 60, 140, 1)",
                      "&.Mui-checked": {
                        color: "rgba(104, 60, 140, 1)",
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    sx={{ fontSize: "10px", fontWeight: 700, color: "#000" }}
                  >
                    PDF
                  </Typography>
                }
              />
              <FormControlLabel
                value="csv"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "rgba(104, 60, 140, 1)",
                      "&.Mui-checked": {
                        color: "rgba(104, 60, 140, 1)",
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    sx={{ fontSize: "10px", fontWeight: 700, color: "#000" }}
                  >
                    CSV
                  </Typography>
                }
              />
            </RadioGroup>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Button
              variant="outlined"
              size="small"
              onClick={handleClose}
              sx={{
                textTransform: "capitalize",
                backgroundColor: "transparent",
                color: "#683C8C",
                borderColor: "#683C8C",
                "&:hover": {
                  backgroundColor: "transparent",
                  color: "#683C8C",
                  borderColor: "#683C8C",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                dataFormat == "pdf" ? handleExportToPDF() : handleExportToCSV();
              }}
              sx={{
                backgroundColor: "#683C8C",
                textTransform: "capitalize",
                color: "#FFFFFF",
                borderColor: "#683C8C",
                "&:hover": {
                  backgroundColor: "#683C8C",
                  color: "#FFFFFF",
                  borderColor: "#683C8C",
                },
              }}
            >
              Export
            </Button>
          </Box>
        </div>
      </Popover>
    </>
  );
};

export default Export;
