import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Box, Typography, IconButton } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export default function ReviewCarousel() {
  const swiperRef = React.useRef(null);

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  return (
    <>
      <Swiper
        slidesPerView={3}
        spaceBetween={60}
        pagination={{ clickable: true }}
        className="mySwiper"
        style={{ width: "calc(375px * 3 + 30px * 2 )", marginTop: "20px" }} // Adjust total width of swiper
        breakpoints={{
          // Responsive breakpoints
          600: {
            slidesPerView: 2, // 2 slides per view on small screens (sm)
          },
          960: {
            slidesPerView: 3, // 3 slides per view on medium screens (md) and larger
          },
        }}
        ref={swiperRef}
      >
        {[...Array(15)].map((_, index) => (
          <SwiperSlide key={index}>
            <Box
              sx={{
                width: "360px",
                background: "rgba(202, 187, 215, 1)",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography>No Best Review by client to displayed</Typography>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
      <Box
        position="absolute"
        top="0"
        right="0"
        display="flex"
        alignItems="center"
        zIndex={10}
      >
        <IconButton
          onClick={goPrev}
          sx={{
            mr: 1,
            background: "rgba(104, 60, 140, 0.35)",
            borderRadius: "14px",
            color: "rgba(104, 60, 140, 1)",
            padding: "13px",
          }}
        >
          <NavigateBeforeIcon />
        </IconButton>
        <IconButton
          onClick={goNext}
          sx={{
            background: "rgba(104, 60, 140, 0.35)",
            borderRadius: "14px",
            color: "rgba(104, 60, 140, 1)",
            padding: "13px",
          }}
        >
          <NavigateNextIcon />
        </IconButton>
      </Box>
    </>
  );
}
