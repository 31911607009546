import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Worker } from '@react-pdf-viewer/core';
import { ThemeContextProvider } from './theme/ThemeContextProvider';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <ThemeContextProvider>
        <DndProvider backend={HTML5Backend}>

          <App />
        </DndProvider>

      </ThemeContextProvider>
    </Worker>
  </React.StrictMode>
);
