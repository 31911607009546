import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { $crud } from "../../utils/CrudFactory";
import { usePagination } from "../../hooks";
import DynamicTable from "../../components/DynamicTable";
import { orderFilters, regularOfferFilters } from "../../utils/filterConfig";
import {
  Visibility,
  VisibilityOff,
  EditNote,
  Delete,
} from "@mui/icons-material";
import RegularOfferModal from "../../components/modals/RegularOfferModal";

export function RegularOffers() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [expandedRows, setExpandedRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openRegularOfferModal, setOpenRegularOfferModal] = useState(false);
  const [rows, setRows] = useState([]); //STATE TO GET THE ORDER LIST
  const { page, limit, setLimit, setPage, total, setTotal } = usePagination(); // PAGINATION PROPS
  // STATE TO MANAGE FILTERS --------------------------------------
  const [filtrationData, setFiltrationData] = useState({
    from: "",
    to: "",
    target: "",
    stage: "",
  });
  // STATE TO MANAGE FILTERS --------------------------------------

  async function retrieveData() {
    const { data } = await $crud.retrieve("admin/regular-offers", {
      limit,
      page,
      from: filtrationData.from,
      to: filtrationData.to,
      target: filtrationData.target,
      stage: filtrationData.stage,
    });
    return data;
  }
  useEffect(() => {
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
    retrieveData().then((res) => {
      setRows(res.result);
      setTotal(res.total);
    });
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
  }, [limit, page, filtrationData]);

  const handleOpenRegularOfferModal = (row) => {
    setSelectedRow(row);
    setOpenRegularOfferModal(true);
  };

  // function to close position modal---------------------------------
  const handleCloseModal = () => {
    setOpenRegularOfferModal(false);
    setSelectedRow(null);
  };

  // function to call the api and update the comment -------------------------------------
  const handleOfferSubmit = async (reason) => {
    // Logic to update position with reason
    const { data, type } = await $crud.put(`admin/regular-offer-archieve`, {
      id: selectedRow._id,
    });
    if (type == "success") {
      retrieveData().then((res) => {
        setRows(res.result);
        setTotal(res.total);
      });
    }
    // handleCloseModal();
  };
  // COLUMNS IN THE ORDER TABLE-----------------------------
  const clientColumns = [
    {
      id: "offerCode",
      label: "Offer Code",
      align: "center",
    },
    // {
    //   id: "target",
    //   label: "Target",
    //   align: "center",
    //   renderCell: (row) => {
    //     const getStatusStyles = (status) => {
    //       switch (status) {
    //         case "Drivers":
    //           return {
    //             background: "rgba(7, 79, 36, 0.35)",
    //           };
    //         case "Both":
    //           return {
    //             background: "rgba(104, 60, 140, 0.5)",
    //           };
    //         case "Clients":
    //           return {
    //             background: "rgba(251, 189, 189, 1)",
    //           };
    //         default:
    //           return {
    //             background: "#FFF0ED",
    //           };
    //       }
    //     };
    //     const styles = getStatusStyles(row.target);
    //     return (
    //       <Button
    //         sx={{
    //           background: styles.background,
    //           color: "rgba(23, 26, 31, 1)",
    //           cursor: "auto",
    //           padding: "7px 27px",
    //           textTransform: "capitalize",
    //           borderRadius: "22px",
    //           border: "none",
    //           fontSize: "12px",
    //           width: "100px",
    //           outline: "none !important",
    //           "&:hover": {
    //             outline: styles.background,
    //             background: styles.background,
    //           },
    //           "&:focus": {
    //             outline: styles.background,
    //             background: styles.background,
    //           },
    //         }}
    //       >
    //         {row.target}
    //       </Button>
    //     );
    //   },
    // },
    {
      id: "stage",
      label: "Stage",
      align: "center",
      renderCell: (row) => {
        const getStatusStyles = (status) => {
          switch (status) {
            case "Active":
              return {
                background: "rgba(7, 79, 36, 0.35)",
              };
            case "Archived":
              return {
                background: "rgba(104, 60, 140, 0.5)",
              };
            case "Expired":
              return {
                background: "rgba(251, 189, 189, 1)",
              };
            default:
              return {
                background: "#FFF0ED",
              };
          }
        };
        const styles = getStatusStyles(row.stage);
        return (
          <Button
            sx={{
              background: styles.background,
              cursor: "auto",
              borderRadius: "22px",
              color: "rgba(23, 26, 31, 1)",
              padding: "7px 27px",
              textTransform: "capitalize",
              border: "none",
              fontSize: "12px",
              width: "100px",
              outline: "none !important",
              "&:hover": {
                outline: styles.background,
                background: styles.background,
              },
              "&:focus": {
                outline: styles.background,
                background: styles.background,
              },
            }}
          >
            {row.stage}
          </Button>
        );
      },
    },
    {
      id: "usedBy",
      label: "Used By",
      align: "center",
    },
    {
      id: "savedValue",
      label: "Saved Value",
      align: "center",
    },
    {
      id: "startDate",
      label: "Start Date",
      align: "center",
    },
    {
      id: "expireDate",
      label: "Expire Date",
      align: "center",
    },
    {
      id: "action",
      label: "View",
      renderCell: (row) => {
        const isRowExpanded = expandedRows.includes(row._id);
        return (
          <>
            {isRowExpanded ? (
              <IconButton
                sx={{
                  fontSize: "8px",
                  color: "rgba(104, 60, 140, 1)",
                }}
                onClick={() => handleExpandRow(row._id)}
                // Add your onClick logic for delete here
              >
                <VisibilityOff />
              </IconButton>
            ) : (
              <IconButton
                sx={{ fontSize: "8px", color: "rgba(104, 60, 140, 1)" }}
                onClick={() => handleExpandRow(row._id)}
              >
                <Visibility />
              </IconButton>
            )}
          </>
        );
      },
    },
    {
      id: "action",
      label: "Delete",
      renderCell: (row) => {
        const isRowExpanded = expandedRows.includes(row._id);
        return (
          <>
            <IconButton
              sx={{ fontSize: "8px", color: "rgba(104, 60, 140, 1)" }}
              onClick={(e) => handleOpenRegularOfferModal(row)}
            >
              <Delete />
            </IconButton>
          </>
        );
      },
    },
  ];
  // COLUMNS IN THE ORDER TABLE-----------------------------

  const handleExpandRow = (rowId) => {
    setExpandedRows((prev) =>
      prev.includes(rowId)
        ? prev.filter((id) => id !== rowId)
        : [...prev, rowId]
    );
  };

  // FUNCTION TO SET THE FILTERS IN filtrationData STATE -----------------------------
  const getFilteredData = (data) => {
    setFiltrationData({
      ...filtrationData,
      ...data,
    });
  };
  // FUNCTION TO SET THE FILTERS IN filtrationData STATE -----------------------------
  return (
    <>
      <Grid container sx={{ padding: "0 18px" }}>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              fontSize: "30px",
              // color: "#673C8E",
              color: theme.palette.text.primary,
            }}
          >
            Regular Offers
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            paddingRight: "24px",
            display: "flex",
            // flexDirection: "column",
            justifyContent: "end",
            alignItems: "end",
            gap: "15px",
          }}
        >
          <Button
            onClick={() => navigate("add")}
            variant="outlined"
            size="small"
            sx={{
              padding: "10px 30px",
              textTransform: "capitalize",
              backgroundColor: "transparent",
              color: "rgba(103, 60, 142, 1)",
              borderColor: "rgba(103, 60, 142, 1)",
              borderRadius: "4px",
              fontWeight: 600,
              // fontSize: "16px",
              "&:hover": {
                backgroundColor: "transparent",
                color: "rgba(103, 60, 142, 1)",
                borderColor: "rgba(103, 60, 142, 1)",
              },
            }}
          >
            Create New Offer
          </Button>
          <Button
            onClick={() => navigate("/services/help-offers")}
            variant="contained"
            size="small"
            sx={{
              padding: "10px 30px",
              textTransform: "capitalize",
              backgroundColor: "rgba(103, 60, 142, 1)",
              color: "#fff",
              borderRadius: "4px",
              fontWeight: 600,
              // fontSize: "16px",
              "&:hover": {
                backgroundColor: "rgba(103, 60, 142, 1)",
                color: "#fff",
              },
            }}
          >
            Go To Help Offers
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ paddingRight: "24px" }}>
        <DynamicTable
          rows={rows}
          type="regularOffers"
          url="regularOffers"
          setRows={setRows}
          filterConfig={regularOfferFilters} // Pass the filter checkbox options
          noRecordTitle="No regular offers Yet !" // no data title
          onFilterData={getFilteredData} // filteration data
          onResetFilters={() => {
            setFiltrationData((prev) => ({
              target: "",
              stage: "",
              from: "",
              to: "",
            }));
          }}
          onSearchData={(value) =>
            setFiltrationData({ ...filtrationData, search: value })
          }
          paginationProps={{ limit, page, setLimit, setPage, total }}
          columns={clientColumns} // Pass the columns prop
          expandedRows={expandedRows}
          handleExpandRow={handleExpandRow}
        />
      </Box>
      {openRegularOfferModal && selectedRow && (
        <RegularOfferModal
          open={openRegularOfferModal}
          archived={selectedRow.archived}
          onSubmit={handleOfferSubmit}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
}
