import React from 'react';
import { useMediaQuery } from '@mui/material'; // Correct import path for useMediaQuery
import { createTheme } from '@mui/material/styles';

// Function to get design tokens based on the mode
export const getDesignTokens = (mode) => ({
    palette: {
        mode,
        ...(mode === 'light'
            ? {
                primary: {
                    main: '#fff',
                },
                secondary: {
                    main: '#dc004e',
                },
                background: {
                    default: 'rgba(104, 60, 140, 0.06)',
                    primary: "#F3F2F7",
                    paper: '#f5f5f5',
                },
                text: {
                    primary: '#673C8E',
                    secondary: 'rgba(86, 93, 109, 1)',
                    black: '#000',
                },
                boxShadow: {
                    primary: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
                },
                border: {
                    primary: "1px solid #673C8E"
                }

            }
            : {
                primary: {
                    main: '#2A2C38',
                },
                secondary: {
                    main: '#03dac6',
                },
                background: {
                    default: 'rgba(104, 60, 140, 0.06)',
                    primary: "#F3F2F7",
                    paper: '#333333',
                },
                text: {
                    primary: '#A3A3A3',
                    secondary: '#e0e0e0',
                    black: '#fff',

                },
                boxShadow: {
                    primary: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
                },
                border: {
                    primary: "1px solid #673C8E"
                }
            }),
    },
});

// Custom hook to manage theme
export const useColorTheme = () => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    // Get initial theme from local storage or default to light
    const initialMode = localStorage.getItem('themeMode') || 'light';
    const [mode, setMode] = React.useState(initialMode);

    const toggleColorMode = () => {
        const newMode = mode === 'light' ? 'dark' : 'light';
        setMode(newMode);
        localStorage.setItem('themeMode', newMode); // Save the new theme mode to local storage
    };

    const modifiedTheme = React.useMemo(
        () => createTheme(getDesignTokens(mode)),
        [mode]
    );

    return {
        theme: modifiedTheme,
        mode,
        toggleColorMode,
    };
};