import {
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import back from "../../assets/images/svg/back.svg";
import profile from "../../assets/images/svg/avatar.svg";
import email from "../../assets/images/svg/email.svg";
import call from "../../assets/images/svg/call.svg";
import edit from "../../assets/images/svg/edit.svg";
import map from "../../assets/images/svg/location.svg";
import DriverDetailCard from "../../components/cards/DriverDetailCard";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import add from "../../assets/images/svg/add.svg";
import reject from "../../assets/images/svg/reject.svg";
import minus from "../../assets/images/svg/minus.svg";
import returnIcon from "../../assets/images/svg/return.svg";
import dotIcon from "../../assets/images/svg/dot.svg";
import reload from "../../assets/images/svg/reload.svg";
import MapComponent from "../../components/maps/MapComponent";
import CustomPagination from "../../components/CustomPagination";
import { usePagination } from "../../hooks";
import DynamicTable from "../../components/DynamicTable";
import { $crud } from "../../utils/CrudFactory";
import sort from "../../assets/images/svg/sort.svg";

// CUSTOM STEPPER FOR ORDER DETAIL ------------------------------

const CustomStepConnector = () => (
  <StepConnector
    sx={{
      "& .MuiStepConnector-line": {
        border: "1px dashed rgba(103, 60, 142, 1)",
      },
    }}
  />
);

// CUSTOM STEPPER FOR ORDER DETAIL ------------------------------

// CUSTOM TOOLTIP ====================================
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: 140, // Custom maxWidth for Tooltip
    minHeight: 56, // Custom maxWidth for Tooltip
    background: "#795898", // Additional styles if needed
    color: "#fff", // Additional styles if needed
    fontSize: "8px", // Additional styles if needed
    padding: "8px", // Additional styles if needed
    position: "absolute",
    top: "-38px",
    left: "-90px",
    borderRadius: "0",
  },
});

// STEPS FOR THE STATUS OF ORDER -------------------------------

const steps = [
  {
    label: "Account Created",
    description: "21 Jul 2020\n11:49 AM",
    icon: <img src={add} width="25px" style={{ marginLeft: "8px" }} />,
    dot: <img src={dotIcon} />,
    tooltip: false,
  },
];

// STEPS FOR THE STATUS OF ORDER -------------------------------
export function DriverDetail() {
  const [orders, setOrders] = React.useState([
    {
      id: 1,
      pickups: 4,
      kms: 144,
      weight: "124 Lbs / 20 Kgs",
      value: "$2000",
      type: "Special",
      mapComponent: <MapComponent />,
    },
    {
      id: 2,
      pickups: 3,
      kms: 120,
      weight: "100 Lbs / 18 Kgs",
      value: "$1500",
      type: "Standard",
      mapComponent: <MapComponent />,
    },
    {
      id: 3,
      pickups: 5,
      kms: 160,
      weight: "140 Lbs / 22 Kgs",
      value: "$2500",
      type: "Urgent",
      mapComponent: <MapComponent />,
    },
  ]);

  const { page, limit, setLimit, setPage, setTotal, total } = usePagination();
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(+event.target.value);
    setPage(0);
  };
  const currentOrder = orders[page] || orders[0];

  const [mergedSteps, setMergedSteps] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const location = useLocation();
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const navigate = useNavigate();
  const handleViewAllClick = () => {
    navigate(`/orders?driverId=${driver.driverID}`);
  };
  const [driver, setDriver] = useState(location.state?.selectedDriver);
  console.log(driver, "driver");
  useEffect(() => {
    // Combine order.positionHistory with existing steps
    const merged = [...steps];

    if (driver && driver.blockStatusHistory) {
      driver.blockStatusHistory.forEach((historyItem, index) => {
        let label = "";
        let icon = "";
        let desc = "";
        let dot = <img src={dotIcon} />;
        if (historyItem.status == "Blocked") {
          label = "Account Blocked";
          desc = "Fri,22 Jul 2020,10:44 AM - 35m";
          icon = (
            <img src={reject} width="25px" style={{ marginLeft: "8px" }} />
          );
        }
        if (historyItem.status == "Active") {
          label = "Account Active";
          desc = "Fri,22 Jul 2020,10:44 AM - 35m";
          icon = (
            <img src={reload} width="25px" style={{ marginLeft: "8px" }} />
          );
        }
        merged.push({
          label,
          description: desc,
          icon,
          reason: historyItem.reason,
          tooltip: true,
          dot,
        });
      });
    }

    setMergedSteps(merged);
  }, [driver, steps]);
  const handleBackClick = () => {
    navigate(-1);
  };

  async function retrieveDriverOrders() {
    const { data } = await $crud.retrieve("admin/orders", {
      search: driver.driverID,
    });
    return data;
  }

  const sortedRows = useMemo(() => {
    let sortableRows = [...orderData];
    if (sortConfig.key !== null) {
      sortableRows.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableRows;
  }, [orderData, sortConfig]);

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    retrieveDriverOrders().then((res) => {
      // console.log(res.result);
      setOrderData(res.result.slice(0, 5));
      // setTotal(res.total);
    });
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
  }, [driver]);

  const orderColumns = [
    {
      id: "orderId",
      label: "Order ID",
      align: "center",
    },
    {
      id: "dateBooked",
      label: "Date",
      align: "center",
    },
    {
      id: "status",
      label: "Status",
      align: "center",
      renderCell: (row) => {
        return (
          <Button
            sx={{
              background: "rgba(103, 60, 142, 0.40)",
              color: "rgba(103, 60, 142, 1)",
              padding: "4px 14px",
              textTransform: "capitalize",
              border: "none",
              cursor: "default",
              fontSize: "10px",
              width: "90px",
              outline: "none !important",
              "&:hover": {
                outline: "rgba(103, 60, 142, 0.40)",
                background: "rgba(103, 60, 142, 0.40)",
              },
              "&:focus": {
                outline: "rgba(103, 60, 142, 0.40)",
                background: "rgba(103, 60, 142, 0.40)",
              },
            }}
          >
            {/* {row.status} */}
            New Order
          </Button>
        );
      },
    },
  ];

  // const orderData = [
  //   {
  //     orderID: "OID123",
  //     date: "27 May 2024, 12:00 AM",
  //     status: "New Order",
  //   },
  //   {
  //     orderID: "OID123",
  //     date: "27 May 2024, 12:00 AM",
  //     status: "New Order",
  //   },
  //   {
  //     orderID: "OID123",
  //     date: "27 May 2024, 12:00 AM",
  //     status: "New Order",
  //   },
  //   {
  //     orderID: "OID123",
  //     date: "27 May 2024, 12:00 AM",
  //     status: "New Order",
  //   },
  //   {
  //     orderID: "OID123",
  //     date: "27 May 2024, 12:00 AM",
  //     status: "New Order",
  //   },
  //   {
  //     orderID: "OID123",
  //     date: "27 May 2024, 12:00 AM",
  //     status: "New Order",
  //   },
  // ];

  return (
    <Grid container sx={{ padding: "0 24px" }} spacing={1}>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "flex-start",
            gap: 1,
          }}
        >
          <IconButton onClick={handleBackClick} sx={{ marginLeft: "-10px" }}>
            <img src={back} />
          </IconButton>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: 6,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 700,
                  fontSize: "30px",
                  color: "#673C8E",
                }}
              >
                Driver's Details
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} md={6} sx={{ textAlign: "right" }}>
        <Button
          variant="contained"
          size="small"
          sx={{
            padding: "6px 14px",
            textTransform: "capitalize",
            cursor: "default",
            borderRadius: 0,
            boxShadow: "none",
            fontWeight: 400,
            backgroundColor:
              driver.blockStatus === "Active"
                ? "rgba(103, 60, 142, 1)"
                : "rgba(255, 240, 237, 1)",
            color:
              driver.blockStatus === "Active"
                ? "#fff"
                : "rgba(254, 133, 106, 1)",
            "&:hover": {
              boxShadow: "none",
              backgroundColor:
                driver.blockStatus === "Active"
                  ? "rgba(103, 60, 142, 1)"
                  : "rgba(255, 240, 237, 1)",
              color:
                driver.blockStatus === "Active"
                  ? "#fff"
                  : "rgba(254, 133, 106, 1)",
            },
          }}
        >
          {/* Active */}
          {driver.blockStatus}
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{
            padding: "6px 65px",
            textTransform: "capitalize",
            backgroundColor:
              driver.status === "Online"
                ? "rgba(7, 79, 36, 0.2)"
                : "rgba(255, 240, 237, 1)",
            color:
              driver.status === "Online"
                ? "rgba(7, 79, 36, 1)"
                : "rgba(254, 133, 106, 1)",
            cursor: "default",
            boxShadow: "none",
            fontWeight: 400,
            "&:hover": {
              backgroundColor:
                driver.status === "Online"
                  ? "rgba(7, 79, 36, 0.2)"
                  : "rgba(255, 240, 237, 1)",
              color:
                driver.status === "Online"
                  ? "rgba(7, 79, 36, 1)"
                  : "rgba(254, 133, 106, 1)",
              boxShadow: "none",
            },
            mx: 2,
          }}
        >
          {/* Online */}
          {driver.status}
        </Button>
      </Grid>

      <Grid item xs={12} mt={1} mb={1} mr={2}>
        <Grid container spacing={1}>
          <DriverDetailCard
            title="Today's Deliveries"
            heading="0 Orders"
            subTitle="+150%vs past month"
          />
          <DriverDetailCard
            title="Monthly Deliveries"
            heading="0 Orders"
            subTitle="+150%vs past month"
          />
          <DriverDetailCard
            title="Delivery Issues"
            heading="0 Reported"
            subTitle="+150%vs past month"
          />
          <DriverDetailCard
            title="Total Delivery"
            heading="0 Orders"
            subTitle="+150%vs past month"
          />
        </Grid>
      </Grid>

      {/* DRIVER DETAIL CARD */}
      <Grid item xs={12} md={6}>
        <Card
          sx={{
            borderRadius: 0,
            padding: "20px",
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            gap: "21px",
            position: "relative",
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              right: "20px",
              top: "18px",
              background: "rgba(104, 60, 140, 0.35)",
              borderRadius: 0,
              "&:hover": {
                background: "rgba(104, 60, 140, 0.35)",
              },
            }}
          >
            <img src={edit} />
          </IconButton>
          <Avatar
            variant="square"
            sx={{ width: "170px", height: "165px" }}
            src={profile}
          />
          <Box sx={{ width: "100%", marginTop: "-10px" }}>
            <Typography
              sx={{
                fontSize: "36px",
                color: "#000",
                fontWeight: 600,
              }}
            >
              {driver.name}
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                color: "#673C8E",
                marginTop: "-10px",
              }}
            >
              {driver.driverID}
            </Typography>
            <Box
              sx={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "end",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  justifyContent: "start",
                  gap: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <img src={map} />
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: "12px",
                      color: "rgba(23, 26, 31, 1)", // Return the value directly
                    }}
                  >
                    {driver.location}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <img src={email} />
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: "12px",
                      color: "rgba(23, 26, 31, 1)", // Return the value directly
                    }}
                  >
                    {driver.email}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <img src={call} />
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: "12px",
                      color: "rgba(23, 26, 31, 1)", // Return the value directly
                    }}
                  >
                    {driver.phone}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#000",
                  }}
                >
                  Rating
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "rgba(118, 158, 243, 1)",
                  }}
                >
                  4.7 Points
                </Typography>
              </Box>
            </Box>
          </Box>
        </Card>
      </Grid>

      {/* DRIVER HISTORY STEPPER */}
      <Grid item xs={12} md={6} mt={2}>
        <Typography
          sx={{
            fontSize: "20px",
            color: "rgba(104, 60, 140, 1)",
            fontWeight: 700,
            textAlign: "center",
            mb: 2,
          }}
        >
          Driver History
        </Typography>
        <Box
          sx={{
            border: "1px solid #673C8E",
            padding: "12px",
            marginTop: "12px",
            ml: 2,
            height: "auto",
            maxWidth: "100%",
            overflowX: "auto",
            marginRight: "14px",
          }}
        >
          <Stepper
            activeStep={3}
            orientation="horizontal"
            connector={<CustomStepConnector />}
          >
            {mergedSteps.map((step, index) => (
              <Step key={index} sx={{ minWidth: "120px", maxWidth: "150px" }}>
                {step.tooltip ? (
                  <CustomTooltip title={step.reason || "No reason provided"}>
                    <StepLabel
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      icon={step.icon}
                    >
                      <Typography
                        sx={{
                          textAlign: "center",
                          margin: "8px 0",
                        }}
                      >
                        {step.dot}
                      </Typography>
                      <Typography
                        sx={{
                          color: "rgba(104, 60, 140, 1)",
                          fontSize: "10px",
                          fontWeight: 700,
                          textAlign: "center",
                        }}
                      >
                        {step.label}
                      </Typography>
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: "10px",
                          textAlign: "center",
                        }}
                      >
                        {step.description}
                      </Typography>
                    </StepLabel>
                  </CustomTooltip>
                ) : (
                  <StepLabel
                    icon={step.icon}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: "center",
                        margin: "8px 0",
                      }}
                    >
                      {step.dot}
                    </Typography>
                    <Typography
                      sx={{
                        color: "rgba(104, 60, 140, 1)",
                        fontSize: "10px",
                        fontWeight: 700,
                        textAlign: "center",
                      }}
                    >
                      {step.label}
                    </Typography>
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "10px",
                        textAlign: "center",
                      }}
                    >
                      {step.description}
                    </Typography>
                  </StepLabel>
                )}
              </Step>
            ))}
          </Stepper>
        </Box>
      </Grid>

      {/* DRIVER PENDING CARD */}
      <Grid item xs={12} md={6} mt={1}>
        <Card sx={{ padding: "14px", borderRadius: 0 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                fontSize: "13px",
                color: "#000",
                fontWeight: 400,
              }}
            >
              Pending $200
            </Typography>
            <Box sx={{ textAlign: "right" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "rgba(255, 129, 73, 1)",
                  fontWeight: 400,
                }}
              >
                Next Payout 2 March 2O24
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  color: "#000",
                  fontWeight: 400,
                }}
              >
                Driving since : 24/5/24
              </Typography>
            </Box>
          </Box>

          <Typography
            sx={{
              fontSize: "15px",
              color: "rgba(103, 60, 142, 1)",
              fontWeight: 400,
              margin: "10px 0",
            }}
          >
            Work Information
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "13px",
                  color: "#000",
                  fontWeight: 400,
                }}
              >
                Total Hours
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  color: "rgba(189, 193, 202, 1)",
                  fontWeight: 400,
                }}
              >
                1382
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "13px",
                  color: "#000",
                  fontWeight: 400,
                }}
              >
                Avg.Monthly Online Hrs
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  color: "rgba(189, 193, 202, 1)",
                  fontWeight: 400,
                }}
              >
                66
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "13px",
                  color: "#000",
                  fontWeight: 400,
                }}
              >
                Total Paid till date
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  color: "rgba(189, 193, 202, 1)",
                  fontWeight: 400,
                }}
              >
                $47,223
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "13px",
                  color: "#000",
                  fontWeight: 400,
                }}
              >
                Last Payment
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  color: "rgba(189, 193, 202, 1)",
                  fontWeight: 400,
                }}
              >
                $1,233
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "13px",
                  color: "#000",
                  fontWeight: 400,
                }}
              >
                Pending Payment
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  color: "rgba(189, 193, 202, 1)",
                  fontWeight: 400,
                }}
              >
                $200
              </Typography>
            </Box>
          </Box>
        </Card>

        <Card sx={{ padding: "14px", borderRadius: 0, marginTop: "24px" }}>
          <Typography
            sx={{
              fontSize: "14px",
              color: "#000",
              fontWeight: 400,
              textAlign: "center",
            }}
          >
            Current Deliveries
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              color: "#673C8E",
              fontWeight: 400,
            }}
          >
            OID {currentOrder.id}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
              mb: 2,
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "13px",
                  color: "#000",
                  fontWeight: 400,
                }}
              >
                Total Pickups
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  color: "rgba(189, 193, 202, 1)",
                  fontWeight: 400,
                }}
              >
                {currentOrder.pickups} pickups
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "13px",
                  color: "#000",
                  fontWeight: 400,
                }}
              >
                Total Kms
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  color: "rgba(189, 193, 202, 1)",
                  fontWeight: 400,
                }}
              >
                {currentOrder.kms} Kms
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "13px",
                  color: "#000",
                  fontWeight: 400,
                }}
              >
                Average weight
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  color: "rgba(189, 193, 202, 1)",
                  fontWeight: 400,
                }}
              >
                {currentOrder.weight}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "13px",
                  color: "#000",
                  fontWeight: 400,
                }}
              >
                Total Value
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  color: "rgba(189, 193, 202, 1)",
                  fontWeight: 400,
                }}
              >
                {currentOrder.value}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "13px",
                  color: "#000",
                  fontWeight: 400,
                }}
              >
                Delivery type
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  color: "#FF8149",
                  fontWeight: 400,
                }}
              >
                {currentOrder.type}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "113px",
            }}
          >
            {currentOrder.mapComponent}
          </Box>
        </Card>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <CustomPagination
            count={3}
            rowsPerPage={1}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            total={orders.length}
            type="driverDetails"
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={6} mt={1}>
        <Card sx={{ borderRadius: 0, marginRight: "14px", ml: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "end",
              width: "100%",
              padding: "20px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "22px",
                  color: "rgba(103, 60, 142, 1)",
                  fontWeight: 600,
                }}
              >
                Order Details
              </Typography>
              <Typography
                sx={{
                  fontSize: "8px",
                  color: "#7C7D7F",
                  fontWeight: 400,
                }}
              >
                Lorem ipsum dolor sit amet, consectetur
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "rgba(103, 60, 142, 1)",
                  fontWeight: 700,
                  cursor: "pointer",
                  textTransform: "uppercase",
                }}
                onClick={handleViewAllClick}
              >
                VIEW All
              </Typography>
            </Box>
          </Box>

          <TableContainer
            sx={{
              height: "340px",
              maxWidth: "100vw",
              // boxShadow: (theme) => theme.shadows[5],
              borderRadius: 0,
              position: "relative", // Ensure that the container is positioned relatively
            }}
          >
            <Table
              // size="small"
              // aria-label="a dense table"
              sx={{ borderRadius: "0", height: "100%" }}
              stickyHeader
              // aria-label=" sticky table"
            >
              <TableHead>
                <TableRow>
                  {orderColumns?.map((column) => (
                    <TableCell
                      sx={{
                        background: "#683C8C",
                        color: "#fff",
                        cursor: "pointer",
                      }}
                      key={column.id}
                      align={column.align}
                      onClick={() => requestSort(column.id)}
                    >
                      {column.label}
                      {column.label != "Status" && (
                        <img
                          src={sort}
                          style={{
                            marginLeft: "10px",
                            width: "10px",
                            height: "10px",
                          }}
                        />
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {sortedRows.length === 0 ? (
                <TableBody>
                  <TableRow>
                    <TableCell
                      colSpan={orderColumns.length}
                      sx={{ borderBottom: "none", p: 0 }}
                    >
                      <Box
                        sx={{
                          height: "200px", // Ensure it matches the height of the container
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "16px", color: "rgba(0, 0, 0, 1)" }}
                        >
                          No orders
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {sortedRows?.map((row) => (
                    <TableRow
                      sx={{
                        cursor: "pointer",
                      }}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {orderColumns?.map((column) => {
                        const value = row[column.id];

                        return (
                          <TableCell
                            sx={{
                              color: "rgba(86, 93, 109, 1)",
                              fontSize: "12px",
                            }}
                            key={column.id}
                            align={column.align}
                          >
                            {column?.renderCell
                              ? column?.renderCell(row)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
      </Grid>
    </Grid>
  );
}
