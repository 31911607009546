import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  Backdrop,
  FormControl,
  Select,
  MenuItem,
  Grid,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ImageUploader } from "../ImageUploader";
import { $crud } from "../../utils/CrudFactory";
import { BASE_URL } from "../../constants";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backdropFilter: "blur(2px)",
  },
  dialogPaper: {
    borderRadius: "0",
    border: "1px solid #000",
  },
  textFieldRoot: {
    "& .MuiInputLabel-root": {
      color: "#8D8D8E !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(103, 60, 142, 1)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(103, 60, 142, 1)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(103, 60, 142, 1)",
      },
    },
  },
}));

const MoreWaysModal = ({ onClose, open, onSubmit, view, row, id }) => {
  const classes = useStyles();

  // State to manage file upload
  const [uploadedFile, setUploadedFile] = useState(null);
  const [imageSrc, setImageSrc] = useState("");

  // Function to handle file changes
  const handleFileChange = (file) => {
    setUploadedFile(file);
    const fileReader = new FileReader();
    fileReader.onload = (e) => setImageSrc(e.target.result);
    setFieldValue("image", file); // Update Formik field with the file object
    fileReader.readAsDataURL(file);
  };

  const initialValues = {
    id: id || "",
    title: "",
    why: "",
    pickup: "",
    image: "",
  };

  useEffect(() => {
    if (id != null) {
      setValues({
        ...row,
        id,
      });
      setImageSrc(`${BASE_URL}moreWays/${row.image}`);
    }
  }, [id]);

  //   const validationSchema = Yup.object({
  //     title: Yup.string().required("Required!"),
  //     subTitle: Yup.string().required("Required!"),
  //     description: Yup.string().required("Required!"),
  //   });

  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues,
    onSubmit,
    // validationSchema,
  });

  const handleOfferSubmit = async () => {
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("why", values.why);
    formData.append("pickup", values.pickup);
    if (id) {
      formData.append("id", id);
    }
    if (uploadedFile) {
      formData.append("image", values.image);
    }

    try {
      const { data } = id
        ? await $crud.put(`admin/more-ways`, formData)
        : await $crud.post(`admin/more-ways`, formData);

      if (data) {
        onClose();
        // Navigate after success
        // navigate("/services/regular-offers");
      }
    } catch (err) {
      // No need for error handling here, as it’s already handled by $crud.send
      console.log("more ways submission failed: ", err.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Backdrop className={classes.backdrop} open={open} />
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogContent sx={{ marginTop: "30px", padding: "0 40px" }}>
          <Typography
            id="modal-title"
            sx={{
              fontSize: "20px",
              mb: 2,
              textAlign: "center",
            }}
            gutterBottom
          >
            {view ? "View " : id ? "Update " : "Add "} More ways to use
          </Typography>

          <Grid container spacing={2}>
            {/* Title and Subtitle Fields */}
            <Grid item xs={12} md={6}>
              <Typography sx={{ fontSize: "18px", marginBottom: "2px" }}>
                Event Name
              </Typography>
              <TextField
                name="title"
                placeholder="PLAN A SURPRISE BIRTHDAY"
                sx={{
                  color: "#1C1B1F",
                  mb: 1,
                  "& .MuiInputLabel-root": {
                    color: "#1C1B1F !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: (theme) => theme.palette.text.secondary,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#1C1B1F",
                    },
                  },
                }}
                disabled={view}
                size="small"
                fullWidth
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                error={touched.title && !!errors.title}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ fontSize: "18px", marginBottom: "2px" }}>
                Why ?
              </Typography>
              <TextField
                name="why"
                placeholder="| Living far | Living abroad | Don’t have time | Surprise | Helping someone | Not in best health |"
                sx={{
                  color: "#1C1B1F",
                  mb: 1,
                  "& .MuiInputLabel-root": {
                    color: "#1C1B1F !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: (theme) => theme.palette.text.secondary,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#1C1B1F",
                    },
                  },
                }}
                disabled={view}
                size="small"
                fullWidth
                value={values.why}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                error={touched.why && !!errors.why}
              />
            </Grid>

            {/* CKEditor for Description */}
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "18px", marginBottom: "2px" }}>
                Details (bullet points)
              </Typography>
              <CKEditor
                disabled={view}
                editor={ClassicEditor}
                data={values.pickup}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setFieldValue("pickup", data); // Update state with editor content only if not in view mode
                }}
                config={{
                  toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "underline",
                    "strikethrough",
                    "fontSize",
                    "|",
                    "alignment",
                    "bulletedList",
                    "numberedList",
                    "indent",
                    "outdent",
                    "|",
                    "link",
                    "blockQuote",
                    "insertTable",
                    "mediaEmbed",
                    "imageUpload",
                    "undo",
                    "redo",
                    "findAndReplace",
                    "highlight",
                    "removeFormat",
                    "|",
                    "sourceEditing",
                    "specialCharacters",
                    "code",
                  ],
                  image: {
                    toolbar: [
                      "imageTextAlternative",
                      "imageStyle:full",
                      "imageStyle:side",
                    ],
                  },
                  table: {
                    contentToolbar: [
                      "tableColumn",
                      "tableRow",
                      "mergeTableCells",
                      "tableProperties",
                      "tableCellProperties",
                    ],
                  },
                }}
              />
            </Grid>

            {/* File Upload Section */}
            <Grid item xs={12} md={6}>
              <Typography sx={{ fontSize: "18px", marginBottom: "2px" }}>
                Upload Image
              </Typography>
              <ImageUploader
                name="image"
                onChange={handleFileChange}
                id="image-upload"
                file={uploadedFile}
                src={imageSrc}
                required
                accept="image/*"
              />
            </Grid>
          </Grid>

          <Box
            sx={{
              marginTop: "47px",
              marginBottom: "38px",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              sx={{
                borderColor: "#683C8C",
                color: "#683C8C",
                padding: "6px 40px",
                fontSize: "15px",
                fontWeight: 400,
                textTransform: "capitalize",
                "&:hover": {
                  borderColor: "#683C8C",
                  backgroundColor: "transparent",
                  color: "#683C8C",
                },
                mr: 1,
              }}
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              disabled={view} // Disable button if view is true
              sx={{
                backgroundColor: "#683C8C",
                color: "#FFFFFF",
                padding: "6px 40px",
                fontSize: "15px",
                fontWeight: 400,
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#683C8C",
                  color: "#FFFFFF",
                },
              }}
              variant="contained"
              onClick={handleOfferSubmit}
            >
              {id ? "Update" : "Submit"}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </form>
  );
};

export default MoreWaysModal;
