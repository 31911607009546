import React from "react";
import "./App.css";
import Router from "./routers/Router";
import { SnackbarProvider } from "notistack";
import { Button, CssBaseline, ThemeProvider } from "@mui/material";
import { useThemeContext } from "./theme/ThemeContextProvider";

function App() {
  const snackbarProvider = React.useRef();
  const { theme } = useThemeContext();  // Use the theme from context

  return (
    <SnackbarProvider
      ref={snackbarProvider}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      maxSnack={3}
      action={(snackbarId) => (
        <Button
          size="small"
          color="inherit"
          onClick={() => snackbarProvider.current.closeSnackbar(snackbarId)}
        >
          Dismiss
        </Button>
      )}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router />
      </ThemeProvider>
    </SnackbarProvider>
  );
}

export default App;
