import React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Button,
  Dialog,
  DialogContent,
  Typography,
  useTheme,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";

const RetrieveAccountModal = ({
  archiveCard,
  archiveConfirmCard,
  isCheckArchive,
  setIsCheckArchive,
  handleRetrieveAccount,
  setArchiveCard,
  setArchiveConfirmCard,
  handleArchiveAccount,
  deleteAccountDate,
  theme,
  navigate,
}) => {
  return (
    <Dialog
      open={archiveCard || archiveConfirmCard}
      onClose={() => {}}
      disableBackdropClick
      disableEscapeKeyDown
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: 0,
          border: theme.palette.border.primary,
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "400px",
          width: "650px",
          boxShadow: theme.palette.boxShadow.primary,
        },
      }}
    >
      <DialogContent>
        {archiveCard && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                color: theme.palette.text.primary,
                fontSize: "30px",
                fontWeight: 700,
              }}
            >
              Account Archived
            </Typography>
            <Typography
              sx={{
                color: theme.palette.text.secondary,
                fontSize: "15px",
                fontWeight: 400,
                textAlign: "center",
              }}
            >
              Your account has been archived and we will delete it in 7 days by{" "}
              {deleteAccountDate}
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                flexDirection: "column",
                gap: 2,
                marginTop: "20px",
              }}
            >
              <Button
                onClick={() => {
                  setArchiveConfirmCard(true);
                  setArchiveCard(false);
                }}
                variant="contained"
                size="small"
                type="submit"
                sx={{
                  borderColor: "#673C8E",
                  background: "#673C8E",
                  color: "#fff",
                  padding: "10px 30px",
                  fontSize: "14px",
                  borderRadius: "10px",
                  textTransform: "capitalize",
                  "&:hover": {
                    background: "#673C8E",
                    borderColor: "#673C8E",
                    color: "#fff",
                  },
                }}
              >
                Retrieve Account
              </Button>
              <Button
                onClick={() => {
                  localStorage.clear();
                  navigate("/login");
                  enqueueSnackbar("Logged Out Successfully.", {
                    variant: "success",
                  });
                }}
                variant="contained"
                size="small"
                type="submit"
                sx={{
                  background: "#fff",
                  color: "#673C8E",
                  padding: "10px 65px",
                  fontSize: "14px",
                  borderRadius: "10px",
                  textTransform: "capitalize",
                  "&:hover": {
                    background: "#fff",
                    color: "#673C8E",
                  },
                }}
              >
                Logout
              </Button>
            </Box>
          </Box>
        )}

        {archiveConfirmCard && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={isCheckArchive}
                  onChange={(e) => setIsCheckArchive(e.target.checked)}
                  sx={{
                    color: "#683C8C",
                    "&.Mui-checked": {
                      color: "#683C8C",
                    },
                  }}
                />
              }
              sx={{
                "& .MuiFormControlLabel-label": {
                  color: "#683C8C",
                  fontSize: "20px",
                },
              }}
              label="Yes, please retrieve my account."
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: 2,
                marginTop: "20px",
              }}
            >
              <Button
                onClick={handleRetrieveAccount}
                variant="contained"
                size="small"
                type="submit"
                sx={{
                  background: "#673C8E",
                  color: "#fff",
                  padding: "10px 30px",
                  fontSize: "14px",
                  borderRadius: "10px",
                  textTransform: "capitalize",
                  "&:hover": {
                    background: "#673C8E",
                    color: "#fff",
                  },
                }}
              >
                Retrieve
              </Button>
              <Button
                onClick={() => {
                  setArchiveCard(true);
                  setArchiveConfirmCard(false);
                }}
                variant="outlined"
                size="small"
                type="submit"
                sx={{
                  borderColor: "#673C8E",
                  color: "#673C8E",
                  padding: "10px 30px",
                  fontSize: "14px",
                  borderRadius: "10px",
                  textTransform: "capitalize",
                  "&:hover": {
                    borderColor: "#673C8E",
                    color: "#673C8E",
                  },
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default RetrieveAccountModal;
