import * as React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent'

export function Modal(props) {
    const {open, onClose} = props

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogContent>
                {props.children}
            </DialogContent>
        </Dialog>
    );
};





