// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 8px; /* Reduced width for vertical scrollbar */
  height: 8px; /* Reduced height for horizontal scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(104, 60, 140, 0.06);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #673c8e;
  border-radius: 6px;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #683c8c0f;
} */

.swiper {
  width: 100%;
  height: 100%;
  overflow: hidden !important;
}

.hidden-otp {
  -webkit-text-security: disc; /* For Chrome, Safari, Edge */
  text-security: disc; /* For Firefox */
}

.highlight {
  background-color: yellow;
  font-weight: bold;
  color: black;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA,UAAU;AACV;EACE,UAAU,EAAE,yCAAyC;EACrD,WAAW,EAAE,4CAA4C;AAC3D;;AAEA,UAAU;AACV;EACE,oCAAoC;AACtC;;AAEA,WAAW;AACX;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA,oBAAoB;AACpB;;GAEG;;AAEH;EACE,WAAW;EACX,YAAY;EACZ,2BAA2B;AAC7B;;AAEA;EACE,2BAA2B,EAAE,6BAA6B;EAC1D,mBAAmB,EAAE,gBAAgB;AACvC;;AAEA;EACE,wBAAwB;EACxB,iBAAiB;EACjB,YAAY;AACd","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\n/* width */\n::-webkit-scrollbar {\n  width: 8px; /* Reduced width for vertical scrollbar */\n  height: 8px; /* Reduced height for horizontal scrollbar */\n}\n\n/* Track */\n::-webkit-scrollbar-track {\n  background: rgba(104, 60, 140, 0.06);\n}\n\n/* Handle */\n::-webkit-scrollbar-thumb {\n  background: #673c8e;\n  border-radius: 6px;\n}\n\n/* Handle on hover */\n/* ::-webkit-scrollbar-thumb:hover {\n  background: #683c8c0f;\n} */\n\n.swiper {\n  width: 100%;\n  height: 100%;\n  overflow: hidden !important;\n}\n\n.hidden-otp {\n  -webkit-text-security: disc; /* For Chrome, Safari, Edge */\n  text-security: disc; /* For Firefox */\n}\n\n.highlight {\n  background-color: yellow;\n  font-weight: bold;\n  color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
