import { Box, Card, Grid, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import ExecutiveStatements from "./ExecutiveStatements";
import DriverStatements from "./DriverStatements";
import ClientStatements from "./ClientStatements";

export function Statements() {
  const [value, setValue] = useState(2);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Grid container sx={{ padding: "0 18px" }}>
      <Grid item xs={12} md={4}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 700,
            fontSize: "30px",
            color: "#673C8E", // Return the value directly
          }}
        >
          Statements
        </Typography>
      </Grid>
      <Grid xs={12} md={6} sx={{ textAlign: "center" }}>
        <Box
          sx={{
            width: "46%",
            bgcolor: "rgba(104, 60, 140, 0.06)",
            borderRadius: "8px",
            padding: "8px 10px",
            minHeight: "60px",
            maxHeight: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            centered
            sx={{
              "& .MuiTabs-indicator": {
                display: "none",
              },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Tab
              label="Executive"
              sx={{
                textTransform: "capitalize",
                color: value === 0 ? "#fff" : "#000",
                fontSize: "14px",
                fontWeight: 700,
                backgroundColor: value === 0 ? "#673C8E" : "transparent",
                borderBottom: "none",
                borderRadius: "16px",
                minWidth: "87px",
                width: "87px",
                maxWidth: "87px",
                minHeight: "34px",
                height: "34px",
                maxHeight: "34px",
              }}
            />
            <Tab
              label="Drivers"
              sx={{
                textTransform: "capitalize",
                color: value === 1 ? "#fff" : "#000",
                fontSize: "14px",
                fontWeight: 700,
                backgroundColor: value === 1 ? "#673C8E" : "transparent",
                borderBottom: "none",
                borderRadius: "16px",
                minWidth: "67px",
                width: "67px",
                maxWidth: "67px",
                minHeight: "34px",
                height: "34px",
                maxHeight: "34px",
              }}
            />
            <Tab
              label="Clients"
              sx={{
                textTransform: "capitalize",
                color: value === 2 ? "#fff" : "#000",
                fontSize: "14px",
                fontWeight: 700,
                backgroundColor: value === 2 ? "#673C8E" : "transparent",
                borderBottom: "none",
                borderRadius: "16px",
                minWidth: "67px",
                width: "67px",
                maxWidth: "67px",
                minHeight: "34px",
                height: "34px",
                maxHeight: "34px",
              }}
            />
          </Tabs>
        </Box>
      </Grid>

      {value === 0 && <ExecutiveStatements />}
      {value === 1 && <DriverStatements />}
      {value === 2 && <ClientStatements />}
    </Grid>
  );
}
