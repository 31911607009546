import React from "react";
import {
  Button,
  Box,
  Grid,
  Typography,
  TextField,
  IconButton,
  Card,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  Avatar,
} from "@mui/material";
import { FileDownloadOutlined, MoreVert } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import OrderCard from "../../components/cards/OrderCard";
import homeImg from "../../assets/images/homeImg.svg";
import phone from "../../assets/images/svg/phone.svg";
import chat from "../../assets/images/svg/chat2.svg";
import calendarDashboard from "../../assets/images/svg/calendarDashboard.svg";
import DriversBarChart from "../../components/customCharts/DriversBarChart";
import TotalRevenueLineChart from "../../components/customCharts/TotalRevenueLineChart";
import CustomerBarChart from "../../components/customCharts/CustomerBarChart";
import ReviewCarousel from "../../components/carousels/ReviewCarousel";
import PieChart from "../../components/customCharts/PieChart";

export function Dashboard({ theme }) {
  const [customerMap, setCustomerMap] = React.useState("week");

  const handleChange = (event) => {
    setCustomerMap(event.target.value);
  };
  return (
    <Grid
      container
      sx={{
        padding: "0 18px",
      }}
      spacing={2}
    >
      <Grid item xs={12} md={6}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 700,
            fontSize: "30px",
            color: "#673C8E", // Return the value directly
          }}
        >
          Home
        </Typography>
        <Typography sx={{ color: "#A3A3A3", fontSize: "18px" }}>
          Hi, Example. Welcome back to Vpickup Admin!
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        display="flex"
        justifyContent="end"
        gap={2}
        alignItems="center"
      >
        <Box
          sx={{
            width: "38%",
            paddingLeft: "6px",
            display: "flex",
            borderRadius: "4px",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 1,
            // border: "2px solid rgba(103, 60, 142, 1)",
          }}
        >
          <Typography
            sx={{ color: "#673C8E", fontSize: "16px", fontWeight: 400 }}
          >
            From
          </Typography>
          <LocalizationProvider
            sx={{
              "& .PrivatePickersPopperRoot-popupIndicator": {
                display: "none",
              },
            }}
            dateAdapter={AdapterDayjs}
          >
            <DatePicker
              // value={filtrationData?.from ? dayjs(filtrationData.from) : null}
              // onChange={(newValue) => {
              //   const formattedDate = dayjs(newValue).toISOString();
              //   setFiltrationData({
              //     ...filtrationData,
              //     from: formattedDate,
              //   });
              //   // handleFilterChange("from", formattedDate);
              // }}
              PopoverProps={{ disableEnforceFocus: true }} // Disable enforcing focus
              sx={{
                "& .MuiInputBase-input": {
                  padding: "7px",
                  color: "#673C8E",
                  borderRadius: "14px",
                  border: "none", // Remove the border from the input
                },
                "& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root": {
                  color: "#673C8E", // Change the color of the icon button
                },
                "& .MuiOutlinedInput-root": {
                  // borderRadius: "14px",
                },
                "& .MuiOutlinedInput-root fieldset": {
                  // border: "none",
                  border: "2px solid rgba(103, 60, 142, 1)",
                },
                "& .MuiOutlinedInput-root fieldset:hover": {
                  borderColor: "#673C8E", // Change border color on hover
                },
                "& .MuiOutlinedInput-root.Mui-focused fieldset": {
                  borderColor: "#673C8E", // Change border color when focused
                },
              }}
              renderInput={(params) => (
                <TextField placeholder="Select Date" {...params} />
              )}
            />
          </LocalizationProvider>
        </Box>
        <Box
          sx={{
            width: "35%",
            paddingLeft: "6px",
            display: "flex",
            borderRadius: "4px",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 1,
            // border: "2px solid rgba(103, 60, 142, 1)",
          }}
        >
          <Typography
            sx={{ color: "#673C8E", fontSize: "16px", fontWeight: 400 }}
          >
            To
          </Typography>
          <LocalizationProvider
            sx={{
              "& .PrivatePickersPopperRoot-popupIndicator": {
                display: "none",
              },
            }}
            dateAdapter={AdapterDayjs}
          >
            <DatePicker
              // value={filtrationData?.to ? dayjs(filtrationData.to) : null}
              // onChange={(newValue) => {
              //   const formattedDate = dayjs(newValue).toISOString();
              //   setFiltrationData({
              //     ...filtrationData,
              //     to: formattedDate,
              //   });
              //   // handleFilterChange("from", formattedDate);
              // }}
              PopoverProps={{ disableEnforceFocus: true }} // Disable enforcing focus
              sx={{
                "& .MuiInputBase-input": {
                  padding: "7px",
                  color: "#673C8E",
                  borderRadius: "14px",
                  border: "none", // Remove the border from the input
                },
                "& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root": {
                  color: "#673C8E", // Change the color of the icon button
                },
                "& .MuiOutlinedInput-root": {
                  // borderRadius: "14px",
                },
                "& .MuiOutlinedInput-root fieldset": {
                  // border: "none",
                  border: "2px solid rgba(103, 60, 142, 1)",
                },
                "& .MuiOutlinedInput-root fieldset:hover": {
                  borderColor: "#673C8E", // Change border color on hover
                },
                "& .MuiOutlinedInput-root.Mui-focused fieldset": {
                  borderColor: "#673C8E", // Change border color when focused
                },
              }}
              renderInput={(params) => (
                <TextField placeholder="Select Date" {...params} />
              )}
            />
          </LocalizationProvider>
        </Box>
      </Grid>

      {/* ----------------------------- REGULAR ORDERS --------------------------------- */}
      <Grid item xs={12} sx={{ paddingTop: "45px" }}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            fontSize: "32px",
            color: "#673C8E", // Return the value directly
            marginBottom: "20px",
          }}
        >
          Regular Orders
        </Typography>
        <Grid container spacing={2}>
          {/* ================================== REGULAR ORDER CARDS ================================ */}
          <OrderCard title="Total Orders" count="0" />
          <OrderCard title="Total Delivered" count="0" />
          <OrderCard title="Total Canceled" count="0" />
          <OrderCard title="Total Revenue" count="0" />
          <OrderCard title="Total Drivers" count="0" />
          {/* ================================== REGULAR ORDER CARDS ================================ */}
        </Grid>
      </Grid>
      {/* ----------------------------- REGULAR ORDERS --------------------------------- */}

      {/* --------------------------- SPECIAL ORDERS ----------------------------- */}
      <Grid item xs={12} sx={{ paddingTop: "45px", marginBottom: "20px" }}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            fontSize: "32px",
            color: "#673C8E", // Return the value directly
            marginBottom: "20px",
          }}
        >
          Special Orders
        </Typography>
        <Grid container spacing={2}>
          {/* ================================ SPECIAL ORDER CARDS ================================== */}
          <OrderCard title="Total Orders" count="0" />
          <OrderCard title="Total Delivered" count="0" />
          <OrderCard title="Total Canceled" count="0" />
          <OrderCard title="Total Revenue" count="0" />
          <OrderCard title="Total Drivers" count="0" />
          {/* ================================ SPECIAL ORDER CARDS ================================== */}
        </Grid>
      </Grid>
      {/* --------------------------- SPECIAL ORDERS ----------------------------- */}

      {/* -------------------------------- WEEKLY PERFORMANCE OF DRIVERS ---------------------------------------- */}
      <Grid xs={12} lg={6} item>
        <Card sx={{ padding: "16px 20px", borderRadius: "14px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                color: "#673C8E", // Return the value directly
                // marginBottom: "20px",
              }}
            >
              Weekly Performance of Drivers
            </Typography>
            <IconButton>
              <img src={calendarDashboard} />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "29px",
                color: "#673C8E", // Return the value directly
                // marginBottom: "20px",
              }}
            >
              95.6
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "10px",
                color: "rgba(104, 60, 140, 0.35)", // Return the value directly
                // marginBottom: "20px",
              }}
            >
              Excellent <br /> Performance
            </Typography>
          </Box>
          <Box>
            <DriversBarChart />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "9px",
                  color: "rgba(103, 60, 142, 1)", // Return the value directly
                  // marginBottom: "20px",
                }}
              >
                Given Orders
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "11px",
                  color: "rgba(103, 60, 142, 1)", // Return the value directly
                  // marginBottom: "20px",
                }}
              >
                Completed Orders
              </Typography>
            </Box>
          </Box>
          {/* <Box sx={{ textAlign: "center", margin: "50px 0" }}>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                color: "#673C8E", // Return the value directly
                // marginBottom: "20px",
              }}
            >
              No data Yet!
            </Typography>
          </Box> */}
        </Card>
      </Grid>
      {/* -------------------------------- WEEKLY PERFORMANCE OF DRIVERS ---------------------------------------- */}

      {/* --------------------------------------- PIE CHART -------------------------------------- */}
      <Grid xs={12} lg={6} item>
        <Card sx={{ padding: "16px 20px", borderRadius: "14px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "20px",
                color: "#673C8E", // Return the value directly
                // marginBottom: "20px",
              }}
            >
              Pie Chart
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      // onChange={(e) => setIsChecked(e.target.checked)}
                      sx={{
                        color: "#683C8C",
                        "&.Mui-checked": {
                          color: "#683C8C",
                        },
                      }}
                    />
                  }
                  label="Chart"
                />
              </Box>
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      // onChange={(e) => setIsChecked(e.target.checked)}
                      sx={{
                        color: "#683C8C",
                        "&.Mui-checked": {
                          color: "#683C8C",
                        },
                      }}
                      defaultChecked
                    />
                  }
                  label="Show Value"
                />
              </Box>
              <IconButton>
                <MoreVert sx={{ color: "#673C8E" }} />
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
              marginBottom: "16px",
            }}
          >
            <PieChart percentage={81} title="Customer Growth" />
            <PieChart percentage={22} title="Drivers Growth" />
            <PieChart percentage={62} title="Revenue Growth" />
          </Box>
          {/* <Box sx={{ textAlign: "center", margin: "115px 0" }}>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                color: "#673C8E", // Return the value directly
                // marginBottom: "20px",
              }}
            >
              No data Yet!
            </Typography>
          </Box> */}
        </Card>
      </Grid>
      {/* --------------------------------------- PIE CHART -------------------------------------- */}

      {/* ---------------------------------------- TOTAL REVENUE --------------------------------------- */}
      <Grid xs={12} lg={6} item>
        <Card sx={{ padding: "16px 20px", borderRadius: "14px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "20px",
                color: "#673C8E", // Return the value directly
                // marginBottom: "20px",
              }}
            >
              Total Revenue
            </Typography>
            <RadioGroup row defaultValue="2024">
              <FormControlLabel
                value="2023"
                control={
                  <Radio
                    sx={{
                      color: "rgba(104, 60, 140, 1)",
                      "&.Mui-checked": {
                        color: "rgba(104, 60, 140, 1)",
                      },
                    }}
                  />
                }
                label="2023"
              />
              <FormControlLabel
                value="2024"
                control={
                  <Radio
                    sx={{
                      color: "rgba(104, 60, 140, 1)",
                      "&.Mui-checked": {
                        color: "rgba(104, 60, 140, 1)",
                      },
                    }}
                  />
                }
                label="2024"
              />
            </RadioGroup>
          </Box>
          <Box sx={{ overflowX: "scroll", overflowY: "hidden" }}>
            <TotalRevenueLineChart />
          </Box>
          {/* <Box sx={{ textAlign: "center", margin: "50px 0" }}>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                color: "#673C8E", // Return the value directly
                // marginBottom: "20px",
              }}
            >
              No data Yet!
            </Typography>
          </Box> */}
        </Card>
      </Grid>
      {/* ---------------------------------------- TOTAL REVENUE --------------------------------------- */}

      {/* ------------------------------------------ CUSTOMER MAP ---------------------------------------- */}
      <Grid xs={12} lg={3.5} item>
        <Card sx={{ padding: "12px", borderRadius: "14px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "20px",
                color: "#673C8E", // Return the value directly
                // marginBottom: "20px",
              }}
            >
              Customer Map
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <FormControl fullWidth sx={{ border: "none !important" }}>
                <Select
                  sx={{
                    // border: "1px solid #683C8C",
                    color: "#000",
                    border: "none !important",
                    outline: "none !important", // Remove outline
                    "& .MuiSelect-icon": {
                      color: "#683C8C", // Hide the down arrow icon
                    },
                    "&:hover": {
                      borderColor: "transparent !important", // Remove hover background
                    },
                    "&:focus": {
                      borderColor: "transparent !important", // Remove focus background
                    },
                    "& .MuiSelect-select": {
                      // paddingRight: "32px !important",
                      padding: "4px",
                      border: "2px solid #683C8C",
                      borderRadius: "12px",
                      width: "60px",
                      height: "18px",
                    },
                    "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                      "&:hover": {
                        borderColor: "transparent",
                      },
                      "&:focus": {
                        borderColor: "transparent",
                      },
                    },
                    "&.css-c1gf8l-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover":
                      {
                        border: "none",
                        outline: "none",
                      },
                    // "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    //   borderColor: "transparent",
                    // },
                  }}
                  value={customerMap}
                  onChange={handleChange}
                >
                  <MenuItem value="week">Weekly</MenuItem>
                  <MenuItem value="month">Monthly</MenuItem>
                  <MenuItem value="year">Yearly</MenuItem>
                </Select>
              </FormControl>
              <IconButton>
                <MoreVert sx={{ color: "#673C8E" }} />
              </IconButton>
            </Box>
          </Box>
          <Box>
            <CustomerBarChart />
          </Box>
          {/* <Box sx={{ textAlign: "center", margin: "50px 0" }}>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                color: "#673C8E", // Return the value directly
                // marginBottom: "20px",
              }}
            >
              No data Yet!
            </Typography>
          </Box> */}
        </Card>
      </Grid>
      {/* ------------------------------------------ CUSTOMER MAP ---------------------------------------- */}

      {/* ----------------------------------------- LIVE CHATS ----------------------------------------------- */}
      <Grid xs={12} lg={2.5} item>
        <Card sx={{ padding: "8px", borderRadius: "14px" }}>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: 700,
              fontSize: "20px",
              color: "#673C8E", // Return the value directly
              mb: 2,
            }}
          >
            Current Chats
          </Typography>
          <Box
            sx={{ overflowY: "scroll", overflowX: "hidden", height: "176px" }}
          >
            <Box
              sx={{
                // padding: "4px",
                // border: "1px solid rgba(104, 60, 140, 0.06)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 2,
                mb: 1,
              }}
            >
              <Avatar
                sx={{ width: 50, height: 50 }}
                alt="Remy Sharp"
                src="/static/images/avatar/1.jpg"
              />
              <Box>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "12px",
                    color: "#1A1D1F", // Return the value directly
                  }}
                >
                  Domenica{" "}
                  <span style={{ color: "#9A9FA5" }}>ID : 27295602</span>
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "8px",
                    color: "#1A1D1F", // Return the value directly
                    // marginBottom: "20px",
                  }}
                >
                  “Great work, I just purchased this product” <br />{" "}
                  <b>Reply</b>
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                // padding: "4px",
                // border: "1px solid rgba(104, 60, 140, 0.06)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 2,
                mb: 1,
              }}
            >
              <Avatar
                sx={{ width: 50, height: 50 }}
                alt="Remy Sharp"
                src="/static/images/avatar/1.jpg"
              />
              <Box>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "12px",
                    color: "#1A1D1F", // Return the value directly
                  }}
                >
                  Domenica{" "}
                  <span style={{ color: "#9A9FA5" }}>ID : 27295602</span>
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "8px",
                    color: "#1A1D1F", // Return the value directly
                    // marginBottom: "20px",
                  }}
                >
                  “Great work, I just purchased this product” <br />{" "}
                  <b>Reply</b>
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                // padding: "4px",
                // border: "1px solid rgba(104, 60, 140, 0.06)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 2,
                mb: 1,
              }}
            >
              <Avatar
                sx={{ width: 50, height: 50 }}
                alt="Remy Sharp"
                src="/static/images/avatar/1.jpg"
              />
              <Box>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "12px",
                    color: "#1A1D1F", // Return the value directly
                  }}
                >
                  Domenica{" "}
                  <span style={{ color: "#9A9FA5" }}>ID : 27295602</span>
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "8px",
                    color: "#1A1D1F", // Return the value directly
                    // marginBottom: "20px",
                  }}
                >
                  “Great work, I just purchased this product” <br />{" "}
                  <b>Reply</b>
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                // padding: "4px",
                // border: "1px solid rgba(104, 60, 140, 0.06)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 2,
                mb: 1,
              }}
            >
              <Avatar
                sx={{ width: 50, height: 50 }}
                alt="Remy Sharp"
                src="/static/images/avatar/1.jpg"
              />
              <Box>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "12px",
                    color: "#1A1D1F", // Return the value directly
                  }}
                >
                  Domenica{" "}
                  <span style={{ color: "#9A9FA5" }}>ID : 27295602</span>
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "8px",
                    color: "#1A1D1F", // Return the value directly
                    // marginBottom: "20px",
                  }}
                >
                  “Great work, I just purchased this product” <br />{" "}
                  <b>Reply</b>
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                // padding: "4px",
                // border: "1px solid rgba(104, 60, 140, 0.06)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 2,
                mb: 1,
              }}
            >
              <Avatar
                sx={{ width: 50, height: 50 }}
                alt="Remy Sharp"
                src="/static/images/avatar/1.jpg"
              />
              <Box>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "12px",
                    color: "#1A1D1F", // Return the value directly
                  }}
                >
                  Domenica{" "}
                  <span style={{ color: "#9A9FA5" }}>ID : 27295602</span>
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "8px",
                    color: "#1A1D1F", // Return the value directly
                    // marginBottom: "20px",
                  }}
                >
                  “Great work, I just purchased this product” <br />{" "}
                  <b>Reply</b>
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* <Box sx={{ textAlign: "center", margin: "50px 0" }}>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                color: "#673C8E", // Return the value directly
                // marginBottom: "20px",
              }}
            >
              No data Yet!
            </Typography>
          </Box> */}
        </Card>
      </Grid>
      {/* ----------------------------------------- LIVE CHATS ----------------------------------------------- */}

      {/* ---------------------------------------------- -ACTIVE DRIVERS ------------------------------------------------- */}
      <Grid xs={12} lg={4.75} item>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "20px",
              color: "#673C8E", // Return the value directly
              // marginBottom: "20px",
            }}
          >
            Active Drivers
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "12px",
              color: "#673C8E", // Return the value directly
              cursor: "pointer",
            }}
          >
            See all
          </Typography>
        </Box>
        <Card sx={{ borderRadius: "8px" }}>
          <Box
            sx={{
              padding: "20px",
              border: "1px solid rgba(104, 60, 140, 0.06)",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Avatar
              sx={{ width: 50, height: 50 }}
              alt="Remy Sharp"
              src="/static/images/avatar/1.jpg"
            />
            <Box>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "15px",
                  color: "#171A1F", // Return the value directly
                }}
              >
                Robert Downey
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "11px",
                  color: "#BDC1CA", // Return the value directly
                  // marginBottom: "20px",
                }}
              >
                Truck Driver
              </Typography>
            </Box>
            <IconButton>
              <img src={phone} />
            </IconButton>
            <IconButton>
              <img src={chat} />
            </IconButton>
          </Box>
          <Box
            sx={{
              padding: "20px",
              border: "1px solid rgba(104, 60, 140, 0.06)",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{ width: 50, height: 50 }}
              alt="Remy Sharp"
              src="/static/images/avatar/1.jpg"
            />
            <Box>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "15px",
                  color: "#171A1F", // Return the value directly
                }}
              >
                Robert Downey
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "11px",
                  color: "#BDC1CA", // Return the value directly
                  // marginBottom: "20px",
                }}
              >
                Truck Driver
              </Typography>
            </Box>
            <IconButton>
              <img src={phone} />
            </IconButton>
            <IconButton>
              <img src={chat} />
            </IconButton>
          </Box>
          <Box
            sx={{
              padding: "20px",
              border: "1px solid rgba(104, 60, 140, 0.06)",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{ width: 50, height: 50 }}
              alt="Remy Sharp"
              src="/static/images/avatar/1.jpg"
            />
            <Box>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "15px",
                  color: "#171A1F", // Return the value directly
                }}
              >
                Robert Downey
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "11px",
                  color: "#BDC1CA", // Return the value directly
                  // marginBottom: "20px",
                }}
              >
                Truck Driver
              </Typography>
            </Box>
            <IconButton>
              <img src={phone} />
            </IconButton>
            <IconButton>
              <img src={chat} />
            </IconButton>
          </Box>
          {/* <Box sx={{ textAlign: "center", margin: "50px 0" }}>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                color: "#673C8E", // Return the value directly
                // marginBottom: "20px",
              }}
            >
              No data Yet!
            </Typography>
          </Box> */}
        </Card>
      </Grid>
      {/* ---------------------------------------------- -ACTIVE DRIVERS ------------------------------------------------- */}

      {/* ------------------------------------------ LATEST ORDERS ------------------------------------------------ */}
      <Grid xs={12} lg={4.75} item>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "20px",
              color: "#673C8E", // Return the value directly
              // marginBottom: "20px",
            }}
          >
            Latest Orders
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "12px",
              color: "#673C8E", // Return the value directly
              cursor: "pointer",
            }}
          >
            See all
          </Typography>
        </Box>
        <Card sx={{ borderRadius: "8px" }}>
          <Box
            sx={{
              padding: "14px",
              border: "1px solid rgba(104, 60, 140, 0.06)",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Avatar
              sx={{ width: 50, height: 50 }}
              alt="Remy Sharp"
              src="/static/images/avatar/1.jpg"
            />
            <Box>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "#171A1F", // Return the value directly
                }}
              >
                Order Number 36-Williams Street
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "#171A1F", // Return the value directly
                  // marginBottom: "20px",
                }}
              >
                09:00 AM to 9:00 PM
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <MoreVert sx={{ color: "#673C8E" }} />
              </IconButton>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "11px",
                  color: "#171A1F", // Return the value directly
                  // marginBottom: "20px",
                }}
              >
                2 pickups
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              padding: "16px",
              border: "1px solid rgba(104, 60, 140, 0.06)",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Avatar
              sx={{ width: 50, height: 50 }}
              alt="Remy Sharp"
              src="/static/images/avatar/1.jpg"
            />
            <Box>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "#171A1F", // Return the value directly
                }}
              >
                Order Number 36-Williams Street
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "#171A1F", // Return the value directly
                  // marginBottom: "20px",
                }}
              >
                09:00 AM to 9:00 PM
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <MoreVert sx={{ color: "#673C8E" }} />
              </IconButton>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "11px",
                  color: "#171A1F", // Return the value directly
                  // marginBottom: "20px",
                }}
              >
                2 pickups
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              padding: "16px",
              border: "1px solid rgba(104, 60, 140, 0.06)",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{ width: 50, height: 50 }}
              alt="Remy Sharp"
              src="/static/images/avatar/1.jpg"
            />
            <Box>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "#171A1F", // Return the value directly
                }}
              >
                Order Number 36-Williams Street
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "#171A1F", // Return the value directly
                  // marginBottom: "20px",
                }}
              >
                09:00 AM to 9:00 PM
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <MoreVert sx={{ color: "#673C8E" }} />
              </IconButton>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "11px",
                  color: "#171A1F", // Return the value directly
                  // marginBottom: "20px",
                }}
              >
                2 pickups
              </Typography>
            </Box>
          </Box>
          {/* <Box sx={{ textAlign: "center", margin: "50px 0" }}>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                color: "#673C8E", // Return the value directly
                // marginBottom: "20px",
              }}
            >
              No data Yet!
            </Typography>
          </Box> */}
        </Card>
      </Grid>
      {/* ------------------------------------------ LATEST ORDERS ------------------------------------------------ */}

      {/* -------------------------------------- CALENDAR ---------------------------------------------- */}
      <Grid xs={12} lg={2.5} item>
        <Card sx={{ padding: "12px", borderRadius: "14px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "16px",
                color: "#673C8E", // Return the value directly
                // marginBottom: "20px",
              }}
            >
              Calendar
            </Typography>
            <IconButton>
              <img src={calendarDashboard} />
            </IconButton>
          </Box>
          <Box sx={{ textAlign: "center", margin: "120px 0" }}>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                color: "#673C8E", // Return the value directly
                // marginBottom: "20px",
              }}
            >
              No data Yet!
            </Typography>
          </Box>
        </Card>
      </Grid>
      {/* -------------------------------------- CALENDAR ---------------------------------------------- */}

      {/* -------------------------------------- REVIEW CAROUSEL ---------------------------------------- */}
      <Grid item xs={12} lg={12}>
        <Box
          sx={{
            width: "100%",
            height: "200px",
            marginBottom: "40px",
            position: "relative",
            paddingTop: "40px",
          }}
        >
          <ReviewCarousel />
        </Box>
      </Grid>
      {/* -------------------------------------- REVIEW CAROUSEL ---------------------------------------- */}

      {/* <Grid xs={12} item sx={{ textAlign: "center", paddingTop: "45px" }}>
        <img src={homeImg} />
      </Grid> */}
    </Grid>
  );
}
