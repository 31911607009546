import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  Typography,
  Avatar,
  Box,
  Chip,
  Button,
} from "@mui/material";
import { useDrag } from "react-dnd";
import reviewImg from "../../../assets/images/svg/reviewImg.svg";
import reviewImg2 from "../../../assets/images/svg/reviewImg.svg";
import reviewImg3 from "../../../assets/images/svg/reviewImg.svg";
import star from "../../../assets/images/svg/star.svg";
import avatar from "../../../assets/images/svg/avatar.svg";
import ClientReviewModel from "../../../components/modals/ClientReviewModel";
import { $crud } from "../../../utils/CrudFactory";
import { BASE_URL } from "../../../constants";

const OtherReviewDriver = ({ onDrop, showAllReviews, onViewAllClick }) => {
  const [reviews, setReviews] = useState([]);

  async function retrieveData() {
    const { data } = await $crud.retrieve("admin/driver-reviews");
    return data;
  }

  useEffect(() => {
    retrieveData().then((res) => {
      setReviews(res.reviews);
    });
  }, []);
  const visibleReviews = showAllReviews ? reviews : reviews.slice(0, 4);
  return (
    <Grid container spacing={2}>
      {!showAllReviews && (
        <>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: "40px",
                color: "#673C8E",
              }}
            >
              Other Reviews
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 400,
                fontSize: "12px",
                color: "#A3A3A3",
              }}
            >
              Here is customer review about your restaurant
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} sx={{ textAlign: "right" }}>
            <Button
              onClick={onViewAllClick}
              sx={{
                background: "rgba(103, 60, 142)",
                color: "#fff",
                padding: "4px 14px",
                textTransform: "capitalize",
                border: "none",
                fontSize: "14px",
                width: "90px",
                outline: "none !important",
                borderRadius: "4px",
                "&:hover": {
                  color: "#fff",
                  background: "rgba(103, 60, 142)",
                },
                "&:focus": {
                  color: "#fff",
                  background: "rgba(103, 60, 142)",
                },
              }}
            >
              View All
            </Button>
          </Grid>
        </>
      )}
      <Box
        sx={{
          // height: showAllReviews ? "auto" : "230px",
          height: "auto",
          overflow: "auto",
          paddingLeft: "18px",
        }}
      >
        {visibleReviews.map((review, index) => (
          <DraggableReview
            showAllReviews={showAllReviews}
            key={index}
            review={review}
            onDrop={onDrop}
          />
        ))}
      </Box>
    </Grid>
  );
};

const DraggableReview = ({ review, onDrop, showAllReviews }) => {
  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSubmit = async () => {
    onDrop(review);
    const { data } = await $crud.post("admin/top-driver-review", review);
    if (data.success) {
      onDrop(review); // Update the state with the new review
    }
    setOpenModal(false);
  };

  const [, drag] = useDrag(() => ({
    type: "review",
    item: review,
    end: async (item, monitor) => {
      if (monitor.didDrop()) {
        onDrop(item);
        // console.log(review, "dragged review, =========================");
        const { data } = await $crud.post("admin/top-driver-review", review);
        if (data.success) {
          onDrop(review); // Update the state with the new review
        }
      }
    },
  }));

  return (
    <>
      <Grid item xs={12} ref={drag}>
        <Card
          sx={{
            background: "#FEFEFF",
            marginBottom: "15px",
            padding: "28px",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "start",
            gap: "24px",
            position: "relative",
          }}
        >
          {showAllReviews && (
            <Button
              onClick={() => {
                setOpenModal(true);
              }}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                background: "rgba(103, 60, 142)",
                color: "#fff",
                padding: "4px 14px",
                textTransform: "capitalize",
                border: "none",
                fontSize: "14px",
                // width: "90px",
                outline: "none !important",
                borderRadius: "4px",
                "&:hover": {
                  color: "#fff",
                  background: "rgba(103, 60, 142)",
                },
                "&:focus": {
                  color: "#fff",
                  background: "rgba(103, 60, 142)",
                },
              }}
            >
              Set as Best Review
            </Button>
          )}
          <Box>
            <Avatar src={avatar} />
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#000",
                    fontWeight: 600,
                  }}
                >
                  {review.author}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "32px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "9px",
                      color: "#000",
                      fontWeight: 400,
                    }}
                  >
                    {review.city}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "9px",
                      color: "rgba(151, 151, 151, 1)",
                      fontWeight: 400,
                    }}
                  >
                    {review.date}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Chip
                  size="small"
                  label="Good Services"
                  sx={{
                    background: "rgba(104, 60, 140, 0.35)",
                    color: "rgba(104, 60, 140, 1)",
                    fontSize: "10px",
                    marginRight: "15px",
                  }}
                />
                <Chip
                  size="small"
                  label="Excellent"
                  sx={{
                    background: "#D5E5F4",
                    color: "rgba(44, 156, 219, 1)",
                    fontSize: "10px",
                  }}
                />
              </Box>
            </Box>

            <Typography
              sx={{
                fontSize: "9px",
                color: "#171A1F",
                fontWeight: 400,
                marginTop: "15px",
              }}
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text.
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy
              textLorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy textLorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy textLorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy text
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: showAllReviews ? "10px" : 0,
            }}
          >
            <Typography
              sx={{
                fontSize: "24px",
                color: "#171A1F",
                fontWeight: 700,
              }}
            >
              4.5
            </Typography>
            <Box sx={{ display: "flex", gap: 1 }}>
              <img src={`${BASE_URL}/driverReviews/${review.icon}`} />
              <img src={`${BASE_URL}/driverReviews/${review.icon}`} />
              <img src={`${BASE_URL}/driverReviews/${review.icon}`} />
              <img src={`${BASE_URL}/driverReviews/${review.icon}`} />
            </Box>
          </Box>
        </Card>
      </Grid>
      {openModal && (
        <ClientReviewModel
          open={openModal}
          onSubmit={handleSubmit}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default OtherReviewDriver;
