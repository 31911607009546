import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  Backdrop,
  FormControl,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backdropFilter: "blur(2px)", // Adjust blur strength as needed
  },
  dialogPaper: {
    borderRadius: "0", // Set border radius to 0
    border: "1px solid #000",
  },
  textFieldRoot: {
    "& .MuiInputLabel-root": {
      color: "#8D8D8E !important", // Adding !important to ensure the color overrides
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(103, 60, 142, 1)", // Initial outlined border color
      },
      "&:hover fieldset": {
        borderColor: "rgba(103, 60, 142, 1)", // Change outlined border color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(103, 60, 142, 1)", // Change outlined border color when focused
      },
    },
  },
}));
const PromoTermModal = ({
  onClose,
  open,
  onSubmit,
  view,
  existingData,
  id,
}) => {
  const classes = useStyles();
  const [editorData, setEditorData] = useState(existingData?.content || "");
  // const [validFor, setValidFor] = useState(existingData?.validFor || "");

  useEffect(() => {
    setEditorData(existingData?.content || "");
    // setValidFor(existingData?.validFor || "");
  }, [existingData]);

  const handleSubmit = () => {
    // Handle submission logic, e.g., send editorData to an API
    onSubmit(editorData);
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={open} />
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogContent sx={{ marginTop: "30px", padding: "0 40px" }}>
          <Typography
            id="modal-title"
            sx={{
              fontSize: "20px",
              mb: 2,
              textAlign: "center",
            }}
            gutterBottom
          >
            {view ? "View " : id ? "Update " : "Add "}
            Promo Term
          </Typography>
          <Box className={classes.editorWrapper} sx={{ mt: 3, mb: 4 }}>
            <CKEditor
              editor={ClassicEditor}
              data={editorData}
              onChange={(event, editor) => {
                const data = editor.getData();
                !view && setEditorData(data); // Update state with editor content only if not in view mode
              }}
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "underline",
                  "strikethrough",
                  "fontSize",
                  "|",
                  "alignment",
                  "bulletedList",
                  "numberedList",
                  "indent",
                  "outdent",
                  "|",
                  "link",
                  "blockQuote",
                  "insertTable",
                  "mediaEmbed",
                  "imageUpload",
                  "undo",
                  "redo",
                  "findAndReplace",
                  "highlight",
                  "removeFormat",
                  "|",
                  "sourceEditing",
                  "specialCharacters",
                  "code",
                ],
                image: {
                  toolbar: [
                    "imageTextAlternative",
                    "imageStyle:full",
                    "imageStyle:side",
                  ],
                },
                table: {
                  contentToolbar: [
                    "tableColumn",
                    "tableRow",
                    "mergeTableCells",
                    "tableProperties",
                    "tableCellProperties",
                  ],
                },
                heading: {
                  options: [
                    {
                      model: "paragraph",
                      title: "Paragraph",
                      class: "ck-heading_paragraph",
                    },
                    {
                      model: "heading1",
                      view: "h1",
                      title: "Heading 1",
                      class: "ck-heading_heading1",
                    },
                    {
                      model: "heading2",
                      view: "h2",
                      title: "Heading 2",
                      class: "ck-heading_heading2",
                    },
                    {
                      model: "heading3",
                      view: "h3",
                      title: "Heading 3",
                      class: "ck-heading_heading3",
                    },
                  ],
                },
              }}
              disabled={view}
            />
          </Box>
          <Box
            sx={{
              marginTop: "47px",
              marginBottom: "38px",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              sx={{
                borderColor: "#683C8C",
                color: "#683C8C",
                padding: "6px 40px",
                fontSize: "15px",
                fontWeight: 400,
                textTransform: "capitalize",
                "&:hover": {
                  borderColor: "#683C8C",
                  backgroundColor: "transparent",
                  color: "#683C8C",
                },
                mr: 1,
              }}
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              disabled={view} // Disable button if view is true
              sx={{
                backgroundColor: "#683C8C",
                color: "#FFFFFF",
                padding: "6px 40px",
                fontSize: "15px",
                fontWeight: 400,
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#683C8C",
                  color: "#FFFFFF",
                },
              }}
              variant="contained"
              onClick={handleSubmit}
            >
              {id ? "Update" : "Submit"}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PromoTermModal;
