import {
  Avatar,
  Box,
  Button,
  Card,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import back from "../../assets/images/svg/back.svg";
import sort from "../../assets/images/svg/sort.svg";
import profile from "../../assets/images/svg/avatar.svg";
import email from "../../assets/images/svg/email.svg";
import call from "../../assets/images/svg/call.svg";
import edit from "../../assets/images/svg/edit.svg";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import add from "../../assets/images/svg/add.svg";
import reject from "../../assets/images/svg/reject.svg";
import unblock from "../../assets/images/svg/unblock.svg";
import minus from "../../assets/images/svg/minus.svg";
import returnIcon from "../../assets/images/svg/return.svg";
import dotIcon from "../../assets/images/svg/dot.svg";
import reload from "../../assets/images/svg/reload.svg";
import MapComponent from "../../components/maps/MapComponent";
import CustomPagination from "../../components/CustomPagination";
import { usePagination } from "../../hooks";
import DynamicTable from "../../components/DynamicTable";
import TotalRevenueLineChart from "../../components/customCharts/TotalRevenueLineChart";
import { FileDownloadOutlined, MoreVert } from "@mui/icons-material";
import { $crud } from "../../utils/CrudFactory";

// CUSTOM STEPPER FOR ORDER DETAIL ------------------------------

const CustomStepConnector = () => (
  <StepConnector
    sx={{
      "& .MuiStepConnector-line": {
        border: "1px dashed rgba(103, 60, 142, 1)",
      },
    }}
  />
);

// CUSTOM STEPPER FOR ORDER DETAIL ------------------------------

// CUSTOM TOOLTIP ====================================
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: 140, // Custom maxWidth for Tooltip
    minHeight: 56, // Custom maxWidth for Tooltip
    background: "#795898", // Additional styles if needed
    color: "#fff", // Additional styles if needed
    fontSize: "8px", // Additional styles if needed
    padding: "8px", // Additional styles if needed
    position: "absolute",
    top: "-38px",
    left: "-90px",
    borderRadius: "0",
  },
});

// STEPS FOR THE STATUS OF ORDER -------------------------------

const steps = [
  {
    label: "Account Created",
    description: "21 Jul 2020\n11:49 AM",
    icon: <img src={add} width="25px" style={{ marginLeft: "8px" }} />,
    dot: <img src={dotIcon} />,
    tooltip: false,
  },
];

export function ClientDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const [client, setClient] = useState(location.state?.selectedClient);
  const [orderData, setOrderData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const handleBackClick = () => {
    navigate(-1);
  };
  const handleViewAllClick = () => {
    navigate(`/orders?clientId=${client.customerID}`);
  };
  const [mergedSteps, setMergedSteps] = useState([]);
  useEffect(() => {
    // Combine order.positionHistory with existing steps
    const merged = [...steps];

    if (client && client.blockStatusHistory) {
      client.blockStatusHistory.forEach((historyItem, index) => {
        let label = "";
        let icon = "";
        let desc = "";
        let dot = <img src={dotIcon} />;
        if (historyItem.status == "Blocked") {
          label = "Account Blocked";
          desc = "Fri,22 Jul 2020,10:44 AM - 35m";
          icon = (
            <img src={reject} width="25px" style={{ marginLeft: "8px" }} />
          );
        }
        if (historyItem.status == "Active") {
          label = "Account Unblocked";
          desc = "Fri,22 Jul 2020,10:44 AM - 35m";
          icon = (
            <img src={unblock} width="25px" style={{ marginLeft: "8px" }} />
          );
        }
        merged.push({
          label,
          description: desc,
          icon,
          reason: historyItem.reason,
          tooltip: true,
          dot,
        });
      });
    }

    setMergedSteps(merged);
  }, [client, steps]);

  const sortedRows = useMemo(() => {
    let sortableRows = [...orderData];
    if (sortConfig.key !== null) {
      sortableRows.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableRows;
  }, [orderData, sortConfig]);

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  async function retrieveClientOrders() {
    const { data } = await $crud.retrieve("admin/orders", {
      search: client.customerID,
    });
    return data;
  }

  console.log(client, "client");
  useEffect(() => {
    retrieveClientOrders().then((res) => {
      // console.log(res.result);
      setOrderData(res.result.slice(0, 5));
      // setTotal(res.total);
    });
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
  }, [client]);

  const orderColumns = [
    {
      id: "orderId",
      label: "Order ID",
      align: "center",
    },
    {
      id: "dateBooked",
      label: "Date",
      align: "center",
    },
    {
      id: "status",
      label: "Status",
      align: "center",
      renderCell: (row) => {
        return (
          <Button
            sx={{
              background: "rgba(103, 60, 142, 0.40)",
              color: "rgba(103, 60, 142, 1)",
              padding: "4px 14px",
              textTransform: "capitalize",
              border: "none",
              fontSize: "10px",
              cursor: "default",
              width: "90px",
              outline: "none !important",
              "&:hover": {
                outline: "rgba(103, 60, 142, 0.40)",
                background: "rgba(103, 60, 142, 0.40)",
              },
              "&:focus": {
                outline: "rgba(103, 60, 142, 0.40)",
                background: "rgba(103, 60, 142, 0.40)",
              },
            }}
          >
            {/* {row.status} */}
            New Order
          </Button>
        );
      },
    },
  ];

  return (
    <Grid container sx={{ padding: "0 24px" }} spacing={1}>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "flex-start",
            gap: 1,
          }}
        >
          <IconButton onClick={handleBackClick} sx={{ marginLeft: "-10px" }}>
            <img src={back} />
          </IconButton>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: 6,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 700,
                  fontSize: "30px",
                  color: "#673C8E", // Return the value directly
                }}
              >
                Client Details
              </Typography>
            </Box>
            {/* <Box sx={{ marginTop: "12px" }}>
              <Typography
                variant="h5"
                sx={{
                  fontSize: "13px",
                  color: "#673C8E", // Return the value directly
                }}
              >
                Booked : {order.dateBooked}
              </Typography>
            </Box> */}
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} md={6} mt={2}>
        <Typography
          variant="h5"
          sx={{
            fontSize: "13px",
            color: "rgba(126, 126, 126, 1)", // Return the value directly
          }}
        >
          Joined On : {client.joinDate}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} sx={{ textAlign: "right" }}>
        <Button
          variant="contained"
          size="small"
          sx={{
            padding: "6px 65px",
            textTransform: "capitalize",
            backgroundColor:
              client.blockStatus === "Active"
                ? "rgba(7, 79, 36, 0.2)"
                : "rgba(255, 240, 237, 1)",
            color:
              client.blockStatus === "Active"
                ? "rgba(7, 79, 36, 1)"
                : "rgba(254, 133, 106, 1)",
            borderColor: "#683C8C",
            boxShadow: "none",
            cursor: "default",
            "&:hover": {
              backgroundColor:
                client.blockStatus === "Active"
                  ? "rgba(7, 79, 36, 0.2)"
                  : "rgba(255, 240, 237, 1)",
              color:
                client.blockStatus === "Active"
                  ? "rgba(7, 79, 36, 1)"
                  : "rgba(254, 133, 106, 1)",
              borderColor: "#683C8C",
              boxShadow: "none",
            },
          }}
        >
          {client.blockStatus}
        </Button>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card
          sx={{
            borderRadius: 0,
            padding: "20px 20px 4px 20px",
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            gap: "21px",
            position: "relative",
            background: "#fff",
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              right: "20px",
              top: "18px",
              background: "rgba(104, 60, 140, 0.35)",
              borderRadius: 0,
              "&:hover": {
                background: "rgba(104, 60, 140, 0.35)",
              },
            }}
          >
            <img src={edit} />
          </IconButton>
          <Box>
            <Avatar
              variant="square"
              sx={{ width: "170px", height: "165px" }}
              src={profile}
            />
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "14px",
                color: "rgba(103, 60, 142, 1)",
                fontWeight: 400,
                mt: 1,
              }}
            >
              {client.customerID}
            </Typography>
          </Box>
          <Box sx={{ width: "100%", marginTop: "-10px" }}>
            <Typography
              sx={{
                fontSize: "36px",
                color: "#000",
                fontWeight: 600,
              }}
            >
              {client.name}
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontSize: "12px",
                color: "rgba(126, 126, 126, 1)", // Return the value directly
              }}
            >
              Home : {client.location}
            </Typography>
            <Box
              sx={{
                marginTop: "12px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <img src={email} />
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: "12px",
                    color: "rgba(23, 26, 31, 1)", // Return the value directly
                  }}
                >
                  {client.email}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <img src={call} />
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: "12px",
                    color: "rgba(23, 26, 31, 1)", // Return the value directly
                  }}
                >
                  {client.phone}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "30px",
              }}
            >
              <Box>
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: "14px",
                    color: "rgba(103, 60, 142, 1)", // Return the value directly
                  }}
                >
                  Last Order Spent
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 700,
                    fontSize: "14px",
                    color: "rgba(103, 60, 142, 1)", // Return the value directly
                  }}
                >
                  ${client.lastOrderPrice}.00
                </Typography>
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: "14px",
                    color: "rgba(103, 60, 142, 1)", // Return the value directly
                  }}
                >
                  Total Spent Till Date
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 700,
                    fontSize: "14px",
                    color: "rgba(103, 60, 142, 1)", // Return the value directly
                  }}
                >
                  ${client.totalSpent}.00
                </Typography>
              </Box>
            </Box>
          </Box>
        </Card>
      </Grid>

      {/* CLIENTS HISTORY STEPPER */}
      <Grid item xs={12} md={6}>
        <Box>
          <Typography
            sx={{
              fontSize: "20px",
              color: "rgba(104, 60, 140, 1)",
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            Client History
          </Typography>
          <Box
            sx={{
              border: "1px solid #673C8E",
              padding: "12px",
              marginTop: "6px",
              ml: 2,
              height: "auto",
              maxWidth: "100%",
              overflowX: "auto",
              marginRight: "14px",
            }}
          >
            <Stepper
              activeStep={3}
              orientation="horizontal"
              connector={<CustomStepConnector />}
            >
              {mergedSteps.map((step, index) => (
                <Step key={index} sx={{ minWidth: "120px", maxWidth: "150px" }}>
                  {step.tooltip ? (
                    <CustomTooltip title={step.reason || "No reason provided"}>
                      <StepLabel
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        icon={step.icon}
                      >
                        <Typography
                          sx={{
                            textAlign: "center",
                            margin: "8px 0",
                          }}
                        >
                          {step.dot}
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(104, 60, 140, 1)",
                            fontSize: "10px",
                            fontWeight: 700,
                            textAlign: "center",
                          }}
                        >
                          {step.label}
                        </Typography>
                        <Typography
                          sx={{
                            color: "black",
                            fontSize: "10px",
                            textAlign: "center",
                          }}
                        >
                          {step.description}
                        </Typography>
                      </StepLabel>
                    </CustomTooltip>
                  ) : (
                    <StepLabel
                      icon={step.icon}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          textAlign: "center",
                          margin: "8px 0",
                        }}
                      >
                        {step.dot}
                      </Typography>
                      <Typography
                        sx={{
                          color: "rgba(104, 60, 140, 1)",
                          fontSize: "10px",
                          fontWeight: 700,
                          textAlign: "center",
                        }}
                      >
                        {step.label}
                      </Typography>
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: "10px",
                          textAlign: "center",
                        }}
                      >
                        {step.description}
                      </Typography>
                    </StepLabel>
                  )}
                </Step>
              ))}
            </Stepper>
          </Box>
        </Box>

        <Box
          sx={{
            background: "#683C8C",
            display: "flex",
            justifyContent: "space-around",
            ml: 2,
            marginRight: "14px",
            marginTop: "10px",
            padding: "4px",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "10px",
                color: "#fff",
                fontWeight: 400,
                textAlign: "center",
              }}
            >
              Average Rating
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                color: "#fff",
                fontWeight: 700,
                textAlign: "center",
              }}
            >
              4.5/5
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "10px",
                color: "#fff",
                fontWeight: 400,
                textAlign: "center",
              }}
            >
              Last Order Rating
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                color: "#fff",
                fontWeight: 700,
                textAlign: "center",
              }}
            >
              3/5
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "10px",
                color: "#fff",
                fontWeight: 400,
                textAlign: "center",
              }}
            >
              Average Pickups
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                color: "#fff",
                fontWeight: 700,
                textAlign: "center",
              }}
            >
              3
            </Typography>
          </Box>
        </Box>
      </Grid>

      {/* order list */}
      <Grid item xs={12} md={5} mt={1} mb={2}>
        <Card sx={{ borderRadius: 0, background: "#fff" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "end",
              width: "100%",
              padding: "20px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "22px",
                  color: "rgba(103, 60, 142, 1)",
                  fontWeight: 600,
                }}
              >
                Order Details
              </Typography>
              <Typography
                sx={{
                  fontSize: "8px",
                  color: "#7C7D7F",
                  fontWeight: 400,
                }}
              >
                Lorem ipsum dolor sit amet, consectetur
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "rgba(103, 60, 142, 1)",
                  fontWeight: 700,
                  cursor: "pointer",
                  textTransform: "uppercase",
                }}
                onClick={handleViewAllClick}
              >
                VIEW All
              </Typography>
            </Box>
          </Box>

          <TableContainer
            sx={{
              height: "400px",
              maxWidth: "100vw",
              // boxShadow: (theme) => theme.shadows[5],
              borderRadius: 0,
              position: "relative", // Ensure that the container is positioned relatively
            }}
          >
            <Table
              // size="small"
              // aria-label="a dense table"
              sx={{ borderRadius: "0", height: "100%" }}
              stickyHeader
              // aria-label=" sticky table"
            >
              <TableHead>
                <TableRow>
                  {orderColumns?.map((column) => (
                    <TableCell
                      sx={{
                        background: "#683C8C",
                        color: "#fff",
                        cursor: "pointer",
                      }}
                      key={column.id}
                      align={column.align}
                      onClick={() => requestSort(column.id)}
                    >
                      {column.label}
                      {column.label != "Status" && (
                        <img
                          src={sort}
                          style={{
                            marginLeft: "10px",
                            width: "10px",
                            height: "10px",
                          }}
                        />
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {sortedRows.length === 0 ? (
                <TableBody>
                  <TableRow>
                    <TableCell
                      colSpan={orderColumns.length}
                      sx={{ borderBottom: "none", p: 0 }}
                    >
                      <Box
                        sx={{
                          height: "200px", // Ensure it matches the height of the container
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "16px", color: "rgba(0, 0, 0, 1)" }}
                        >
                          No orders
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {sortedRows?.map((row) => (
                    <TableRow
                      sx={{
                        cursor: "pointer",
                      }}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {orderColumns?.map((column) => {
                        const value = row[column.id];

                        return (
                          <TableCell
                            sx={{
                              color: "rgba(86, 93, 109, 1)",
                              fontSize: "12px",
                            }}
                            key={column.id}
                            align={column.align}
                          >
                            {column?.renderCell
                              ? column?.renderCell(row)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
      </Grid>

      <Grid xs={12} lg={7} item mt={1} mb={2}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            paddingLeft: "10px",
            marginBottom: "16px",
            gap: 2,
          }}
        >
          <Card
            sx={{
              padding: "8px",
              borderRadius: "14px",
              width: "40%",
              background: "#fff",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: 700,
                fontSize: "20px",
                color: "#673C8E", // Return the value directly
                mb: 2,
              }}
            >
              Current Chats
            </Typography>
            <Box
              sx={{ overflowY: "scroll", overflowX: "hidden", height: "176px" }}
            >
              <Box
                sx={{
                  paddingRight: "6px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                  mb: 1,
                }}
              >
                <Avatar
                  sx={{ width: 50, height: 50, borderRadius: "20px" }}
                  alt="Remy Sharp"
                  src={profile}
                />
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "12px",
                        color: "#1A1D1F", // Return the value directly
                      }}
                    >
                      Domenica
                    </Typography>
                    <Typography
                      sx={{
                        color: "#9A9FA5",
                        fontSize: "10px",
                        fontWeight: 500,
                      }}
                    >
                      ID : OID272
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "8px",
                      color: "#1A1D1F", // Return the value directly
                    }}
                  >
                    “Great work, I just purchased this product” <br />{" "}
                    <b>Reply</b>
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  paddingRight: "6px",
                  display: "flex",
                  justifyContent: "center",

                  alignItems: "center",
                  gap: 2,
                  mb: 1,
                }}
              >
                <Avatar
                  sx={{ width: 50, height: 50, borderRadius: "20px" }}
                  alt="Remy Sharp"
                  src={profile}
                />
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "12px",
                        color: "#1A1D1F", // Return the value directly
                      }}
                    >
                      Domenica
                    </Typography>
                    <Typography
                      sx={{
                        color: "#9A9FA5",
                        fontSize: "10px",
                        fontWeight: 500,
                      }}
                    >
                      ID : OID272
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "8px",
                      color: "#1A1D1F", // Return the value directly
                    }}
                  >
                    “Great work, I just purchased this product” <br />{" "}
                    <b>Reply</b>
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  paddingRight: "6px",
                  display: "flex",
                  justifyContent: "center",

                  alignItems: "center",
                  gap: 2,
                  mb: 1,
                }}
              >
                <Avatar
                  sx={{ width: 50, height: 50, borderRadius: "20px" }}
                  alt="Remy Sharp"
                  src={profile}
                />
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "12px",
                        color: "#1A1D1F", // Return the value directly
                      }}
                    >
                      Domenica
                    </Typography>
                    <Typography
                      sx={{
                        color: "#9A9FA5",
                        fontSize: "10px",
                        fontWeight: 500,
                      }}
                    >
                      ID : OID272
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "8px",
                      color: "#1A1D1F", // Return the value directly
                    }}
                  >
                    “Great work, I just purchased this product” <br />{" "}
                    <b>Reply</b>
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  paddingRight: "6px",
                  display: "flex",
                  justifyContent: "center",

                  alignItems: "center",
                  gap: 2,
                  mb: 1,
                }}
              >
                <Avatar
                  sx={{ width: 50, height: 50, borderRadius: "20px" }}
                  alt="Remy Sharp"
                  src={profile}
                />
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "12px",
                        color: "#1A1D1F", // Return the value directly
                      }}
                    >
                      Domenica
                    </Typography>
                    <Typography
                      sx={{
                        color: "#9A9FA5",
                        fontSize: "10px",
                        fontWeight: 500,
                      }}
                    >
                      ID : OID272
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "8px",
                      color: "#1A1D1F", // Return the value directly
                    }}
                  >
                    “Great work, I just purchased this product” <br />{" "}
                    <b>Reply</b>
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  paddingRight: "6px",
                  display: "flex",
                  justifyContent: "center",

                  alignItems: "center",
                  gap: 2,
                  mb: 1,
                }}
              >
                <Avatar
                  sx={{ width: 50, height: 50, borderRadius: "20px" }}
                  alt="Remy Sharp"
                  src={profile}
                />
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "12px",
                        color: "#1A1D1F", // Return the value directly
                      }}
                    >
                      Domenica
                    </Typography>
                    <Typography
                      sx={{
                        color: "#9A9FA5",
                        fontSize: "10px",
                        fontWeight: 500,
                      }}
                    >
                      ID : OID272
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "8px",
                      color: "#1A1D1F", // Return the value directly
                    }}
                  >
                    “Great work, I just purchased this product” <br />{" "}
                    <b>Reply</b>
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "6px",
                  }}
                >
                  <Avatar
                    sx={{ width: 20, height: 20, borderRadius: "50%" }}
                    alt="Remy Sharp"
                    src={profile}
                  />
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: "7px",
                      color: "#683C8C",
                    }}
                  >
                    @domenia
                  </Typography>
                  <Typography
                    sx={{
                      borderLeft: "2px solid black",
                      paddingLeft: "6px",
                      fontWeight: 600,
                      fontSize: "7px",
                      color: "rgba(17, 19, 21, 0.5)",
                    }}
                  >
                    Leave something to reply
                  </Typography>
                </Box>
                <Box>
                  <Button
                    sx={{
                      background: "#673C8E",
                      color: "#fff",
                      padding: "4px 14px",
                      textTransform: "capitalize",
                      fontSize: "10px",
                      outline: "none !important",
                      "&:hover": {
                        outline: "#673C8E",
                        background: "#673C8E",
                      },
                      "&:focus": {
                        outline: "#673C8E",
                        background: "#673C8E",
                      },
                    }}
                  >
                    Reply
                  </Button>
                  <Button
                    sx={{
                      background: "#fff",
                      color: "#000",
                      padding: "4px 14px",
                      textTransform: "capitalize",
                      fontSize: "10px",
                      outline: "none !important",
                      "&:hover": {
                        outline: "#fff",
                        background: "#fff",
                      },
                      "&:focus": {
                        outline: "#fff",
                        background: "#fff",
                      },
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </Card>

          <Card
            sx={{
              padding: "8px",
              borderRadius: "14px",
              width: "60%",
              background: "#fff",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: 700,
                fontSize: "20px",
                color: "#673C8E", // Return the value directly
                mb: 2,
              }}
            >
              Current Pending Orders
            </Typography>
            <Box
              sx={{ overflowY: "scroll", overflowX: "hidden", height: "176px" }}
            >
              <Card
                sx={{
                  background: "#fff",
                  padding: "14px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 1,
                  borderRadius: "14px",
                  boxShadow:
                    "0px 0px 2px 0px rgba(23, 26, 31, 0.12), 0px 0px 1px 0px rgba(23, 26, 31, 0.07)",
                }}
              >
                <Avatar
                  sx={{ width: 50, height: 50 }}
                  alt="Remy Sharp"
                  src="/static/images/avatar/1.jpg"
                />
                <Box>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "#171A1F", // Return the value directly
                    }}
                  >
                    Order Number 36-Williams Street
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "#171A1F", // Return the value directly
                      // marginBottom: "20px",
                    }}
                  >
                    09:00 AM to 9:00 PM
                  </Typography>
                </Box>
                <Box>
                  {/* <IconButton>
                    <MoreVert sx={{ color: "#673C8E" }} />
                  </IconButton> */}
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "11px",
                      color: "#171A1F", // Return the value directly
                      // marginBottom: "20px",
                    }}
                  >
                    2 pickups
                  </Typography>
                </Box>
              </Card>
              <Card
                sx={{
                  background: "#fff",
                  padding: "14px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 1,
                  borderRadius: "14px",
                  boxShadow:
                    "0px 0px 2px 0px rgba(23, 26, 31, 0.12), 0px 0px 1px 0px rgba(23, 26, 31, 0.07)",
                }}
              >
                <Avatar
                  sx={{ width: 50, height: 50 }}
                  alt="Remy Sharp"
                  src="/static/images/avatar/1.jpg"
                />
                <Box>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "#171A1F", // Return the value directly
                    }}
                  >
                    Order Number 36-Williams Street
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "#171A1F", // Return the value directly
                      // marginBottom: "20px",
                    }}
                  >
                    09:00 AM to 9:00 PM
                  </Typography>
                </Box>
                <Box>
                  {/* <IconButton>
                    <MoreVert sx={{ color: "#673C8E" }} />
                  </IconButton> */}
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "11px",
                      color: "#171A1F", // Return the value directly
                      // marginBottom: "20px",
                    }}
                  >
                    2 pickups
                  </Typography>
                </Box>
              </Card>
              <Card
                sx={{
                  background: "#fff",
                  padding: "14px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 1,
                  borderRadius: "14px",
                  boxShadow:
                    "0px 0px 2px 0px rgba(23, 26, 31, 0.12), 0px 0px 1px 0px rgba(23, 26, 31, 0.07)",
                }}
              >
                <Avatar
                  sx={{ width: 50, height: 50 }}
                  alt="Remy Sharp"
                  src="/static/images/avatar/1.jpg"
                />
                <Box>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "#171A1F", // Return the value directly
                    }}
                  >
                    Order Number 36-Williams Street
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "#171A1F", // Return the value directly
                      // marginBottom: "20px",
                    }}
                  >
                    09:00 AM to 9:00 PM
                  </Typography>
                </Box>
                <Box>
                  {/* <IconButton>
                    <MoreVert sx={{ color: "#673C8E" }} />
                  </IconButton> */}
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "11px",
                      color: "#171A1F", // Return the value directly
                      // marginBottom: "20px",
                    }}
                  >
                    2 pickups
                  </Typography>
                </Box>
              </Card>
              <Card
                sx={{
                  background: "#fff",
                  padding: "14px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 1,
                  borderRadius: "14px",
                  boxShadow:
                    "0px 0px 2px 0px rgba(23, 26, 31, 0.12), 0px 0px 1px 0px rgba(23, 26, 31, 0.07)",
                }}
              >
                <Avatar
                  sx={{ width: 50, height: 50 }}
                  alt="Remy Sharp"
                  src="/static/images/avatar/1.jpg"
                />
                <Box>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "#171A1F", // Return the value directly
                    }}
                  >
                    Order Number 36-Williams Street
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "#171A1F", // Return the value directly
                      // marginBottom: "20px",
                    }}
                  >
                    09:00 AM to 9:00 PM
                  </Typography>
                </Box>
                <Box>
                  {/* <IconButton>
                    <MoreVert sx={{ color: "#673C8E" }} />
                  </IconButton> */}
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "11px",
                      color: "#171A1F", // Return the value directly
                      // marginBottom: "20px",
                    }}
                  >
                    2 pickups
                  </Typography>
                </Box>
              </Card>
              <Card
                sx={{
                  background: "#fff",
                  padding: "14px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 1,
                  borderRadius: "14px",
                  boxShadow:
                    "0px 0px 2px 0px rgba(23, 26, 31, 0.12), 0px 0px 1px 0px rgba(23, 26, 31, 0.07)",
                }}
              >
                <Avatar
                  sx={{ width: 50, height: 50 }}
                  alt="Remy Sharp"
                  src="/static/images/avatar/1.jpg"
                />
                <Box>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "#171A1F", // Return the value directly
                    }}
                  >
                    Order Number 36-Williams Street
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "#171A1F", // Return the value directly
                      // marginBottom: "20px",
                    }}
                  >
                    09:00 AM to 9:00 PM
                  </Typography>
                </Box>
                <Box>
                  {/* <IconButton>
                    <MoreVert sx={{ color: "#673C8E" }} />
                  </IconButton> */}
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "11px",
                      color: "#171A1F", // Return the value directly
                      // marginBottom: "20px",
                    }}
                  >
                    2 pickups
                  </Typography>
                </Box>
              </Card>
            </Box>
          </Card>
        </Box>
        <Card
          sx={{
            padding: "16px 20px",
            marginLeft: "10px",
            borderRadius: "14px",
            background: "#fff",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              background: "#fff",
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "20px",
                color: "#673C8E", // Return the value directly
                // marginBottom: "20px",
              }}
            >
              Total Revenue
            </Typography>
            <RadioGroup row defaultValue="2024">
              <FormControlLabel
                value="2023"
                control={
                  <Radio
                    sx={{
                      color: "rgba(104, 60, 140, 1)",
                      "&.Mui-checked": {
                        color: "rgba(104, 60, 140, 1)",
                      },
                    }}
                  />
                }
                label="2023"
              />
              <FormControlLabel
                value="2024"
                control={
                  <Radio
                    sx={{
                      color: "rgba(104, 60, 140, 1)",
                      "&.Mui-checked": {
                        color: "rgba(104, 60, 140, 1)",
                      },
                    }}
                  />
                }
                label="2024"
              />
            </RadioGroup>
          </Box>
          <Box sx={{ overflowX: "scroll", overflowY: "hidden" }}>
            <TotalRevenueLineChart />
          </Box>
          {/* <Box sx={{ textAlign: "center", margin: "50px 0" }}>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                color: "#673C8E", // Return the value directly
                // marginBottom: "20px",
              }}
            >
              No data Yet!
            </Typography>
          </Box> */}
        </Card>
      </Grid>
    </Grid>
  );
}
