import { Card, Grid, Typography } from "@mui/material";
import React from "react";

const DriverDetailCard = ({ title, subTitle, heading }) => {
  return (
    <Grid item xs={12} md={3}>
      <Card
        sx={{
          border: "1px solid #9095A1",
          borderRadius: "4px",
          padding: "7px 12px",
        }}
      >
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            color: "#171A1F", // Return the value directly
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "32px",
            color: "#171A1F", // Return the value directly
            margin: "18px 0",
          }}
        >
          {heading}
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            color: "#171A1F", // Return the value directly
          }}
        >
          {subTitle}
        </Typography>
      </Card>
    </Grid>
  );
};

export default DriverDetailCard;
