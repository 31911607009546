import React, { useEffect, useRef } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import truck from "../../assets/images/svg/truck.svg";
import pinIcon from "../../assets/images/svg/pinIcon.svg"; // Import the pin icon

const MapComponent = () => {
  const mapRef = useRef(null);
  const truckMarkerRef = useRef(null);
  const pinMarkerRef = useRef(null); // Ref for the pin marker
  const polylineRef = useRef(null);

  // Dummy positions
  const initialPositions = [
    [51.505, -0.09],
    [51.506, -0.091],
    [51.507, -0.092],
    [51.508, -0.091],
    [51.509, -0.092],
    [51.51, -0.091],
    [51.511, -0.092],
  ];
  useEffect(() => {
    // Initialize the map
    mapRef.current = L.map("map", {
      center: [51.505, -0.09],
      zoom: 13,
    });

    // Add a tile layer to the map
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(
      mapRef.current
    );

    // Initialize the truck marker
    truckMarkerRef.current = L.marker(initialPositions[0], {
      icon: L.icon({
        iconUrl: truck,
        iconSize: [25, 25],
      }),
    }).addTo(mapRef.current);

    // Initialize the pin marker
    pinMarkerRef.current = L.marker(
      initialPositions[initialPositions.length - 1],
      {
        icon: L.icon({
          iconUrl: pinIcon,
          iconSize: [25, 25],
        }),
      }
    ).addTo(mapRef.current);

    // Initialize the polyline
    polylineRef.current = L.polyline(initialPositions, {
      color: "purple",
    }).addTo(mapRef.current);

    return () => {
      // Clean up the map instance on component unmount
      mapRef.current.remove();
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      // Update positions
      const newPositions = initialPositions.map(([lat, lng]) => [
        lat + (Math.random() - 0.5) * 0.001,
        lng + (Math.random() - 0.5) * 0.001,
      ]);

      // Update marker positions
      truckMarkerRef.current.setLatLng(newPositions[0]);
      pinMarkerRef.current.setLatLng(newPositions[newPositions.length - 1]);

      // Update polyline positions
      polylineRef.current.setLatLngs(newPositions);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return <div id="map" style={{ height: "inherit", width: "100%" }}></div>;
};

export default MapComponent;
