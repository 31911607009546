import React from "react";
import { Box, Grid, Typography, Slider } from "@mui/material";
import { styled } from "@mui/system";

const valuetext = (value) => {
  return `${value}`;
};

const marks = [
  { value: 10, label: "$10" },
  { value: 100, label: "$100" },
  { value: 200, label: "$200" },
  { value: 300, label: "$300" },
  { value: 400, label: "$400" },
  { value: 500, label: "$500" },
  { value: 600, label: "$600" },
  { value: 700, label: "$700" },
  { value: 800, label: "$800" },
  { value: 900, label: "$900" },
];

const CustomSlider = styled(Slider)({
  "& .MuiSlider-valueLabel": {
    backgroundColor: "#673C8E",
    color: "#fff",
  },
  "& .MuiSlider-markLabel": {
    fontSize: "8px",
  },
});

const AmountRangeFilter = ({ type, selectedFilters, setSelectedFilters }) => {
  const handleSliderChange = (event, newValue) => {
    const [newAmountFrom, newAmountTo] = newValue;
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      amountFrom: newAmountFrom,
      amountTo: newAmountTo,
    }));
  };

  return (
    <>
      {(type === "orders" || type === "clients" || type == "drivers") && (
        <Grid item xs={12}>
          <Typography
            sx={{
              color: "rgba(23, 26, 31, 1)",
              fontSize: "10px",
              mb: 1,
              fontWeight: 700,
            }}
          >
            {type === "orders"
              ? "Amount Range"
              : type == "clients"
              ? "Filter with Total Amount Spent"
              : "Filter with Total Order Count"}
          </Typography>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 1,
              mb: 1,
            }}
          >
            <Typography
              sx={{
                color: "rgba(23, 26, 31, 1)",
                fontSize: "12px",
              }}
            >
              From:
            </Typography>
            <Typography
              sx={{
                color: "rgba(23, 26, 31, 1)",
                fontSize: "12px",
                paddingRight: "70px",
              }}
            >
              To:
            </Typography>
          </Box> */}
          <Box sx={{ mt: 5 }}>
            <CustomSlider
              getAriaLabel={() => "Amount range"}
              value={[selectedFilters.amountFrom, selectedFilters.amountTo]}
              onChange={handleSliderChange}
              valueLabelDisplay="on"
              valueLabelFormat={valuetext}
              getAriaValueText={valuetext}
              min={0}
              max={900}
              sx={{ color: "#673C8E" }}
              marks={marks}
            />
          </Box>
        </Grid>
      )}
    </>
  );
};

export default AmountRangeFilter;
