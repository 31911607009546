import { Card, CardContent, Grid, IconButton, Typography } from "@mui/material";
import { ReceiptOutlined } from "@mui/icons-material";
import React from "react";

const OrderCard = ({ title, count }) => {
  return (
    <Grid item xs={12} md={2.4}>
      <Card
        sx={{
          boxShadow: "none",
          borderRadius: "14px",
          height: "125px",
          borderRight: "1px solid #683C8C",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "38px 15px",
        }}
      >
        <IconButton
          variant="outlined"
          sx={{ border: "1px solid #673C8E", color: "#673C8E" }}
        >
          <ReceiptOutlined />
        </IconButton>
        <CardContent>
          <Typography
            sx={{ fontSize: "14px", fontWeight: 500 }}
            variant="subtitle"
          >
            {title}
          </Typography>
          <Typography
            sx={{ fontSize: "46px", fontWeight: 700, textAlign: "center" }}
            variant="h3"
          >
            {count}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default OrderCard;
