import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export function LogoutPage() {
  const [isCheck, setIsCheck] = useState(false);
  const navigate = useNavigate();

  const handleCheckboxChange = (e) => {
    setIsCheck(e.target.checked);
  };

  const logout = async () => {
    if (!isCheck) {
      enqueueSnackbar("Please Check the box to confirm.", {
        variant: "error",
      });
      return;
    }

    localStorage.clear();
    enqueueSnackbar("Logged Out Successfully.", { variant: "success" });
    navigate("/login");
    // const { type } = await $crud.delete("admin/logout");
    // if (type === "success") {
    //   localStorage.removeItem("token");
    //   navigate("/login");
    // }
  };

  return (
    <>
      <Grid container sx={{ padding: "0 16px" }}>
        <Grid item xs={12}>
          <Typography
            sx={{
              color: "rgba(103, 60, 142, 1)",
              fontSize: "30px",
              fontWeight: 700,
            }}
          >
            LOGOUT
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "42px" }}>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: 400,
            }}
          >
            Are you sure, you want to logout?
          </Typography>
        </Grid>
        <Grid item xs={12} lg={9} sx={{ marginTop: "15px" }}>
          <Card sx={{ padding: "22px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isCheck}
                  onChange={handleCheckboxChange}
                  sx={{
                    color: "#683C8C",
                    "&.Mui-checked": {
                      color: "#683C8C",
                    },
                  }}
                />
              }
              sx={{
                "& .MuiFormControlLabel-label": {
                  color: "#683C8C",
                  fontSize: "20px",
                },
              }}
              label="Yes, I do confirm!"
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: 2,
                marginTop: "20px",
              }}
            >
              <Button
                onClick={logout}
                variant={"contained"}
                size="small"
                type="submit"
                sx={{
                  backgroundColor: "#F04444",
                  color: "#FFFFFF",
                  padding: "10px 24px",
                  "&:hover": {
                    backgroundColor: "#F04444",
                    color: "#FFFFFF",
                  },
                }}
              >
                logout
              </Button>
              <Button
                onClick={() => navigate(-1)}
                variant="outlined"
                size="small"
                type="submit"
                sx={{
                  borderColor: "#565D6D",
                  color: "#565D6D",
                  padding: "10px 30px",
                  fontSize: "14px",
                  textTransform: "capitalize",
                  "&:hover": {
                    borderColor: "#565D6D",
                    color: "#565D6D",
                  },
                }}
              >
                Cancel
              </Button>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
