import React, { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
import {
  Avatar,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Popover,
  Modal,
} from "@mui/material";
import {
  Send,
  EmojiEmotions,
  Mic,
  ImageOutlined,
  AttachFile,
  CameraAltOutlined,
  InsertDriveFileOutlined,
  ArrowCircleDownOutlined,
  SearchOutlined,
  Delete,
} from "@mui/icons-material";
import back from "../../assets/images/svg/back.svg";
import search from "../../assets/images/svg/search.svg";
import { BASE_URL } from "../../constants";
import { $crud } from "../../utils/CrudFactory";
import { useLocation, useNavigate } from "react-router-dom";
import { socketInit, socket } from "../../utils/Socket";
import EmojiPicker from "emoji-picker-react";
import ChatUploader from "../../components/modals/ChatUploader";
import ChatCameraPopUp from "../../components/modals/ChatCameraPopUp";
import { ReactMic } from "react-mic";
import ChatAudioRecorder from "../../components/modals/ChatAudioRecorder";

const formatTime = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};
export function ChatDetails() {
  const user = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const navigate = useNavigate();
  const [chats, setChats] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [chatdetail, setChatDetail] = useState(location.state?.chat);
  const [joinChat, setJoinChat] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileType, setFileType] = useState(null);
  const fileInputRef = useRef(null);
  const [cameraModalOpen, setCameraModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [highlightedIndex, setHighlightedIndex] = useState(0); // Track the current highlighted result
  const [highlightedElements, setHighlightedElements] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [audioFile, setAudioFile] = useState(null);
  const [audioFileName, setAudioFileName] = useState("");
  const endOfMessagesRef = useRef(null);
  const boxRef = useRef(null);

  useEffect(() => {
    // Ensure the container is fully rendered before scrolling
    const scrollToBottom = () => {
      if (boxRef.current && endOfMessagesRef.current) {
        boxRef.current.scrollTop = boxRef.current.scrollHeight;
      }
    };

    // Delay scrolling to ensure DOM updates are complete
    const timeoutId = setTimeout(scrollToBottom, 100);

    return () => clearTimeout(timeoutId); // Clean up timeout on unmount
  }, [chatdetail, joinChat, newMessage, audioFile, selectedFile]);

  const open = Boolean(anchorEl);

  const handleEmojiClick = () => {
    // Close attach file popover if open
    if (anchorEl) {
      setAnchorEl(null);
    }
    // Toggle emoji picker
    setShowEmojiPicker(!showEmojiPicker);
  };

  // Handler for Attach File Click
  const handlePopoverClick = (event) => {
    // Close emoji picker if open
    if (showEmojiPicker) {
      setShowEmojiPicker(false);
    }
    // Toggle attach file popover
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFileClick = (type) => {
    setFileType(type);
    if (type === "camera") {
      // Open the camera modal
      setCameraModalOpen(true);
    } else {
      if (type === "image") {
        fileInputRef.current.accept = "image/*, video/*";
      } else if (type === "document") {
        fileInputRef.current.accept =
          "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      }
      fileInputRef.current.click(); // Open file manager
    }

    handleClose(); // Close the popover
  };

  const handleCameraModalClose = () => {
    setCameraModalOpen(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      let fileType = "document"; // Default to document if no match found
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (["png", "jpg", "jpeg"].includes(fileExtension)) {
        fileType = "image";
      } else if (["mp4", "mov", "avi"].includes(fileExtension)) {
        fileType = "video";
      } else if (["pdf", "doc", "docx"].includes(fileExtension)) {
        fileType = "document";
      }
      setFileType(fileType);
      setSelectedFile(file);
      setShowModal(true); // Open the modal
      console.log(file);
    }
  };

  const handleSend = (file) => {
    handleSendMessage();
  };

  // Fetch chat sessions
  async function retrieveChats() {
    const { data } = await $crud.retrieve("admin/chats");
    return data.result;
  }

  // Fetch messages for a specific chat session
  async function retrieveMessages(chatId) {
    const { data } = await $crud.retrieve(`admin/chats/${chatId}/messages`);
    return data.messages;
  }

  useEffect(() => {
    socketInit();
  }, []);

  useEffect(() => {
    retrieveMessages(chatdetail._id).then((messages) => {
      setMessages(messages);
    });
  }, [chatdetail._id]);

  useEffect(() => {
    socket.on("newMessage", (message) => {
      if (message.chatId === chatdetail._id) {
        setMessages((prevMessages) => [...prevMessages, message]);
      }
    });

    socket.on("onlineUser", (e) => {
      console.log(e);
    });
    return () => {
      socket.off("onlineUser");

      socket.off("message");
    };
  }, [chatdetail._id]);

  const handleJoinChat = () => {
    setJoinChat(true);
    socket.emit("join", { user: "Admin", room: chatdetail.orderID });
  };

  const handleLeaveChat = () => {
    setJoinChat(false);
    socket.emit("leave", { user: "Admin", room: chatdetail.orderID });
  };

  const handleMicClick = () => {
    setIsRecording((prevState) => !prevState);
  };

  const handleSendAudio = (audioBlob, fileName) => {
    setAudioFile(audioBlob);
    handleSendMessage(null, audioBlob, fileName); // Pass the audio file to the message handler
  };

  const handleSendMessage = async (capturedPhoto = null, audioFile = null) => {
    if (audioFile) {
      // If an audio file is provided, read it as a Base64 string and send it
      const reader = new FileReader();
      reader.onload = () => {
        const base64File = reader.result.split(",")[1]; // Get the Base64 part
        const message = {
          chatId: chatdetail._id,
          sender: user.name,
          message: {
            fileName: audioFileName, // Use the audio file name
            fileType: "audio/wav", // Audio file type
            fileContent: base64File, // Send the Base64 content
          },
          time: new Date().toISOString(),
          room: chatdetail.orderID,
          mode: "web",
          senderId: user._id,
        };

        // Emit the message to the server
        socket.emit("sendMessage", message);

        // Clear any temporary state if necessary
        setNewMessage("");
        setAudioFile(null); // Reset audio file
        setAudioFileName(""); // Reset audio file name
      };
      reader.readAsDataURL(audioFile);
    } else if (selectedFile) {
      // If a file is selected, read it as a Base64 string and send it
      const reader = new FileReader();
      reader.onload = () => {
        const base64File = reader.result.split(",")[1]; // Get the Base64 part
        const message = {
          chatId: chatdetail._id,
          sender: user.name,
          message: {
            fileName: selectedFile.name,
            fileType: selectedFile.type,
            fileContent: base64File, // Send the Base64 content
          },
          time: new Date().toISOString(),
          room: chatdetail.orderID,
          mode: "web",
          senderId: user._id,
        };

        // Emit the message to the server
        socket.emit("sendMessage", message);

        // Optimistically update the local state
        setNewMessage("");
        setShowModal(false);
        setSelectedFile(null);
        setFileType(null);
      };
      reader.readAsDataURL(selectedFile);
    } else if (capturedPhoto) {
      // If a captured photo is provided, send it
      const timestamp = new Date().getTime(); // Get the current timestamp
      const uniqueFileName = `${user.name}_${timestamp}.png`;
      const message = {
        chatId: chatdetail._id,
        sender: user.name,
        message: {
          fileName: uniqueFileName,
          fileType: `image/png`,
          fileContent: capturedPhoto, // The Base64 content of the captured image
        },
        time: new Date().toISOString(),
        room: chatdetail.orderID,
        mode: "web",
        senderId: user._id,
      };

      // Emit the message to the server
      socket.emit("sendMessage", message);

      // Clear any temporary state if necessary
      setNewMessage("");
    } else if (newMessage) {
      // If it's a text message, send it
      const message = {
        chatId: chatdetail._id,
        sender: user.name,
        message: newMessage,
        time: new Date().toISOString(),
        room: chatdetail.orderID,
        mode: "web",
        senderId: user._id,
      };

      // Emit the message to the server
      socket.emit("sendMessage", message);
      setNewMessage("");
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };
  const handleSearch = (query) => {
    const chatMessages = document.querySelectorAll(".chat-message");

    if (query.trim() === "") {
      // Reset all chat messages to their original content
      chatMessages.forEach((messageElement) => {
        const originalText =
          messageElement.textContent || messageElement.innerText;
        messageElement.innerHTML = originalText; // Remove any highlighting
      });
      setHighlightedIndex(0); // Reset the highlighted index
      setHighlightedElements([]); // Clear the highlighted elements array
      return;
    }

    let newHighlightedElements = []; // To store references to highlighted elements

    // Highlight the matching text in chat messages
    chatMessages.forEach((messageElement) => {
      const textContent =
        messageElement.textContent || messageElement.innerText;

      if (textContent.toLowerCase().includes(query.toLowerCase())) {
        const highlightedText = textContent.replace(
          new RegExp(query, "gi"),
          (match) => `<span class="highlight">${match}</span>`
        );
        messageElement.innerHTML = highlightedText;

        // Collect all matched elements
        const innerMatches = messageElement.querySelectorAll(".highlight");
        innerMatches.forEach((match) => {
          newHighlightedElements.push(match);
        });
      } else {
        messageElement.innerHTML = textContent; // Remove highlighting if the text does not match
      }
    });

    setHighlightedElements(newHighlightedElements); // Update the state with all matches

    // Scroll to the first matching element if it exists
    if (newHighlightedElements.length > 0) {
      setHighlightedIndex(0); // Set the first highlighted index
      newHighlightedElements[0].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      newHighlightedElements[0].focus(); // Focus on the first result
    }
  };

  // Handle keydown events to navigate through highlighted items
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && highlightedElements.length > 0) {
      // Move to the next highlighted result
      const nextIndex = (highlightedIndex + 1) % highlightedElements.length;
      setHighlightedIndex(nextIndex);
      highlightedElements[nextIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      highlightedElements[nextIndex].focus(); // Focus on the next result
    }
  };

  // Focus on the current highlighted element whenever it changes
  useEffect(() => {
    if (highlightedElements.length > 0) {
      highlightedElements[highlightedIndex]?.focus();
    }
  }, [highlightedIndex, highlightedElements]);
  return (
    <Grid container sx={{ padding: "0 18px" }}>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "flex-start",
            gap: 1,
          }}
        >
          <IconButton onClick={handleBackClick} sx={{ marginLeft: "-10px" }}>
            <img src={back} />
          </IconButton>
          <Box>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: "30px",
                color: "#673C8E",
              }}
            >
              Chat Details
            </Typography>

            <Typography
              variant="h5"
              sx={{
                fontWeight: 400,
                fontSize: "12px",
                color: "rgba(99, 99, 99, 1)",
              }}
            >
              Session Id : {chatdetail.sessionID}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "end",
          // alignItems: "center",
          paddingRight: "20px",
        }}
      >
        <Button
          variant="contained"
          size="large"
          onClick={joinChat ? handleLeaveChat : handleJoinChat}
          sx={{
            ml: 2,
            backgroundColor: "#683C8C",
            color: "#FFFFFF",
            borderColor: "#683C8C",
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "#683C8C",
              color: "#FFFFFF",
              borderColor: "#683C8C",
            },
          }}
        >
          {joinChat ? "Leave" : "Join"} Chat
        </Button>
        <Button
          variant="contained"
          size="large"
          // onClick={() => (joinChat == true ? setJoinChat(false) : "")}
          sx={{
            ml: 2,
            backgroundColor: "#683C8C",
            color: "#FFFFFF",
            borderColor: "#683C8C",
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "#683C8C",
              color: "#FFFFFF",
              borderColor: "#683C8C",
            },
          }}
        >
          End Session
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        lg={4}
        mt={1}
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Avatar variant="square" sx={{ borderRadius: "8px" }} />
        <Box>
          <Typography
            sx={{
              color: "rgba(99, 99, 99, 1)",
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            {chatdetail.chatParty1.name}
          </Typography>
          <Typography sx={{ fontSize: "12px", color: "rgba(99, 99, 99, 1)" }}>
            Session Id : {chatdetail.sessionID}
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        lg={5}
        mt={1}
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Avatar variant="square" sx={{ borderRadius: "8px" }} />
        <Box>
          <Typography
            sx={{
              color: "rgba(99, 99, 99, 1)",
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            {chatdetail.chatParty2.name}
          </Typography>
          <Typography sx={{ fontSize: "12px", color: "rgba(99, 99, 99, 1)" }}>
            Driver
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        lg={3}
        mt={1}
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        <TextField
          name="search"
          placeholder="Search..."
          onKeyDown={handleKeyDown}
          sx={{
            width: "100%",
            color: "#1C1B1F",
            mr: 2,
            "& .MuiInputLabel-root": {
              color: "#1C1B1F !important", // Adding !important to ensure the color overrides
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#673C8E", // Initial border color
                borderWidth: "1px", // Increase border width
                borderRadius: "4px",
              },
              "&:hover fieldset": {
                borderColor: "#673C8E", // Change outlined border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "#673C8E", // Change outlined border color when focused
              },
            },
          }}
          size="small"
          id="search"
          value={searchQuery}
          // onChange={(e) => setSearchQuery(e.target.value)} // Update search query on input change
          onChange={(e) => {
            setSearchQuery(e.target.value);
            handleSearch(e.target.value); // Call handleSearch on each change
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ cursor: "pointer" }}
                onClick={() => handleSearch(searchQuery)} // Handle search on click
              >
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Box
        sx={{
          background: "rgba(104, 60, 140, 0.35)",
          width: "100%",
          height: "1px",
          mt: 1,
        }}
      ></Box>
      <Grid item xs={12} sx={{ textAlign: "center" }} mt={1}>
        <Typography sx={{ fontSize: "11px", color: "rgba(175, 184, 207, 1)" }}>
          Today 06:32 PM
        </Typography>
      </Grid>
      <Box
        ref={boxRef}
        sx={{
          width: "100%",
          height: "400px",
          overflowX: "hidden",
          overflowY: "scroll",
          padding: "0 16px",
        }}
      >
        <Grid container spacing={2}>
          {messages.map((msg, index) => {
            const isImage = /\.(jpg|jpeg|png|gif|bmp)$/i.test(msg.message);
            const isDocument = /\.(doc|docx|pdf)$/i.test(msg.message);
            const isAudio = /\.(mp3|wav|ogg|webm)$/i.test(msg.message);
            const isTextMessage = !isAudio && !isImage && !isDocument; // Check if it's plain text

            return (
              <Grid
                key={index}
                item
                xs={12}
                sx={{
                  textAlign: msg.senderId === user._id ? "right" : "left",
                  position: "relative",
                }}
              >
                {isAudio && (
                  <Box
                    sx={{
                      background:
                        msg.senderId === user._id
                          ? "#683C8C"
                          : "rgba(104, 60, 140, 0.35)",
                      borderRadius:
                        msg.senderId === user._id
                          ? "10px 10px 0px 10px"
                          : "10px 10px 10px 0px",
                      padding: "8px",
                      width: "auto",
                      marginTop: "16px",
                      display: "inline-block",
                      textAlign: "left",
                    }}
                  >
                    <audio
                      controls
                      style={{
                        display: "block",
                        width: "250px",
                        // backgroundColor: "red", // Ensures transparent background
                      }}
                    >
                      <source
                        src={`${BASE_URL}/chatAudios/${msg.message}`} // Adjust the URL path
                        type={`audio/${msg.message.split(".").pop()}`}
                      />
                      Your browser does not support the audio element.
                    </audio>
                  </Box>
                )}

                {isImage ? (
                  <Box
                    sx={{
                      background:
                        msg.senderId === user._id
                          ? "#683C8C"
                          : "rgba(104, 60, 140, 0.35)",
                      borderRadius:
                        msg.senderId === user._id
                          ? "10px 10px 0px 10px"
                          : "10px 10px 10px 0px",
                      padding: "8px",
                      width: "auto",
                      marginTop: "16px",
                      display: "inline-block",
                      textAlign: "left",
                    }}
                  >
                    <img
                      src={`${BASE_URL}/chats/${msg.message}`}
                      alt="User uploaded content"
                      style={{
                        maxWidth: "250px",
                        borderRadius: "10px",
                      }}
                    />
                  </Box>
                ) : isDocument ? (
                  <Box
                    sx={{
                      background:
                        msg.senderId === user._id
                          ? "#683C8C"
                          : "rgba(104, 60, 140, 0.35)",
                      borderRadius:
                        msg.senderId === user._id
                          ? "10px 10px 0px 10px"
                          : "10px 10px 10px 0px",
                      padding: "12px",
                      width: "auto",
                      marginTop: "16px",
                      display: "inline-block",
                      textAlign: "left",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 4,
                      }}
                    >
                      <Typography
                        className="chat-message"
                        sx={{
                          fontSize: "12px",
                          color:
                            msg.senderId === user._id
                              ? "#fff"
                              : "rgba(26, 26, 27, 1)",
                        }}
                      >
                        {msg.message}
                      </Typography>
                      <a
                        href={`${BASE_URL}/chats/${msg.message}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none" }}
                      >
                        <ArrowCircleDownOutlined
                          sx={{ color: "#fff", cursor: "pointer" }}
                        />
                      </a>
                    </Box>
                  </Box>
                ) : isTextMessage ? (
                  <Box
                    sx={{
                      background:
                        msg.senderId === user._id
                          ? "#683C8C"
                          : "rgba(104, 60, 140, 0.35)",
                      borderRadius:
                        msg.senderId === user._id
                          ? "32px 32px 0px 32px"
                          : "32px 32px 32px 0px",
                      padding: "16px 24px",
                      width: "auto",
                      marginTop: "16px",
                      display: "inline-block",
                      textAlign: "left",
                    }}
                  >
                    <Typography
                      className="chat-message"
                      sx={{
                        fontSize: "12px",
                        color:
                          msg.senderId === user._id
                            ? "#fff"
                            : "rgba(26, 26, 27, 1)",
                      }}
                    >
                      {msg.message}
                    </Typography>
                  </Box>
                ) : null}

                <Typography
                  sx={{
                    fontSize: "11px",
                    color: "rgba(175, 184, 207, 1)",
                    marginTop: "8px",
                  }}
                >
                  {formatTime(msg.time)}
                </Typography>
              </Grid>
            );
          })}
          <div ref={endOfMessagesRef} />
        </Grid>
      </Box>

      {joinChat ? (
        <>
          {/* Chat typing enable */}
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
              mt: 2,
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 1,
              }}
            >
              <EmojiEmotions
                sx={{ color: "#673C8E", cursor: "pointer" }}
                onClick={handleEmojiClick}
              />
              <AttachFile
                onClick={handlePopoverClick}
                sx={{ color: "#673C8E", cursor: "pointer" }}
              />
            </Box>

            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Box sx={{ padding: "10px" }}>
                <Box
                  sx={{
                    display: "flex",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    mb: 1,
                  }}
                  onClick={() => handleFileClick("image")}
                >
                  <ImageOutlined />
                  <Typography
                    sx={{
                      color: "rgba(23, 26, 31, 1)",
                      fontSize: "12px",
                    }}
                  >
                    Photos
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    cursor: "pointer",
                    justifyContent: "start",
                    mb: 1,
                    alignItems: "center",
                  }}
                  onClick={() => handleFileClick("camera")}
                >
                  <CameraAltOutlined />
                  <Typography
                    sx={{
                      color: "rgba(23, 26, 31, 1)",
                      fontSize: "12px",
                    }}
                  >
                    Camera
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => handleFileClick("document")}
                >
                  <InsertDriveFileOutlined />
                  <Typography
                    sx={{
                      color: "rgba(23, 26, 31, 1)",
                      fontSize: "12px",
                    }}
                  >
                    Document
                  </Typography>
                </Box>
              </Box>
            </Popover>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
              // accept={fileType === "image" ? "image/*" : "application/*"}
            />

            <ChatUploader
              open={showModal}
              onClose={() => setShowModal(false)}
              onSend={handleSend}
              file={selectedFile}
              fileType={fileType}
            />

            <ChatCameraPopUp
              open={cameraModalOpen}
              onClose={handleCameraModalClose}
              handleSendMessage={handleSendMessage}
            />

            {showEmojiPicker && (
              <div
                style={{ position: "absolute", bottom: "50px", left: "10px" }}
              >
                <EmojiPicker
                  onEmojiClick={(e) =>
                    setNewMessage((prev) => prev + " " + e.emoji)
                  }
                  emojiStyle="apple"
                  style={{ zIndex: 9999 }}
                />
              </div>
            )}

            <TextField
              name="message"
              placeholder="Type your message here ..."
              value={newMessage}
              onChange={(e) => {
                setNewMessage(e.target.value);
              }}
              size="small"
              id="message"
              sx={{
                marginRight: "10px",
                width: "100%",
                background: "rgba(248, 249, 253, 1)",
                borderRadius: "26px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none !important",
                  },
                },
              }}
              InputProps={{
                endAdornment: (
                  <>
                    {!isRecording && newMessage.length > 0 && (
                      <InputAdornment
                        position="end"
                        sx={{
                          cursor: "pointer",
                          color: "#673C8E",
                        }}
                        onClick={handleSend}
                      >
                        <Send />
                      </InputAdornment>
                    )}
                    {/* <ChatAudioRecorder
                      isRecording={isRecording}
                      handleMicClick={handleMicClick}
                      recordingTime={recordingTime}
                    /> */}
                    <ChatAudioRecorder
                      isRecording={isRecording}
                      handleMicClick={handleMicClick}
                      onSend={handleSendAudio}
                      setAudioFileName={setAudioFileName}
                      audioFileName={audioFileName}
                    />
                  </>
                ),
              }}
            />
          </Grid>
        </>
      ) : (
        <>
          {/* Chat typing disable */}
          <Grid item xs={12} sx={{ textAlign: "center", mt: 3 }}>
            <Box
              sx={{
                background: "rgba(104, 60, 140, 0.35)",
                width: "100%",
                height: "0.5px",
              }}
            ></Box>
            <Typography sx={{ fontSize: "19px", color: "#000", mt: 1 }}>
              You can only chat here once you join this chat above
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
}
