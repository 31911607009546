import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import TopReviewClient from "./TopReviewClient";
import OtherReviewClient from "./OtherReviewClient";
import { $crud } from "../../../utils/CrudFactory";

const ClientReviews = ({ showAllReviews, onViewAllClick }) => {
  const [topReviews, setTopReviews] = useState([]);

  const addReviewToTop = async (review) => {
    // Optimistically update the state
    setTopReviews((prevReviews) => {
      if (prevReviews.some((r) => r.id === review.id)) {
        return prevReviews;
      }
      if (prevReviews.length < 3) {
        return [...prevReviews, review];
      }
      const updatedReviews = [review, ...prevReviews.slice(0, 2)];
      return updatedReviews;
    });

    // Re-fetch data to ensure it's in sync with the server
    const updatedData = await retrieveData();
    setTopReviews(updatedData.reviews);
  };


  async function retrieveData() {
    const { data } = await $crud.retrieve("admin/top-client-reviews");
    return data;
  }

  useEffect(() => {
    retrieveData().then((res) => {
      setTopReviews(res.reviews);
    });
  }, []);

  return (
    <>
      <Grid container sx={{ padding: "0 18px" }}>
        {!showAllReviews && (
          <Grid item xs={12}>
            <TopReviewClient reviews={topReviews} setReviews={setTopReviews} onDrop={addReviewToTop} />
          </Grid>
        )}
        <Grid item xs={12} sx={{ marginTop: "10px" }}>
          <OtherReviewClient
            onDrop={addReviewToTop}
            showAllReviews={showAllReviews}
            onViewAllClick={onViewAllClick}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ClientReviews;
