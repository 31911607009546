import React, { useState, useEffect } from "react";
import { Chip } from "@mui/material";
import { Calendar, Whisper, Popover } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";
import { $crud } from "../utils/CrudFactory";

// Custom week day format to display full names
const customWeekDayFormat = {
  weekday: "long", // This will show full names like "Monday", "Tuesday"
};

const CustomCalendarUI = () => {
  const [ordersData, setOrdersData] = useState({});
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1); // 1-based month
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  // Function to fetch orders data from API
  async function retrieveData() {
    try {
      const { data } = await $crud.retrieve("admin/orders/month", {
        year: currentYear,
        month: currentMonth,
      });
      return data;
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  }

  // Fetch orders data when the component mounts or when currentYear/currentMonth changes
  useEffect(() => {
    retrieveData().then((res) => {
      console.log(res, "-------------orders");
      // Assuming the API returns a structure like { ordersByDate: { '2024-09-17': { regularOrders: 2, vipOrders: 1 }, ... } }
      setOrdersData(res.ordersByDate || {});
    });
  }, [currentYear, currentMonth]);

  // Function to get the list of orders for a specific date
  function getTodoList(date) {
    const dateKey = date.toISOString().split("T")[0]; // Format date to "YYYY-MM-DD"
    const orderInfo = ordersData[dateKey];

    if (orderInfo) {
      const list = [];
      if (orderInfo.regularOrders > 0) {
        list.push({
          title: `${orderInfo.regularOrders} Regular Orders`,
          bgcolor: "#FBEBDE",
          color: "rgba(233, 159, 95, 1)",
        });
      }
      if (orderInfo.vipOrders > 0) {
        list.push({
          title: `${orderInfo.vipOrders} VIP Orders`,
          color: "rgba(104, 193, 153, 1)",
          bgcolor: "#E1F3EB",
        });
      }
      return list;
    }

    return [];
  }

  // Render the cell for each date
  function renderCell(date) {
    const list = getTodoList(date);
    const displayList = list.filter((item, index) => index < 2);

    if (list.length) {
      const moreCount = list.length - displayList.length;
      const moreItem = (
        <li key="more-item">
          <Whisper
            placement="top"
            trigger="click"
            speaker={
              <Popover>
                {list.map((item, index) => (
                  <p key={index}>{item.title}</p>
                ))}
              </Popover>
            }
          >
            <a>{moreCount} more</a>
          </Whisper>
        </li>
      );

      return (
        <>
          {displayList.map((item, index) => (
            <Chip
              key={index}
              sx={{
                width: "144px",
                color: item.color,
                background: item.bgcolor,
                borderRadius: "4px",
                marginBottom: "2px",
              }}
              label={item.title}
            />
          ))}
          {list.length > 2 && moreItem}
        </>
      );
    }

    return null;
  }

  // Handle month change to update the current month and year
  const handleMonthChange = (nextDate) => {
    setCurrentMonth(nextDate.getMonth() + 1);
    setCurrentYear(nextDate.getFullYear());
  };

  return (
    <Calendar
      bordered
      renderCell={renderCell}
      format={customWeekDayFormat}
      onMonthChange={handleMonthChange}
    />
  );
};

export default CustomCalendarUI;

// import { Chip } from "@mui/material";
// import { Calendar, Whisper, Popover } from "rsuite";
// import "rsuite/dist/rsuite-no-reset.min.css";

// // Custom week day format to display full names
// const customWeekDayFormat = {
//   weekday: "long", // This will show full names like "Monday", "Tuesday"
// };

// function getTodoList(date) {
//   const day = date.getDate();

//   switch (day) {
//     case 4:
//       return [
//         {
//           title: "29 Normal Client",
//           bgcolor: "#FBEBDE",
//           color: "rgba(233, 159, 95, 1)",
//         },
//         {
//           title: "5 Special Client",
//           color: "rgba(104, 193, 153, 1)",
//           bgcolor: "#E1F3EB",
//         },
//       ];
//     case 6:
//       return [
//         {
//           title: "29 Normal Client",
//           bgcolor: "#FBEBDE",
//           color: "rgba(233, 159, 95, 1)",
//         },
//         {
//           title: "5 Special Client",
//           color: "rgba(104, 193, 153, 1)",
//           bgcolor: "#E1F3EB",
//         },
//       ];
//     case 15:
//       return [
//         {
//           title: "29 Normal Client",
//           bgcolor: "#FBEBDE",
//           color: "rgba(233, 159, 95, 1)",
//         },
//         {
//           title: "5 Special Client",
//           color: "rgba(104, 193, 153, 1)",
//           bgcolor: "#E1F3EB",
//         },
//       ];
//     default:
//       return [];
//   }
// }

// const CustomCalendarUI = () => {
//   function renderCell(date) {
//     const list = getTodoList(date);
//     const displayList = list.filter((item, index) => index < 2);

//     if (list.length) {
//       const moreCount = list.length - displayList.length;
//       const moreItem = (
//         <li>
//           <Whisper
//             placement="top"
//             trigger="click"
//             speaker={
//               <Popover>
//                 {list.map((item, index) => (
//                   <p key={index}>
//                     <b>{item.time}</b> - {item.title}
//                   </p>
//                 ))}
//               </Popover>
//             }
//           >
//             <a>{moreCount} more</a>
//           </Whisper>
//         </li>
//       );

//       return (
//         <>
//           {displayList.map((item, index) => (
//             <Chip
//               key={index} // Added key prop here
//               sx={{
//                 width: "144px",
//                 color: item.color,
//                 background: item.bgcolor,
//                 borderRadius: "4px",
//               }}
//               label={item.title}
//             />
//           ))}
//         </>
//       );
//     }

//     return null;
//   }

//   return (
//     <Calendar bordered renderCell={renderCell} format={customWeekDayFormat} />
//   );
// };

// export default CustomCalendarUI;

// import React, { useEffect, useState } from "react";
// import { Calendar, Whisper, Popover } from "rsuite";
// import { Chip } from "@mui/material";
// import axios from "axios";
// import { $crud } from "../utils/CrudFactory";

// const CustomCalendarUI = () => {
//   const [ordersData, setOrdersData] = useState({});
//   const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1); // 1-based month
//   const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

//   // Fetch orders for the current month
//   const fetchOrders = async (year, month) => {
//     try {
//       const response = await axios.get("/admin/orders/month", {
//         params: { year, month },
//       });
//       if (response.data.success) {
//         setOrdersData(response.data.data.ordersByDate);
//       } else {
//         console.error(response.data.message);
//       }
//     } catch (error) {
//       console.error("Error fetching orders:", error);
//     }
//   };

//   async function retrieveData() {
//     const { data } = await $crud.retrieve("admin/orders/month", {
//       year: currentYear,
//       month: currentMonth,
//     });
//     return data;
//   }

//   useEffect(() => {
//     retrieveData().then((res) => {
//       console.log(res, "-------------orders");
//     });
//   }, [currentYear, currentMonth]);

//   // Render the cell for each date
//   const renderCell = (date) => {
//     const dateKey = date.toISOString().split("T")[0];
//     const orderInfo = ordersData[dateKey];

//     if (orderInfo) {
//       return (
//         <>
//           {orderInfo.regularOrders > 0 && (
//             <Chip
//               sx={{
//                 backgroundColor: "#FBEBDE",
//                 color: "rgba(233, 159, 95, 1)",
//               }}
//               label={`${orderInfo.regularOrders} Regular Orders`}
//             />
//           )}
//           {orderInfo.vipOrders > 0 && (
//             <Chip
//               sx={{
//                 backgroundColor: "#E1F3EB",
//                 color: "rgba(104, 193, 153, 1)",
//               }}
//               label={`${orderInfo.vipOrders} VIP Orders`}
//             />
//           )}
//         </>
//       );
//     }
//     return null;
//   };

//   // Handle month change to fetch data for the new month
//   const handleMonthChange = (nextDate) => {
//     setCurrentMonth(nextDate.getMonth() + 1);
//     setCurrentYear(nextDate.getFullYear());
//   };

//   return (
//     <Calendar
//       bordered
//       renderCell={renderCell}
//       onMonthChange={handleMonthChange}
//     />
//   );
// };

// export default CustomCalendarUI;
