import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  { name: "Jan", uv: 11000, pv: 22000, amt: 24000 },
  { name: "Feb", uv: 15000, pv: 13980, amt: 22100 },
  { name: "Mar", uv: 20000, pv: 25800, amt: 22900 },
  { name: "Apr", uv: 28753, pv: 14000, amt: 20000 },
  { name: "May", uv: 19000, pv: 26800, amt: 21810 },
  { name: "Jun", uv: 25000, pv: 13000, amt: 25000 },
  { name: "Jul", uv: 30000, pv: 20000, amt: 21000 },
  { name: "Aug", uv: 28000, pv: 24000, amt: 24000 },
  { name: "Sep", uv: 27000, pv: 18000, amt: 23000 },
  { name: "Oct", uv: 26000, pv: 12000, amt: 22000 },
  { name: "Nov", uv: 24000, pv: 17000, amt: 21000 },
  { name: "Dec", uv: 23000, pv: 26000, amt: 20000 },
];

const TotalRevenueLineChart = () => {
  return (
    <ResponsiveContainer
      style={{ marginLeft: "-40px" }}
      width={800}
      height={150}
    >
      <LineChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid horizontal={false} strokeDasharray="1 0" />
        <XAxis
          axisLine={false}
          tickLine={false}
          tick={{
            fontSize: "11px",
            fontWeight: 400,
          }}
          dataKey="name"
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tick={{
            fontSize: "12px",
            fontWeight: 600,
          }}
          domain={[10000, 30000]}
          ticks={[10000, 20000, 30000]}
          tickFormatter={(value) => `$${value / 1000}k`}
        />
        <Tooltip />
        <Line
          type="monotone"
          dataKey="pv"
          stroke="rgba(104, 60, 140, 1)"
          strokeWidth={2}
        />
        <Line
          type="monotone"
          dataKey="uv"
          stroke="rgba(104, 60, 140, 0.35)"
          strokeWidth={2}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default TotalRevenueLineChart;
